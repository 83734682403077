import '@blocknote/mantine/style.css';

import { type PartialBlock } from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import { useCreateBlockNote } from '@blocknote/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import {
  EnumsMediaType,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
} from '@lp-lib/api-service-client/public';
import type { SlideBlock, SlideBlockAnimationKey } from '@lp-lib/game';
import { EnumsSlideListStyle } from '@lp-lib/game/src/block';
import { type Logger } from '@lp-lib/logger-base';
import { type MediaFormat } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO } from '../../../utils/api-dto';
import { assertExhaustive, err2s } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { playWithCatch } from '../../../utils/playWithCatch';
import { UnplayableVideoImpl } from '../../../utils/unplayable';
import { markSnapshottable, useSnapshot } from '../../../utils/valtio';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { type SoundEffectKeys } from '../../SFX';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';
import {
  DialoguePlayer,
  DialoguePlayerControl,
  DialogueSlideShow,
} from '../../VoiceOver/Dialogue/DialoguePlayer';
import {
  BlockAnimation,
  BlockAnimator,
  BlockAnimatorProvider,
  useBlockAnimator,
} from '../apis/BlockAnimationControl';
import { BlockContainer } from '../design/BlockContainer';
import { CommonButton } from '../design/Button';
import { Captions } from '../design/Captions';
import { EditableTextDisplay } from '../design/EditableDisplay';
import { ScrollableContent } from '../design/ScrollableContent';
import { TrainingLogo } from '../design/TrainingLogo';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../types';
import { HTMLPlayer } from './Slide/HTMLPlayer';

const Animate = BlockAnimation<SlideBlockAnimationKey>;

class VideoPlaystateMonitor {
  private _ended = Promise.withResolvers<void>();
  private _video: HTMLVideoElement | null = null;

  register(video: HTMLVideoElement | null) {
    if (video === this._video && this._video) {
      // do nothing, already registered
    } else if (video === null) {
      this._ended = Promise.withResolvers<void>();

      // video is being unmounted
      if (this._video) {
        this._video.onended = null;
        this._video = null;
      }
    } else if (video) {
      this._ended = Promise.withResolvers<void>();

      // video is being registered
      this._video = video;
      this._video.onended = () => this._ended.resolve();
    }
  }

  get ended() {
    return this._ended.promise;
  }

  get video() {
    return this._video;
  }

  reset() {
    this.register(this._video);
    if (this._video) {
      // Always reset the video to the beginning.
      this._video.currentTime = 0;

      // If the autoplay attribute is set and we're paused, this means we are
      // resetting the video and have no animation triggers. So we need to
      // manually replay the video.
      if (this._video.paused && this._video.autoplay) {
        this._video.play().catch();
      }
    }
  }

  destroy() {
    if (this._video) {
      this._video.onended = null;
    }
    this._video = null;
  }
}

export class SlideBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<{
      status: 'init' | 'present' | 'presented';
      safeToPlayVideo: boolean;
      hideButtons: boolean;
    }>({
      status: 'init',
      safeToPlayVideo: false,
      hideButtons: false,
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  readonly logger: Logger;
  blockAnimator: BlockAnimator<SlideBlockAnimationKey>;
  videoPlaystateMonitor = new VideoPlaystateMonitor();
  public videoCache: Map<string, HTMLVideoElement>;
  // note: this is bounded by the size of the audio pool.
  private preloadCount = 3;
  public dialoguePlayer: DialoguePlayerControl;
  public showReplayButton: boolean;
  public primaryMedia: Nullable<MediaFormat>;

  constructor(private block: SlideBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('slide-block');
    this.blockAnimator = new BlockAnimator(
      block.fields.animations ?? {},
      deps.getLVOCtrl
    );
    this.videoCache = new Map();
    this.dialoguePlayer = new DialoguePlayerControl(
      block.fields.dialogue,
      deps,
      this.videoCache,
      this.logger,
      this.preloadCount
    );
    this.showReplayButton = false;
    this.primaryMedia = null;
    this._state.hideButtons = block.fields.hideButton;
  }

  get state() {
    return this._state;
  }

  getLVOCtrl() {
    return this.deps.getLVOCtrl();
  }

  async preload() {
    const entries = this.block.fields.dialogue?.entries ?? [];
    if (entries.length === 0) return;

    this.showReplayButton = true;

    // preload the dialogue
    const preloads: Promise<void>[] = [];
    preloads.push(this.dialoguePlayer.preload());

    await Promise.all(preloads);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
    this.blockAnimator.on();
    this.primaryMedia = MediaUtils.PickMediaFormat(
      fromMediaDTO(this.block.fields.media?.media),
      { priority: ImagePickPriorityHighToLow }
    );
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    this._state.status = 'present';

    // Always ok to immediately play video if it is silent. This is mainly for
    // iOS: videos will usually take over the audio stream and squelch others.
    if (this.primaryMedia?.silent) {
      this._state.safeToPlayVideo = true;
    }

    if (this.dialoguePlayer.numEntries() > 0) {
      try {
        const info = this.dialoguePlayer.play();
        await info.started;
        this.blockAnimator.triggerAnimations('start');
        await info.ended;
        // VO is over, it is now _always_ safe to play video, even if it has
        // sound (again, iOS).
        this._state.safeToPlayVideo = true;

        if (this.videoPlaystateMonitor.video) {
          await this.videoPlaystateMonitor.ended;
        }
        this.blockAnimator.triggerAnimations('end');
      } catch (err) {
        this.logger.error('failed to play dialogue', err);
      }
    } else if (this.videoPlaystateMonitor.video) {
      // There is no VO, it is always safe to play video immediately since there
      // are no other sound sources.
      this._state.safeToPlayVideo = true;
      await this.videoPlaystateMonitor.ended;
    }
    this._state.status = 'presented';
    if (this._state.hideButtons) {
      if (this.deps.tutorControl.state.handState === 'raised') {
        // in case the hand is raised, we need to make time for the tutor
        this._state.hideButtons = false;
      } else {
        this.end();
        return;
      }
    }
    this.deps.tutorControl.setAvailabilityState('available');
  }

  async replay() {
    this.blockAnimator.reset(this.block.fields.animations ?? {});
    this.videoPlaystateMonitor.reset();
    this._state.safeToPlayVideo = false;
    this.deps.tutorControl.setAvailabilityState('unavailable');
    this.present();
  }

  async end() {
    this.deps.tutorControl.setAvailabilityState('unavailable');
    this.destroy();
    await this.delegate?.blockDidEnd();
  }

  playSFX(key: SoundEffectKeys) {
    return this.deps.sfxControl.play(key);
  }

  async destroy() {
    this.dialoguePlayer.destroy();
    this.videoCache.clear();
    this.blockAnimator.destroy();
    this.videoPlaystateMonitor.destroy();
  }
}

function useVideoPlayback(ctrl: SlideBlockControlAPI) {
  const ref = useRef<HTMLVideoElement | null>(null);
  const videoRefCb = useLiveCallback((el: HTMLVideoElement | null) => {
    ref.current = el;
    // Register so the system knows when the video has finished.
    ctrl.videoPlaystateMonitor.register(el);
  });

  const [entered, setEntered] = useState(false);
  const safeToPlayVideo = useSnapshot(ctrl.state).safeToPlayVideo;
  const hasTrigger = ctrl.blockAnimator.hasEnterAnimation('media');

  useEffect(() => {
    if (!ref.current || !safeToPlayVideo) return;

    if (hasTrigger && entered) {
      // Handle:
      // - non-silent video, with vo, with trigger
      // - silent video, with vo, with trigger
      playWithCatch(ref.current, ctrl.logger);
    } else if (!hasTrigger) {
      // Handle:
      // - non-silent video, with vo, no trigger
      // - non-silent video, no vo, no trigger
      // - silent video, no vo, no trigger
      // - silent video, with vo, no trigger
      playWithCatch(ref.current, ctrl.logger);
    }
  }, [ctrl.logger, safeToPlayVideo, entered, hasTrigger]);

  const onEntered = useLiveCallback(() => {
    // NOTE: This callback only fires if there was an entry animation
    // configured. It does not fire if there was no animation!
    setEntered(true);
  });

  return { videoRefCb: videoRefCb, onEntered };
}

function Logo() {
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  return <TrainingLogo ref={animator.ref('logo')} />;
}

function BigTitle(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />
      <Animate
        name='heading'
        className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}

function Classic(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { videoRefCb, onEntered } = useVideoPlayback(props.ctrl);

  const mediaJSX =
    props.block.fields.media?.media?.type === EnumsMediaType.MediaTypeVideo ? (
      <VideoWithUnmutedAutoplay
        playsInline
        muted={props.ctrl.primaryMedia?.silent}
        src={props.ctrl.primaryMedia?.url ?? ''}
        className='w-full h-full object-contain rounded-xl'
        refCb={videoRefCb}
      />
    ) : (
      <img
        src={props.ctrl.primaryMedia?.url ?? ''}
        alt=''
        className='w-full h-full object-contain rounded-xl'
      />
    );

  return (
    <div className='w-full flex flex-col sm:flex-row gap-5 p-10 sm:p-13 lg:p-15'>
      <div className='w-full sm:w-1/2 flex flex-col gap-5'>
        <Logo />
        <Animate
          name='heading'
          className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
        >
          <EditableTextDisplay value={props.block.fields.heading ?? ''} />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-2xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
      <Animate name='media' className='w-full sm:w-1/2' onEntered={onEntered}>
        {mediaJSX}
      </Animate>
    </div>
  );
}

function getListStyleClass(style?: EnumsSlideListStyle | null): string {
  switch (style) {
    case EnumsSlideListStyle.SlideListStyleNumbers:
      return 'list-decimal';
    case EnumsSlideListStyle.SlideListStyleBullets:
      return 'list-disc';
    case EnumsSlideListStyle.SlideListStyleNone:
      return 'list-none';
    default:
      return 'list-decimal'; // Default to numbers for backward compatibility
  }
}

function BulletPoints(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />

      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <ol
        className={`
          ${getListStyleClass(props.block.fields.listStyle)} break-words
          text-base sm:text-xl lg:text-2xl
          space-y-2 sm:space-y-4 pl-5 sm:pl-8 lg:pl-10
        `}
      >
        {props.block.fields.bulletPoints.map((point, index) => (
          <BlockAnimation<SlideBlockAnimationKey, 'li'>
            key={index}
            name={`bullet-${index + 1}`}
            as='li'
          >
            {point}
          </BlockAnimation>
        ))}
      </ol>
    </div>
  );
}

function TitleAndText(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />
      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}
function Quote(props: { block: SlideBlock }) {
  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col gap-5 md:max-w-2xl mt-18 lg:mt-0 lg:justify-center'>
        <Logo />
        <Animate
          name='heading'
          className='text-2.5xl sm:text-3.5xl md:text-4.5xl font-bold leading-normal justify-start'
        >
          <EditableTextDisplay
            value={`\u201C${props.block.fields.heading}\u201D`}
          />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
    </div>
  );
}

function QuoteMedia(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { videoRefCb, onEntered } = useVideoPlayback(props.ctrl);

  const mediaJSX =
    props.block.fields.media?.media?.type === EnumsMediaType.MediaTypeVideo ? (
      <VideoWithUnmutedAutoplay
        playsInline
        muted={props.ctrl.primaryMedia?.silent}
        src={props.ctrl.primaryMedia?.url ?? ''}
        className='w-full h-full object-contain rounded-xl'
        refCb={videoRefCb}
      />
    ) : (
      <img
        src={props.ctrl.primaryMedia?.url ?? ''}
        alt=''
        className='w-full h-full object-contain rounded-xl'
      />
    );

  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8 mt-18 lg:mt-0'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            className='text-xl sm:text-2xl md:text-3.5xl font-bold leading-normal justify-start'
          >
            <EditableTextDisplay
              value={`\u201C${props.block.fields.heading}\u201D`}
            />
          </Animate>
          <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
            <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
          </Animate>
        </div>
        <Animate
          name='media'
          className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'
          onEntered={onEntered}
        >
          {mediaJSX}
        </Animate>
      </div>
    </div>
  );
}

const BigTitleMedia = (props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
}) => {
  const { videoRefCb, onEntered } = useVideoPlayback(props.ctrl);

  const mediaJSX =
    props.block.fields.media?.media?.type === EnumsMediaType.MediaTypeVideo ? (
      <VideoWithUnmutedAutoplay
        playsInline
        muted={props.ctrl.primaryMedia?.silent}
        src={props.ctrl.primaryMedia?.url ?? ''}
        className='w-full h-full object-contain rounded-xl'
        refCb={videoRefCb}
      />
    ) : (
      <img
        src={props.ctrl.primaryMedia?.url ?? ''}
        alt=''
        className='w-full h-full object-contain rounded-xl'
      />
    );

  return (
    <div className='w-full h-full flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8 p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col gap-4 md:w-1/2'>
        <Logo />
        <Animate
          name='heading'
          className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
        >
          <EditableTextDisplay value={props.block.fields.heading ?? ''} />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
      <Animate
        name='media'
        className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'
        onEntered={onEntered}
      >
        <div className='flex items-center justify-center'>{mediaJSX}</div>
      </Animate>
    </div>
  );
};

function BulletPointsMedia(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
}) {
  const { videoRefCb, onEntered } = useVideoPlayback(props.ctrl);

  const mediaJSX =
    props.block.fields.media?.media?.type === EnumsMediaType.MediaTypeVideo ? (
      <VideoWithUnmutedAutoplay
        playsInline
        muted={props.ctrl.primaryMedia?.silent}
        src={props.ctrl.primaryMedia?.url ?? ''}
        className='w-full object-contain rounded-xl'
        refCb={videoRefCb}
      />
    ) : (
      <img
        src={props.ctrl.primaryMedia?.url ?? ''}
        alt=''
        className='w-full object-contain rounded-xl'
      />
    );

  return (
    <div className='w-full h-full flex flex-col md:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col sm:flex-row sm:items-start sm:justify-between md:gap-8'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
          >
            <EditableTextDisplay value={props.block.fields.heading ?? ''} />
          </Animate>
          <ol
            className={`
                min-h-0 flex-1
                ${getListStyleClass(props.block.fields.listStyle)} break-words
                text-base sm:text-xl lg:text-2xl
                space-y-2 sm:space-y-4 pl-5 sm:pl-8 lg:pl-10
            `}
          >
            {props.block.fields.bulletPoints.map((point, index) => (
              <BlockAnimation<SlideBlockAnimationKey, 'li'>
                key={index}
                name={`bullet-${index + 1}`}
                as='li'
              >
                {point}
              </BlockAnimation>
            ))}
          </ol>
        </div>
        <Animate
          name='media'
          className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'
          onEntered={onEntered}
        >
          {mediaJSX}
        </Animate>
      </div>
    </div>
  );
}

function BigMedia(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { videoRefCb, onEntered } = useVideoPlayback(props.ctrl);

  const element =
    props.block.fields.media?.media?.type === EnumsMediaType.MediaTypeVideo ? (
      <VideoWithUnmutedAutoplay
        src={props.ctrl.primaryMedia?.url ?? ''}
        muted={props.ctrl.primaryMedia?.silent}
        playsInline
        className='w-full object-contain'
        refCb={videoRefCb}
      />
    ) : (
      <img
        src={props.ctrl.primaryMedia?.url ?? ''}
        alt=''
        className='w-full object-contain'
      />
    );

  return (
    <Animate
      name='media'
      className='w-full h-full flex justify-center items-center'
      onEntered={onEntered}
    >
      {element}
    </Animate>
  );
}

function FreeForm(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { block } = props;
  const body = block.fields.freeFormBody;
  const editor = useCreateBlockNote({
    animations: false,
    initialContent: body?.json
      ? (body.json.content as PartialBlock[])
      : undefined,
  });

  const { call: initFromMarkdown } = useLiveAsyncCall(async () => {
    if (!body?.markdown || body.markdownUpdatedAt <= body.jsonUpdatedAt) return;
    try {
      const blocks = await editor.tryParseMarkdownToBlocks(body.markdown);
      if (blocks.length === 0) return;
      editor.replaceBlocks(editor.document, blocks);
    } catch (error) {
      props.ctrl.logger.error(
        'Error update freeform from markdown',
        err2s(error)
      );
    }
  });

  useEffect(() => {
    initFromMarkdown();
  }, [initFromMarkdown]);

  return (
    <BlockNoteView
      editable={false}
      editor={editor}
      theme='dark'
      data-theming-slide-block
    />
  );
}

function Content(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  switch (props.block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutBigTitle:
      return <BigTitle block={props.block} />;
    case EnumsSlideLayout.SlideLayoutClassic:
      return <Classic block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutBulletPoints:
      return <BulletPoints block={props.block} />;
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      return <TitleAndText block={props.block} />;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return null;
    case EnumsSlideLayout.SlideLayoutQuote:
      return <Quote block={props.block} />;
    case EnumsSlideLayout.SlideLayoutQuoteMedia:
      return <QuoteMedia block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutBigTitleMedia:
      return <BigTitleMedia block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutBulletPointsMedia:
      return <BulletPointsMedia block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutBigMedia:
      return <BigMedia block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutFreeForm:
      return <FreeForm block={props.block} ctrl={props.ctrl} />;
    case EnumsSlideLayout.SlideLayoutIframe:
      return null;
    case EnumsSlideLayout.SlideLayoutSlideshow:
      return null;
    case EnumsSlideLayout.SlideLayoutHtml:
      return null;
    default:
      assertExhaustive(props.block.fields.layout);
      return null;
  }
}

/**
 * This utilizes a similar pool of previously unlocked video elements as Audio
 * pools. Videos must be present in the DOM tree to be seen, so this component
 * manages to do that without an additional wrapper element: React restricts
 * which types of values/objects can be returned from render; we cannot directly
 * return a reference to a video element (or any element instance, for that
 * matter) so this uses a subtle trick.
 */
function VideoWithUnmutedAutoplay(props: {
  src: string | MediaStream;
  autoPlay?: boolean;
  className?: string;
  muted?: boolean;
  playsInline?: boolean;
  refCb?: (el: HTMLVideoElement | null) => void;
}) {
  const unplayableRef = useRef<UnplayableVideoImpl | null>(null);
  const [readyForInsert, setReadyForInsert] = useState(false);
  const { refCb } = props;

  useLayoutEffect(() => {
    // Handle video instance creation/update
    if (!unplayableRef.current || unplayableRef.current.source !== props.src) {
      unplayableRef.current?.release();
      unplayableRef.current = UnplayableVideoImpl.FromHTMLVideoPool(props.src);
      unplayableRef.current.intoPlayable(false);
    }

    // Signal that video is ready to be inserted
    setReadyForInsert(true);

    return () => {
      unplayableRef.current?.media.remove();
      unplayableRef.current?.release();
      setReadyForInsert(false);
    };
  }, [props.src]);

  useLayoutEffect(() => {
    if (!unplayableRef.current) return;
    unplayableRef.current.media.muted = props.muted ?? false;
    unplayableRef.current.media.autoplay = props.autoPlay ?? false;
    unplayableRef.current.media.playsInline = props.playsInline ?? false;
    unplayableRef.current.media.className = props.className ?? '';
  }, [props.autoPlay, props.muted, props.playsInline, props.className]);

  // Separate effect for refCb to prevent unstable cb references from having
  // detrimental effects
  useLayoutEffect(() => {
    refCb?.(unplayableRef.current?.media ?? null);
  }, [refCb]);

  return (
    <>
      {readyForInsert && (
        <span
          ref={(el) => {
            if (
              el &&
              unplayableRef.current?.media &&
              !unplayableRef.current.media.parentElement
            ) {
              // The entire goal here is to avoid having a wrapper element. So
              // we use the span as an "anchor" to insert the video element as a
              // sibling. React seems ok with this, likely because there is not
              // a complex tree here to diff.
              el.insertAdjacentElement('afterend', unplayableRef.current.media);
              setReadyForInsert(false); // Remove the span after insertion
            }
          }}
        />
      )}
    </>
  );
}

function Background(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { color } = useOrgBrandColor();

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutHtml) {
    return (
      <div className='absolute inset-0'>
        <HTMLPlayer block={props.block} ctrl={props.ctrl} />;
      </div>
    );
  }

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutSlideshow) {
    return (
      <div className='absolute inset-0'>
        <DialogueSlideShow ctrl={props.ctrl.dialoguePlayer} />;
      </div>
    );
  }

  if (
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutIframe &&
    props.block.fields.iframeUrl
  ) {
    return (
      <div className='absolute inset-0'>
        <iframe
          src={props.block.fields.iframeUrl}
          className='w-full h-full'
          title='Iframe'
        />
      </div>
    );
  }

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost)
    return (
      <div className='absolute inset-0'>
        <DialoguePlayer ctrl={props.ctrl.dialoguePlayer} />
      </div>
    );

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutBigMedia) {
    const firstFrameUrl = MediaUtils.PickMediaUrl(
      fromMediaDTO(props.block.fields.media?.media),
      {
        videoThumbnail: 'first',
        priority: ImagePickPriorityHighToLow,
      }
    );

    if (!firstFrameUrl) return null;

    return (
      <div className='fixed inset-0 overflow-hidden'>
        <img
          src={firstFrameUrl ?? ''}
          alt=''
          className='w-full h-full object-cover blur opacity-50 select-none'
        />
      </div>
    );
  }

  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    return (
      <div className='fixed inset-0'>
        <DialoguePlayer ctrl={props.ctrl.dialoguePlayer} />
        <Animate
          name='overlay'
          className={`absolute inset-0 bg-opacity-60 bg-black`}
        />
      </div>
    );
  }

  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionBrand
  ) {
    return (
      <div
        className='fixed inset-0 opacity-20'
        style={{ backgroundColor: color }}
      />
    );
  }

  const isMediaOption =
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionMedia;
  const isMediaWithOverlayOption =
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionMediaWithOverlay;

  if (
    (isMediaOption || isMediaWithOverlayOption) &&
    props.block.fields.backgroundMedia
  ) {
    const mediaUrl = MediaUtils.PickMediaUrl(
      fromMediaDTO(props.block.fields.backgroundMedia.media),
      { priority: ImagePickPriorityHighToLow }
    );

    if (!mediaUrl) return null;

    return (
      <div className='fixed inset-0'>
        {props.block.fields.backgroundMedia?.media?.type ===
        EnumsMediaType.MediaTypeVideo ? (
          <video
            autoPlay
            muted
            loop
            src={mediaUrl}
            className='w-full h-full object-cover rounded-xl'
            playsInline
          />
        ) : (
          <img
            src={mediaUrl}
            alt=''
            className='w-full h-full object-cover rounded-xl'
          />
        )}
        {isMediaWithOverlayOption && (
          <Animate
            name='overlay'
            className={`absolute inset-0 bg-opacity-60 bg-black`}
          />
        )}
      </div>
    );
  }

  return null;
}

function Buttons(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { status, hideButtons } = useSnapshot(props.ctrl.state);

  if (hideButtons) return <div className='h-[78px]' />;

  return (
    <footer
      className={`w-full flex items-center justify-center gap-2 p-3 pb-5`}
    >
      {props.ctrl.showReplayButton && (
        <CommonButton
          variant='gray'
          onClick={() => {
            props.ctrl.replay();
          }}
          disabled={status !== 'presented'}
          styles={{ size: 'h-11.5' }}
          style={{
            aspectRatio: '1/1',
          }}
        >
          <RefreshIcon className='w-4 h-4 fill-current' />
        </CommonButton>
      )}
      <CommonButton
        variant='brand'
        onClick={() => {
          props.ctrl.playSFX('instructionHoverReadyButton');
          props.ctrl.end();
        }}
        disabled={status !== 'presented'}
      >
        <EditableTextDisplay value={props.block.fields.buttonText ?? ''} />
      </CommonButton>
    </footer>
  );
}

export function SlideBlockPlaygroundInternal(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  useEffectOnce(() => {
    props.ctrl.present();
  });

  const isFullHostLayout =
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;

  const showContent = ![
    EnumsSlideLayout.SlideLayoutHtml,
    EnumsSlideLayout.SlideLayoutSlideshow,
    EnumsSlideLayout.SlideLayoutIframe,
    EnumsSlideLayout.SlideLayoutFullHost,
  ].includes(props.block.fields.layout);

  // TODO(GQ): we should refactor the layout, but just work around it for now
  return (
    <>
      <Background block={props.block} ctrl={props.ctrl} />

      {showContent ? (
        <BlockContainer className='flex flex-col text-white'>
          {showContent && (
            <div className='flex-1 min-h-0'>
              <ScrollableContent>
                <Content block={props.block} ctrl={props.ctrl} />
              </ScrollableContent>
            </div>
          )}

          <Buttons block={props.block} ctrl={props.ctrl} />
        </BlockContainer>
      ) : (
        <>
          {isFullHostLayout && <Captions>{props.subtitlesArea}</Captions>}
          <div className='absolute bottom-0 left-0 right-0'>
            <Buttons block={props.block} ctrl={props.ctrl} />
          </div>
        </>
      )}
    </>
  );
}

export function SlideBlockPlayground(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  return (
    <BlockAnimatorProvider value={props.ctrl.blockAnimator}>
      <SlideBlockPlaygroundInternal {...props} />
    </BlockAnimatorProvider>
  );
}
