import pluralize from 'pluralize';

import { JEOPARDY_TURNS_COUNT } from './types';

export function JeopardyGamePrompt() {
  return (
    <div
      className='
          w-full max-w-70 h-[fit-content] rounded-xl border border-white
          px-4 pt-5 pb-2.5 
          flex flex-col items-center gap-2.5 text-center text-white
        '
      style={{
        background: 'linear-gradient(180deg, #FF0935 0%, #0B1887 100%)',
      }}
    >
      <h2 className='text-sms font-bold'>
        You only play {pluralize('clue', JEOPARDY_TURNS_COUNT, true)} from the
        board.
        <br />
        Choose wisely to get the best score you can!
      </h2>

      <p className='text-2xs'>Choose a clue to continue</p>
    </div>
  );
}
