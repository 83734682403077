import {
  type CSSProperties,
  forwardRef,
  type InputHTMLAttributes,
  type Ref,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';

type RawInputStyles = Partial<{
  text: string;
  size: string;
  spacing: string;
  bg: string;
  border: string;
  brandColor: string;
}>;

const RawInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    styles?: RawInputStyles;
  }
>((props, ref) => {
  const { styles, ...rest } = props;
  return (
    <input
      ref={ref}
      {...rest}
      style={
        {
          ...props.style,
          '--brand-color': styles?.brandColor,
        } as CSSProperties
      }
      className={`
        appearance-none focus:outline-none
        transition-colors
        ${styles?.size ?? 'w-full'}
        ${styles?.spacing ?? 'px-5 py-2'}
        ${
          styles?.text ??
          'text-center text-white text-base sm:text-xl lg:text-2xl font-bold placeholder-italic placeholder-icon-gray'
        }
        ${styles?.bg ?? 'bg-transparent'}
        ${
          styles?.border ??
          'border-b focus:border-[color:var(--brand-color)] rounded-none'
        }
        ${props.className || ''}
      `}
    />
  );
});

type InputVariants = Record<string, RawInputStyles>;

type InputProps<T extends InputVariants> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'ref'
> & {
  variant: keyof T;
  variants: T;
  styles?: RawInputStyles;
};

export const Input = forwardRef(
  <T extends InputVariants = InputVariants>(
    props: InputProps<T>,
    ref: Ref<HTMLInputElement>
  ) => {
    const { variant, variants, styles, ...rest } = props;

    return (
      <RawInput
        ref={ref}
        styles={{ ...styles, ...variants[variant] }}
        {...rest}
      />
    );
  }
);

export type CommonInputVariant = 'correct' | 'incorrect' | 'brand';

const commonVariants: Record<CommonInputVariant, RawInputStyles> = {
  correct: {
    border: 'border-b border-green-001 rounded-none',
  },
  incorrect: {
    border: 'border-b border-red-001 rounded-none',
  },
  brand: {
    brandColor: '#FBB707',
  },
} as const;

export const CommonInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps<typeof commonVariants>, 'variants'> & {
    // Whether this component should always reflect the latest defaultValue prop
    // value. Default: updates are enabled
    disableDefaultValueUpdates?: boolean;
  }
>((props, ref) => {
  const { color } = useOrgBrandColor();

  const variants = useMemo(
    () => ({
      ...commonVariants,
      brand: color
        ? {
            ...commonVariants.brand,
            brandColor: color,
          }
        : commonVariants.brand,
    }),
    [color]
  );

  // This effect allows the component to remain uncontrolled within its parent,
  // but _also_ react to prop changes from that parent in case defaultValue
  // changes via rerender. The use case is that we're showing an input with
  // value A, but then an update from the server arrives with value B. We'd want
  // to show value B, which wouldn't happen normally when using `defaultValue`.
  // We could use another trick, such as `key={props.defaultValue}` outside of
  // this component, but that requires every component be aware of this
  // requirement: error prone and no (easy) way to enforce with types.
  const internalRef = useRef<HTMLInputElement | null>(null);
  const prevDefaultValue = useRef(props.defaultValue);
  useLayoutEffect(() => {
    if (
      !props.disableDefaultValueUpdates &&
      props.defaultValue !== prevDefaultValue.current &&
      internalRef.current &&
      internalRef.current !== document.activeElement
    ) {
      internalRef.current.value = String(props.defaultValue);
    }

    prevDefaultValue.current = props.defaultValue;
  }, [props.defaultValue, props.disableDefaultValueUpdates]);

  // This callback allows us to pass a single ref callback to the rendered
  // component, but intercept the calls to ensure we get a handle to the element
  // as well. We need a stable cb fn for react, otherwise it will go through a
  // cycle of el -> null -> each render.
  const refMerge = useLiveCallback((el: HTMLInputElement | null) => {
    internalRef.current = el;
    if (typeof ref === 'function') ref(el);
    else if (ref) ref.current = el;
  });

  return <Input ref={refMerge} {...props} variants={variants} />;
});
