import { forwardRef, useRef } from 'react';

import {
  type DtoGame,
  type DtoTTSRenderRequest,
  EnumsBlockIntro,
} from '@lp-lib/api-service-client/public';
import { type Block, BlockTypeV2 } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';
import { MediaFormatVersion } from '@lp-lib/media';

import { getStaticAssetPath } from '../../../utils/assets';
import { sleep } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';
import type { BlockDependencies } from '../types';
import { useOrgMasqueradeFallback } from './OrgMasqueradeFallback';
import { type SFXControl } from './SFXControl';

const stingers: { [key in BlockTypeV2]: Nullable<string> } = {
  [BlockTypeV2.QUESTION]: getStaticAssetPath(
    'images/question-block-stinger.png'
  ),
  [BlockTypeV2.MULTIPLE_CHOICE]: getStaticAssetPath(
    'images/multiple-choice-block-stinger.png'
  ),
  [BlockTypeV2.MATCH]: getStaticAssetPath('images/match-block-stinger.png'),
  [BlockTypeV2.SLIDE]: null,
  [BlockTypeV2.SPARKIFACT]: null,
  [BlockTypeV2.ROLEPLAY]: getStaticAssetPath(
    'images/roleplay-block-stinger.png'
  ),
  [BlockTypeV2.DRAW_TO_WIN]: getStaticAssetPath(
    'images/draw-to-win-block-stinger.png'
  ),
  [BlockTypeV2.HIDDEN_PICTURE]: getStaticAssetPath(
    'images/hidden-picture-block-stinger.png'
  ),
  [BlockTypeV2.JEOPARDY]: null,
  [BlockTypeV2.SWIPE_TO_WIN]: getStaticAssetPath(
    'images/swipe-to-win-block-stinger.png'
  ),
  [BlockTypeV2.FILL_IN_THE_BLANKS]: null,
  [BlockTypeV2.SCENARIO]: getStaticAssetPath(
    'images/roleplay-block-stinger.png'
  ),
  [BlockTypeV2.RESULTS]: null,
};

export function useStingerElements() {
  const blockStingerEl = useRef<HTMLImageElement | null>(null);
  const minigameStingerEl = useRef<HTMLDivElement | null>(null);
  return {
    blockStingerEl,
    minigameStingerEl,
  };
}

export const BlockStinger = forwardRef<HTMLImageElement | null>((_, ref) => {
  return (
    <div className='absolute inset-0 pointer-events-none hidden w-full h-full items-center justify-center px-10'>
      <img
        ref={ref}
        className='w-full max-w-75 h-auto object-scale-down opacity-0'
        alt='Block Intro'
      />
    </div>
  );
});

export const MinigameStinger = forwardRef<HTMLDivElement | null>((_, ref) => {
  const org = useOrgMasqueradeFallback();
  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  const { color: primaryColor } = useOrgBrandColor({
    format: 'hsla',
  });
  const bgColor = `hsl(from ${primaryColor} h s calc(l * 0.5))`;

  return (
    <div className='absolute inset-0 overflow-hidden pointer-events-none hidden'>
      <div
        ref={ref}
        className='absolute top-1/2 transform -translate-y-1/2 w-full rounded-full'
        style={{
          aspectRatio: '1/1',
          backgroundColor: bgColor,
        }}
      />
      <div className='relative flex flex-col w-full h-full items-center justify-center gap-2'>
        {logoSrc && (
          <div className='flex-none w-20 h-20 rounded-md overflow-hidden'>
            <img src={logoSrc} alt='' className='w-full h-full object-cover' />
          </div>
        )}
        <div className='px-10 text-center text-white font-bold text-2xl' />
      </div>
    </div>
  );
});

export class StingerControl {
  constructor(
    private els: ReturnType<typeof useStingerElements>,
    private sfxControl: SFXControl,
    private createLVOLocalPlayer: BlockDependencies['createLVOLocalPlayer'],
    private logger: Logger
  ) {}

  isReady() {
    return (
      this.els.blockStingerEl.current && this.els.minigameStingerEl.current
    );
  }

  shouldPreloadTTS(block: Block) {
    return (
      block.fields.intro === EnumsBlockIntro.BlockIntroVoice ||
      block.fields.intro === EnumsBlockIntro.BlockIntroVoiceWithStinger
    );
  }

  async playMinigameIntro(minigame: DtoGame) {
    const el = this.els.minigameStingerEl.current;
    const parent = el?.parentElement;
    if (!el || !parent) return;

    parent.style.display = 'flex';
    const a = el.animate(
      [
        { transform: 'translateY(-50%) scale(0)', opacity: 0 },
        { transform: 'translateY(-50%) scale(1)', opacity: 1, offset: 0.3 },
        { transform: 'translateY(-50%) scale(3)', opacity: 1 },
      ],
      {
        duration: 500,
        easing: 'ease-in-out',
        fill: 'both',
      }
    );
    this.sfxControl.play('blockStingerEnter');
    if (el.nextElementSibling?.lastElementChild) {
      el.nextElementSibling.lastElementChild.textContent = minigame.name;
    }
    try {
      await a.finished;
      await sleep(1500);
    } finally {
      this.resetMinigameStingerEl();
    }
  }

  async playBlockIntro(block: Block, req: Nullable<DtoTTSRenderRequest>) {
    const el = this.els.blockStingerEl.current;
    const parent = el?.parentElement;
    if (!el || !parent) return;

    const intro = block.fields.intro ?? EnumsBlockIntro.BlockIntroNone;
    if (intro === EnumsBlockIntro.BlockIntroNone) {
      return;
    }

    let src = uncheckedIndexAccess_UNSAFE(stingers)[
      block.type as never
    ] as Nullable<string>;

    if (intro === EnumsBlockIntro.BlockIntroVoice) {
      // voice only.
      src = null;
    } else if (intro === EnumsBlockIntro.BlockIntroStinger) {
      // stinger only.
      req = null;
    }

    // nothing to do.
    if (!src && !req) return;

    if (!req) {
      await this.enterBlockIntro(el, parent, src);
      await sleep(2000);
      await this.exitBlockIntro(el, parent, src);
      return;
    }

    try {
      const player = this.createLVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackStarted;
      await this.enterBlockIntro(el, parent, src);
      await info?.trackEnded;
      await this.exitBlockIntro(el, parent, src);
    } catch (e) {
      // something happened here, and we need to ensure a consistent experience.
      this.logger.error('failed to play block intro TTS', e);
      this.resetBlockStingerEl();
    }
  }

  resetBlockStingerEl() {
    const el = this.els.blockStingerEl.current;
    const parent = el?.parentElement;
    if (!el || !parent) return;
    el.style.opacity = '0';
    el.style.transform = 'scale(1)';
    el.src = '';
    parent.style.display = 'none';
  }

  resetMinigameStingerEl() {
    const el = this.els.minigameStingerEl.current;
    const parent = el?.parentElement;
    if (!el || !parent) return;
    el.style.opacity = '0';
    el.style.transform = 'scale(1)';
    parent.style.display = 'none';
  }

  private async enterBlockIntro(
    el: HTMLImageElement,
    parent: HTMLElement,
    src: Nullable<string>
  ) {
    if (!src) return;
    parent.style.display = 'flex';
    el.src = src;
    const anim = el.animate(
      [
        { transform: 'scale(4)', opacity: 0 },
        { transform: 'scale(1)', opacity: 1 },
      ],
      {
        duration: 350,
        easing: 'ease-in',
        fill: 'both',
      }
    );
    this.sfxControl.play('blockStingerEnter');
    await anim.finished;
  }

  private async exitBlockIntro(
    el: HTMLImageElement,
    parent: HTMLElement,
    src: Nullable<string>
  ) {
    if (!src) return;
    const anim = el.animate(
      [
        { transform: 'scale(1)', opacity: 1, offset: 0 },
        { transform: 'scale(1.5)', opacity: 0.8, offset: 0.8 },
        { transform: 'scale(0)', opacity: 0 },
      ],
      {
        duration: 350,
        easing: 'ease-out',
        fill: 'both',
      }
    );
    await anim.finished;
    parent.style.display = 'none';
    el.src = '';
  }
}

export function getStingerPrompt(
  context: string,
  experienceTitle: string,
  experienceDescription: string
) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a scriptwriter for an online platform called Luna Park. Your only job is to write a script that introduces a new experience to the user. 

Your scripts should be short and creative. You should assume that the user has just finished learning some new information, and their next experience will be related to that information. 
You should also assume that the user will see some kind of visual related to your script, for instance an animated image with the name of the experience, and you should assume the user will hear the pertinent instructions to the experience after your introduction.

Never repeat the context in its entirety, but you may broadly reference the context in your script. Never include any information that is not directly related to the context.

<example>
Context: Why is it challenging to host for an unseen audience?
Experience Title: Quick Q
Experience Description: An open-ended question.
Output: Time to test your hosting skills!
</example>

<example>
Context: What does SQL stand for in the context of databases?
Experience Title: Tap the Fact
Experience Description: A multiple-choice question.
Output: Okay, let's put your database acronym knowledge to the test.
</example>

Output only the script, with nothing before or after.

Context: ${context}
Experience Title: ${experienceTitle}
Experience Description: ${experienceDescription}
\`\`\`  
`;
}
