export function Captions(props: { children: React.ReactNode }) {
  return (
    <div
      className='
        absolute top-[80%] left-0 right-0
        mx-auto flex-none

        h-12 lg:h-14 min-w-70 max-w-125

        text-center text-white text-base lg:text-xl font-bold

        z-5
      '
      style={{
        fontFamily: 'PT Sans Caption',
        textShadow: '1.5px 1.5px 1.5px black',
      }}
    >
      {props.children}
    </div>
  );
}
