import { uuid4 } from '@sentry/utils';
import axios from 'axios';

import {
  type DtoProgression,
  type DtoTrackProgressionBlockPlayedRequest,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';

export interface IProgressionTracker {
  trackBlockPlayed(
    packId: string,
    req: DtoTrackProgressionBlockPlayedRequest
  ): Promise<DtoProgression>;
  getProgression(packId: string): Promise<DtoProgression | null>;
}

class InMemoryProgressionTracker implements IProgressionTracker {
  private store = new Map<string, DtoProgression>();
  async trackBlockPlayed(
    packId: string,
    req: DtoTrackProgressionBlockPlayedRequest
  ) {
    const now = new Date().toISOString();
    const progression = this.store.get(packId) ?? {
      id: uuid4(),
      uid: '',
      createdAt: now,
      updatedAt: now,
      gamePackId: packId,
      blockProgressPct: 0,
    };
    progression.updatedAt = new Date().toISOString();

    // game pack progress
    if (req.willCompleteGamePack) {
      progression.completedAt = now;
    }

    // minigame progress
    {
      const progress = progression.progress ?? {};
      const minigameProgress = progress[req.minigameId] ?? {
        completedAt: null,
        minigameId: req.minigameId,
        unlockedAt: now,
      };
      if (req.willCompleteMinigame) {
        minigameProgress.completedAt = now;
      }
      progress[req.minigameId] = minigameProgress;
      progression.progress = progress;
    }

    // block progress
    {
      const blockProgressMap = progression.blockProgress ?? {};
      const blockProgress = blockProgressMap[req.blockId] ?? {
        blockId: req.blockId,
        startedAt: now,
        unlockedAt: now,
      };
      blockProgress.completedAt = now;
      blockProgressMap[req.blockId] = blockProgress;
      progression.blockProgress = blockProgressMap;
    }

    // block outputs
    {
      const blockOutputs = progression.blockOutputs ?? {};
      for (const [key, value] of Object.entries(req.outputs ?? {})) {
        blockOutputs[key] = value;
      }
      progression.blockOutputs = blockOutputs;
    }

    // next destination
    if (req.nextDestination) {
      const blockProgressMap = progression.blockProgress ?? {};
      const nextBlockProgress = blockProgressMap[
        req.nextDestination.blockId
      ] ?? {
        blockId: req.nextDestination.blockId,
        startedAt: now,
        unlockedAt: now,
      };
      blockProgressMap[req.nextDestination.blockId] = nextBlockProgress;
      progression.blockProgress = blockProgressMap;

      const progress = progression.progress ?? {};
      const nextMinigameProgress = progress[req.nextDestination.minigameId] ?? {
        minigameId: req.minigameId,
        unlockedAt: now,
      };
      progress[req.nextDestination.minigameId] = nextMinigameProgress;
      progression.progress = progress;
    }

    this.store.set(packId, progression);
    return progression;
  }

  async getProgression(packId: string) {
    return this.store.get(packId) ?? null;
  }
}

class PersistedProgressionTracker implements IProgressionTracker {
  async trackBlockPlayed(
    packId: string,
    req: DtoTrackProgressionBlockPlayedRequest
  ) {
    const resp = await apiService.progression.trackMyProgressionBlockPlayed(
      packId,
      req
    );
    return resp.data.progression;
  }

  async getProgression(packId: string) {
    try {
      const resp = await apiService.progression.getMyProgression(packId);
      return resp.data.progression;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      } else {
        throw error;
      }
    }
  }
}

export function newProgressionTracker(
  kind: 'in-memory' | 'persist'
): IProgressionTracker {
  switch (kind) {
    case 'in-memory':
      return new InMemoryProgressionTracker();
    case 'persist':
      return new PersistedProgressionTracker();
    default:
      throw new Error(`Unknown progression tracker kind: ${kind}`);
  }
}
