import { forwardRef, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';

import {
  type DtoBlock,
  type DtoProgression,
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import { fromAPIBlockType, type ResultsBlock } from '@lp-lib/game';
import { getBlockOutputsById } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';
import { trainingSummarizeBlockPerformanceResultSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/trainingSummarizeBlockPerformanceResult';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { fromDTOBlock } from '../../../../utils/api-dto';
import { isMobile } from '../../../../utils/user-agent';
import {
  markSnapshottable,
  useSnapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { EnterExitTailwindTransition } from '../../../common/TailwindTransition';
import { ArrowDownIcon, ArrowUpIcon } from '../../../icons/Arrows';
import { SparkBlockIcon } from '../../../icons/Block';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { Loading } from '../../../Loading';
import { TrainingEditorUtils } from '../../../Training/Editor/utils';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import { ScrollableContent } from '../../design/ScrollableContent';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import {
  type BlockGradeResult,
  blockOutputsToGradeResult,
  blockTypePlayable,
} from '../block-grade-result';
import { PointsBadge } from './PointsBadge';

type SummarizedBlockGradeResult = BlockGradeResult & {
  block: DtoBlock;
  summary?: string;
};

const parseSchema = trainingSummarizeBlockPerformanceResultSchema.parse;

type SummarizeTask = {
  results: SummarizedBlockGradeResult[];
  presented: boolean;
  status: 'init' | 'loading' | 'loaded' | 'error' | 'play' | 'complete';
};

type State = {
  correct: SummarizeTask;
  incorrect: SummarizeTask;
  totalPoints: number;
  earnedPoints: number;
  current?: 'correct' | 'incorrect';
};

function blockGradeResultToVariable(result: BlockGradeResult) {
  return `
  \`\`\`xml
  <block id="${result.blockId}" type="${result.blockType}">${result.context}</block>
  \`\`\`
  `.trim();
}

export class ResultsBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      totalPoints: 0,
      earnedPoints: 0,
      correct: {
        results: [],
        presented: false,
        status: 'init',
      },
      incorrect: {
        results: [],
        presented: false,
        status: 'init',
      },
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private resolvedTTS: {
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
  } = {
    correct: null,
    incorrect: null,
  };
  readonly logger: Logger;
  constructor(private block: ResultsBlock, readonly deps: BlockDependencies) {
    this.logger = deps.getLogger('results-block');
  }

  get state() {
    return this._state;
  }

  async preload() {
    return;
  }
  async initialize() {
    const blocks = this.deps.getBlocks();
    const idx = blocks.findIndex((b) => b.id === this.block.id);
    const excluded = new Set(this.block.fields.excludeBlockIds);
    const precedingPlayableBlocks =
      idx === -1
        ? []
        : blocks
            .slice(0, idx)
            .filter((b) => blockTypePlayable(b.type) && !excluded.has(b.id));
    this.logger.info('Preceding playable blocks', {
      blocks: precedingPlayableBlocks.map((b) => ({
        id: b.id,
        type: b.type,
      })),
    });
    let pBlockOutputs: DtoProgression['blockOutputs'] = null;
    try {
      const progression = await this.getProgression();
      pBlockOutputs = progression?.blockOutputs ?? null;
    } catch (error) {
      this.logger.error('Failed to get progression', error);
    }
    this.logger.info('block outputs', {
      blockOutputs: pBlockOutputs,
    });
    const passed: SummarizedBlockGradeResult[] = [];
    const failed: SummarizedBlockGradeResult[] = [];
    for (const block of precedingPlayableBlocks) {
      const blockOutputs = getBlockOutputsById(block.id, pBlockOutputs);
      const result = blockOutputsToGradeResult(
        block,
        blockOutputs,
        this.deps.isPreview()
      );
      this._state.totalPoints += result.totalPoints;
      this._state.earnedPoints += result.earnedPoints;
      if (result.status === 'passed') {
        passed.push({ ...result, block });
      } else if (result.status === 'failed') {
        failed.push({ ...result, block });
      }
    }
    this.logger.info('block grade results w/o summary', {
      passed: ValtioUtils.detachCopy(passed),
      failed: ValtioUtils.detachCopy(failed),
      mock: this.deps.isPreview(),
    });
    this.summarize(
      passed,
      'results/summarize-correct-answers-vo',
      'results/summarize-correct-answers-ui',
      (results) => {
        this._state.correct.results = results;
        this._state.correct.status = 'loading';
      },
      (tts, results) => {
        this.resolvedTTS.correct = tts;
        this._state.correct.results = results;
        this.deps.lvoLocalCacheWarm(this.resolvedTTS.correct);
        this.logger.info('block summaries for correct answers', {
          voiceOverScript: tts?.script ?? null,
          results: ValtioUtils.detachCopy(results),
        });
      }
    )
      .then(() => (this._state.correct.status = 'loaded'))
      .catch((err) => {
        this.logger.error('Failed to summarize correct answers', err);
        this._state.correct.status = 'error';
      });
    this.summarize(
      failed,
      'results/summarize-incorrect-answers-vo',
      'results/summarize-incorrect-answers-ui',
      (results) => {
        this._state.incorrect.results = results;
        this._state.incorrect.status = 'loading';
      },
      (tts, results) => {
        this.resolvedTTS.incorrect = tts;
        this._state.incorrect.results = results;
        this.deps.lvoLocalCacheWarm(this.resolvedTTS.incorrect);
        this.logger.info('block summaries for incorrect answers', {
          voiceOverScript: tts?.script ?? null,
          results: ValtioUtils.detachCopy(results),
        });
      }
    )
      .then(() => (this._state.incorrect.status = 'loaded'))
      .catch((err) => {
        this.logger.error('Failed to summarize incorrect answers', err);
        this._state.incorrect.status = 'error';
      });
  }

  async present() {
    return;
  }

  async presentGroup(group: 'correct' | 'incorrect') {
    this._state.current = group;
    this._state[group].presented = true;
  }

  replay() {
    if (!this._state.current) return;
    this.play(this._state.current);
  }

  async play(group: 'correct' | 'incorrect') {
    try {
      this._state[group].status = 'play';
      const player = this.deps.createLVOLocalPlayer(this.resolvedTTS[group]);
      const info = await player.playFromPool();
      await info?.trackStarted;
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        `failed to play ${group} TTS, falling back to silence`,
        e
      );
    } finally {
      this._state[group].status = 'complete';
    }
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private async summarize(
    results: SummarizedBlockGradeResult[],
    voKey: string,
    uiKey: string,
    onStart: (results: SummarizedBlockGradeResult[]) => void,
    onComplete: (
      tts: Nullable<DtoTTSRenderRequest>,
      results: SummarizedBlockGradeResult[]
    ) => void
  ) {
    onStart(results);
    if (results.length === 0) {
      onComplete(null, []);
      return;
    }
    const variables = {
      blockGradeResults: results
        .map((r) => blockGradeResultToVariable(r))
        .join('\n\n'),
    };
    const vo = new Promise<Nullable<DtoTTSRenderRequest>>(async (resolve) => {
      if (!this.block.fields.personalityId) {
        resolve(null);
        return;
      }
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: voKey,
        variables,
      });
      resolve(this.makeTTSRenderRequest(resp.data.content));
    });
    const ui = new Promise<SummarizedBlockGradeResult[]>(async (resolve) => {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: uiKey,
        variables,
      });
      const output = parseSchema(resp.data.toolCalls.at(0)?.args);
      resolve(
        results.map((r) => ({
          ...r,
          summary: output.summaries.find((s) => s.blockId === r.blockId)
            ?.summary,
        }))
      );
    });
    const resolved = await Promise.all([vo, ui]);
    onComplete(...resolved);
  }

  private async getProgression() {
    return await this.deps.progressionTracker.getProgression(
      this.deps.getPack().id
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }
}

function SummaryItem(props: {
  result: SummarizedBlockGradeResult;
  color: string;
  openByDefault: boolean;
}) {
  const { result } = props;
  const blockTitle = TrainingEditorUtils.BlockTitle(fromDTOBlock(result.block));
  const [open, setOpen] = useState(props.openByDefault);

  return (
    <div className='bg-dark-gray rounded-lg px-2.5 py-3 flex flex-col gap-2.5'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2.5 truncate'>
          <SparkBlockIcon
            className='flex-shrink-0 w-6 h-6'
            blockType={fromAPIBlockType(result.block.type)}
          />
          <div className='text-sms text-white truncate'>{blockTitle}</div>
        </div>
        <button
          type='button'
          className='btn flex items-center gap-2.5 ml-2'
          onClick={() => setOpen(!open)}
        >
          <div className='text-icon-gray'>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </button>
      </div>
      <div className={`text-icon-gray text-2xs ${open ? 'block' : 'hidden'}`}>
        {result.summary ?? 'No summary available'}
      </div>
    </div>
  );
}

type SummaryGroupProps = {
  label: string;
  task: SummarizeTask;
  color: string;
  openByDefault: boolean;
  className?: string;
};

const SummaryGroup = forwardRef<HTMLDivElement, SummaryGroupProps>(
  (props, ref) => {
    const { task } = props;
    return (
      <div
        ref={ref}
        className={`w-full flex flex-col gap-2.5 max-w-100 transition-all duration-500 transform ${
          props.className ?? ''
        }`}
      >
        <header
          className='text-sms text-center font-bold'
          style={{ color: props.color }}
        >
          {props.label}
        </header>
        <main className='w-full flex items-center justify-center'>
          {match(task.status)
            .with('init', 'loading', () => (
              <Loading
                text='Summarizing your excerise'
                containerClassName='text-white'
              />
            ))
            .with('error', () => (
              <div className='text-red-002 text-sms text-center'>
                Something went wrong
              </div>
            ))
            .otherwise(() => (
              <div className='w-full flex flex-col gap-2'>
                {task.results.map((result) => (
                  <SummaryItem
                    key={result.blockId}
                    result={result}
                    color={props.color}
                    openByDefault={props.openByDefault}
                  />
                ))}
              </div>
            ))}
        </main>
      </div>
    );
  }
);

export function ResultsBlockPlayground(props: {
  block: ResultsBlock;
  ctrl: ResultsBlockControlAPI;
}) {
  const { block, ctrl } = props;
  const state = useSnapshot(ctrl.state);
  const current = state.current;
  const correct = state.correct as SummarizeTask;
  const incorrect = state.incorrect as SummarizeTask;
  const currentTask = current ? state[current] : null;
  const [openByDefault] = useState(!isMobile());
  const containerRef = useRef<HTMLDivElement | null>(null);

  const goNext = useLiveCallback(() => {
    let next: 'correct' | 'incorrect' | null = null;
    if (!current) {
      next = correct.results.length > 0 ? 'correct' : 'incorrect';
    } else if (current === 'correct') {
      next = incorrect.results.length > 0 ? 'incorrect' : null;
    } else {
      next = null;
    }
    if (next) {
      // the incorrect group will be slide in from the top on mobile,
      // make sure to scroll to the top of the container
      containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      ctrl.presentGroup(next);
    } else {
      ctrl.end();
    }
  });

  useEffectOnce(() => goNext());

  useEffect(() => {
    if (!current) return;
    if (currentTask?.status === 'loaded') {
      ctrl.play(current);
    }
  }, [ctrl, current, currentTask?.status]);

  const onContinue = () => goNext();

  return (
    <BlockContainer className='flex flex-col px-4'>
      {block.fields.showScore && (
        <PointsBadge
          currPoints={state.correct.results.length}
          totalPoints={
            state.correct.results.length + state.incorrect.results.length
          }
          className='mt-7.5'
        />
      )}
      <div className='w-full flex-1 overflow-auto mt-7.5'>
        <ScrollableContent ref={containerRef}>
          <div className='w-full flex gap-5 flex-col lg:flex-row items-center justify-start lg:items-start lg:justify-center'>
            {incorrect.presented && incorrect.results.length > 0 && (
              <EnterExitTailwindTransition
                initialClasses={
                  correct.results.length > 0
                    ? '-translate-y-full translate-x-0 lg:translate-y-0 lg:-translate-x-full opacity-0'
                    : 'opacity-0'
                }
                enteredClasses={
                  correct.results.length > 0
                    ? `translate-y-0 lg:translate-x-0 opacity-100`
                    : 'opacity-100'
                }
              >
                {(ref, initial) => (
                  <SummaryGroup
                    label='Your Areas for Improvement'
                    task={incorrect}
                    color='#FF0935'
                    openByDefault={openByDefault}
                    ref={ref}
                    className={initial}
                  />
                )}
              </EnterExitTailwindTransition>
            )}
            {correct.presented && correct.results.length > 0 && (
              <EnterExitTailwindTransition
                initialClasses={'opacity-0'}
                enteredClasses={`opacity-100`}
              >
                {(ref, initial) => (
                  <SummaryGroup
                    label='Your Strong Areas'
                    task={correct}
                    color='#39D966'
                    openByDefault={openByDefault}
                    ref={ref}
                    className={initial}
                  />
                )}
              </EnterExitTailwindTransition>
            )}
          </div>
        </ScrollableContent>
      </div>
      <footer
        className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
      >
        <CommonButton
          variant='gray'
          onClick={() => {
            props.ctrl.replay();
          }}
          disabled={!current || state[current].status !== 'complete'}
          styles={{ size: 'h-full' }}
          style={{
            aspectRatio: '1/1',
          }}
        >
          <RefreshIcon className='w-4 h-4 fill-current' />
        </CommonButton>
        <CommonButton
          variant='correct'
          className='flex-none'
          onClick={onContinue}
          disabled={!current || state[current].status !== 'complete'}
        >
          Continue
        </CommonButton>
      </footer>
    </BlockContainer>
  );
}
