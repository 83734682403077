import { useEffect, useRef } from 'react';

import { type SlideBlock } from '@lp-lib/game/src/block';

import { type SlideBlockControlAPI } from '../SlideBlock';

export function HTMLPlayer(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
}) {
  const { block, ctrl } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframeRef.current) return;

    const lvoCtrl = ctrl.getLVOCtrl();
    if (!lvoCtrl) return;

    const off = lvoCtrl.vm.on('marker-reached', (markerId) => {
      console.log('marker-reached', markerId);
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'trigger',
          name: markerId,
        },
        '*'
      );
    });

    return () => {
      off();
    };
  }, [ctrl]);

  if (!block.fields.html) return null;
  return (
    <iframe
      ref={iframeRef}
      srcDoc={block.fields.html}
      className='w-full h-full'
      title='HTML Player'
      sandbox='allow-scripts'
    />
  );
}
