import { type CSSProperties } from 'react';
import ContentEditable from 'react-contenteditable';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getAgentInfo } from '../../../utils/user-agent';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function EditableText(props: {
  value: string;
  onBlur: (value: string) => void;
  className?: string;
  placeholder?: string;
  style?: object | CSSProperties;
}) {
  const { value, onBlur } = props;

  const handleBlur = useLiveCallback((e: React.FocusEvent<HTMLDivElement>) => {
    const text = e.currentTarget.innerText.trim();
    // we possibly trimmed the text, ensure it's synced
    e.currentTarget.innerHTML = text;
    onBlur(text);
  });

  return (
    <ContentEditable
      tagName='p'
      // It's important that this component remain uncontrolled, i.e. does not
      // maintain `html` as managed state. The props.value could change due to a
      // store change/reload, and we want the input to reflect this.
      html={value}
      onChange={noop}
      onBlur={handleBlur}
      className={
        props.className ??
        'w-full outline-none cursor-text whitespace-pre-wrap contenteditable-placeholder'
      }
      data-placeholder={props.placeholder}
      style={props.style}
    />
  );
}

function FirefoxFriendlyEditableText(props: {
  value: string;
  onBlur: (value: string) => void;
  className?: string;
  placeholder?: string;
  style?: object | CSSProperties;
}) {
  const { value, onBlur } = props;

  const handleBlur = useLiveCallback((e: React.FocusEvent<HTMLDivElement>) => {
    const text = e.currentTarget.innerText.trim();
    // we possibly trimmed the text, ensure it's synced
    e.currentTarget.innerHTML = text;
    onBlur(text);
  });

  return (
    <ContentEditable
      tagName='p'
      // It's important that this component remain uncontrolled, i.e. does not
      // maintain `html` as managed state. The props.value could change due to a
      // store change/reload, and we want the input to reflect this.
      html={value}
      onChange={noop}
      onBlur={handleBlur}
      className={
        props.className ?? 'w-full outline-none cursor-text whitespace-pre-wrap'
      }
      onFocus={(e) => {
        if (e.currentTarget.innerText === '') {
          e.currentTarget.innerHTML = ' ';
        }
      }}
      data-placeholder={props.placeholder}
      style={props.style}
    />
  );
}

/**
 * If your field component uses text-center or placeholder, you may want to use
 * FFriendlyEditableText instead. Firefox has a few issues with ContentEditable,
 * see the demo for more info.
 * https://codesandbox.io/p/sandbox/content-editable-ff-w8g7v7
 */
export function FFriendlyEditableText(props: {
  value: string;
  onBlur: (value: string) => void;
  className?: string;
  placeholder?: string;
  style?: object | CSSProperties;
}) {
  const agentInfo = useInstance(() => getAgentInfo());

  if (!agentInfo.browser.isFirefox) return <EditableText {...props} />;
  return <FirefoxFriendlyEditableText {...props} />;
}
