import { useState } from 'react';
import { match } from 'ts-pattern';

import {
  type ModelsScenarioRubric,
  type ModelsScenarioRubricItem,
} from '@lp-lib/api-service-client/public';
import { type ScenarioBlock } from '@lp-lib/game';

import { ArrowDownIcon, ArrowUpIcon } from '../../../icons/Arrows';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { CloseIcon } from '../../../icons/CloseIcon';
import { Loading } from '../../../Loading';
import { ScoreRing } from '../../design/ScoreRing';
import {
  type GameResult,
  type ModelsEvaluatedRubricItem,
} from './ScenarioBlockPlayground';
import { ScenarioBlockUtils } from './utils';

function RubricItem(
  props:
    | {
        evaluated: false;
        item: ModelsScenarioRubricItem;
      }
    | {
        evaluated: true;
        item: ModelsEvaluatedRubricItem;
      }
) {
  const { item, evaluated } = props;

  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className='
        w-full bg-dark-gray rounded border border-secondary
        p-2 flex flex-col gap-2
      '
    >
      <button
        type='button'
        className='flex items-center justify-between gap-2'
        onClick={() => setExpanded(!expanded)}
      >
        <div className='flex items-center gap-2'>
          {evaluated ? (
            match(item.result)
              .with('correct', () => (
                <FilledCheckIcon className='w-3.5 h-3.5 fill-current text-green-001' />
              ))
              .with('incorrect', () => (
                <CloseIcon className='w-3 h-3 fill-current text-red-001' />
              ))
              .exhaustive()
          ) : (
            <Loading imgClassName='w-3.5 h-3.5' text='' />
          )}

          <p className='text-2xs md:text-sm'>{item.title}</p>
        </div>
        <div className='w-3.5 h-3.5 md:w-4 md:h-4 text-icon-gray'>
          {expanded ? (
            <ArrowUpIcon className='w-full h-full fill-current' />
          ) : (
            <ArrowDownIcon className='w-full h-full fill-current' />
          )}
        </div>
      </button>
      {expanded && (
        <p
          className={`px-0.5 text-2xs md:text-sm ${
            evaluated
              ? item.result === 'correct'
                ? 'text-green-001'
                : 'text-red-006'
              : 'text-icon-gray'
          }`}
        >
          {evaluated ? item.assessment : item.description}
        </p>
      )}
    </div>
  );
}

function getPacingStatus(wpm: number | null) {
  if (wpm === null) {
    return null;
  }

  if (wpm < 120) {
    return {
      status: 'too_slow',
      text: 'Too Slow',
      color: 'text-red-006',
    };
  }
  if (wpm > 150) {
    return {
      status: 'too_fast',
      text: 'Too fast',
      color: 'text-red-006',
    };
  }
  return {
    status: 'good',
    text: 'Good',
    color: 'text-green-001',
  };
}

function getFillerWordsStatus(fwpm: number | null) {
  if (fwpm === null) {
    return null;
  }

  if (fwpm > 3) {
    return {
      status: 'failed',
      text: 'Too many',
      color: 'text-red-006',
    };
  }
  return {
    status: 'passed',
    text: 'Good',
    color: 'text-green-001',
  };
}

function DeliveryNotes(props: {
  result: Nullable<GameResult>;
  block: ScenarioBlock;
}) {
  const { result, block } = props;

  if (!block.fields.showWPM && !block.fields.showFillerWords) {
    return null;
  }

  const pacing = getPacingStatus(result?.wpm ?? null);
  const fillerWordsStatus = getFillerWordsStatus(result?.fwpm ?? null);
  const fillerWordsDisplay = ScenarioBlockUtils.FormatFillerWordsDisplay(
    result?.fillerWordCounts || {}
  );

  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='text-sms md:text-base font-bold'>Delivery Notes</div>
      {block.fields.showWPM && (
        <div className='w-full bg-dark-gray rounded border border-secondary p-2 flex flex-col gap-2'>
          <div className='flex items-start justify-between'>
            <div className='flex items-center gap-3'>
              {pacing ? (
                pacing.status === 'good' ? (
                  <FilledCheckIcon className='w-3.5 h-3.5 fill-current text-green-001' />
                ) : (
                  <CloseIcon className='w-3 h-3 fill-current text-red-001' />
                )
              ) : (
                <Loading imgClassName='w-3.5 h-3.5' text='' />
              )}
              <p className='text-2xs md:text-sm'>Pacing</p>
            </div>
            <div className='flex flex-col items-end gap-1'>
              <p className='text-2xs md:text-sm'>
                {result ? `${result.wpm} words/min` : '--'}
              </p>
              <p className={`text-2xs md:text-sm ${pacing?.color}`}>
                {pacing?.text}
              </p>
            </div>
          </div>
        </div>
      )}
      {block.fields.showFillerWords && (
        <div className='w-full bg-dark-gray rounded border border-secondary p-2'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-3'>
              {fillerWordsStatus ? (
                fillerWordsStatus.status === 'passed' ? (
                  <FilledCheckIcon className='w-3.5 h-3.5 fill-current text-green-001' />
                ) : (
                  <CloseIcon className='w-3 h-3 fill-current text-red-001' />
                )
              ) : (
                <Loading imgClassName='w-3.5 h-3.5' text='' />
              )}
              <p className='text-2xs md:text-sm'>Filler Words</p>
            </div>
            <p
              className={`text-2xs md:text-sm font-bold ${fillerWordsStatus?.color}`}
            >
              {result ? `${result.fillerWordCount}` : '--'}
            </p>
          </div>
          {result && fillerWordsDisplay && (
            <p className='text-2xs md:text-sm text-icon-gray mt-1 ml-6'>
              {fillerWordsDisplay}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

function StandardResponse(props: { goldenAnswer: string | null }) {
  const { goldenAnswer } = props;
  const [showResponse, setShowResponse] = useState(true);

  if (!goldenAnswer) {
    return null;
  }

  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <div className='text-sms md:text-base font-bold'>Standard Response</div>
        <button
          type='button'
          onClick={() => setShowResponse(!showResponse)}
          className='text-sms md:text-base text-primary hover:underline'
        >
          {showResponse ? 'Hide' : 'Reveal'}
        </button>
      </div>
      {showResponse && (
        <p className='text-sms md:text-sm text-white whitespace-pre-wrap'>
          {goldenAnswer}
        </p>
      )}
    </div>
  );
}

export function RubricResult(props: {
  block: ScenarioBlock;
  rubric: ModelsScenarioRubric;
  result: Nullable<GameResult>;
}) {
  const { block, result, rubric } = props;

  return (
    <div className='w-full max-w-3xl h-full m-auto p-5 flex flex-col items-center gap-4 text-white'>
      <div className='flex flex-col items-center gap-2'>
        <p className='text-xl md:text-2.5xl font-bold'>
          {result ? 'Evaluation' : 'Evaluating Your Response...'}
        </p>
        <p className='text-sms md:text-base text-icon-gray'>
          Conversation Quality Assessment
        </p>
      </div>

      {result ? (
        <ScoreRing score={result.earnedPoints} total={block.fields.points} />
      ) : (
        <Loading containerClassName='w-15 h-15 md:w-20 md:h-20' text='' />
      )}

      {rubric.thingsSaidCorrectly.length > 0 && (
        <div className='w-full flex flex-col gap-2'>
          <div className='text-sms md:text-base font-bold'>
            Things Said Correctly
          </div>
          {result
            ? result.thingsSaidCorrectly.map((item) => (
                <RubricItem key={item.id} item={item} evaluated={true} />
              ))
            : rubric.thingsSaidCorrectly.map((item) => (
                <RubricItem key={item.id} item={item} evaluated={false} />
              ))}
        </div>
      )}

      {rubric.thingsAvoidedSaying.length > 0 && (
        <div className='w-full flex flex-col gap-2'>
          <div className='text-sms md:text-base font-bold'>
            Things Avoided Saying
          </div>
          {result
            ? result.thingsAvoidedSaying.map((item) => (
                <RubricItem key={item.id} item={item} evaluated={true} />
              ))
            : rubric.thingsAvoidedSaying.map((item) => (
                <RubricItem key={item.id} item={item} evaluated={false} />
              ))}
        </div>
      )}

      <DeliveryNotes result={result} block={block} />

      <StandardResponse goldenAnswer={block.fields.goldenAnswer} />
    </div>
  );
}
