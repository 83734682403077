import { useCallback, useEffect, useRef, useState } from 'react';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { XBoldIcon } from '../../../icons/XIcon';
import { type TutorControl } from './TutorControl';

// Define constants
const TUTOR_RATING_TIME_SECONDS = 7;
const ANIMATION_DURATION = 300;

interface TutorRatingProps {
  ctrl: TutorControl;
}

interface CountdownCircleProps {
  totalSeconds: number;
}

function CountdownCircle({ totalSeconds }: CountdownCircleProps) {
  // Calculate the circumference of the circle
  const radius = 18;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className='relative w-6 h-6 flex items-center justify-center scale-x-[-1]'>
      <svg className='w-full h-full' viewBox='0 0 40 40'>
        {/* Background circle */}
        <circle
          cx='20'
          cy='20'
          r='18'
          fill='transparent'
          stroke='#333'
          strokeWidth='2'
        />
        {/* Animated progress circle */}
        <circle
          cx='20'
          cy='20'
          r='18'
          fill='transparent'
          stroke='#4CE3B3'
          strokeWidth='3'
          strokeLinecap='round'
          strokeDasharray={`${circumference}`}
          strokeDashoffset='0'
          transform='rotate(-90 20 20)'
          className='animate-countdown-circle'
          style={
            {
              '--tutor-rating-countdown-duration': `${totalSeconds}s`,
              '--tutor-rating-circle-circumference': `${circumference}`,
            } as React.CSSProperties
          }
        />
      </svg>
    </div>
  );
}

function ThumbsUpIcon(props: { className?: string }) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-4.5 h-4.5 fill-current'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.69096 1.89291C6.90243 1.42888 7.36629 1.14286 7.86391 1.14286C9.12247 1.14286 10.1761 2.14281 10.1761 3.41701V5.40093H12.2108C13.8104 5.40093 15.0771 6.80044 14.825 8.39813L14.1533 12.6562C13.9513 13.9368 12.8294 14.8571 11.5391 14.8571H3.16449C2.0664 14.8571 1.14282 13.9841 1.14282 12.8664V8.6083C1.14282 7.4906 2.0664 6.61752 3.16449 6.61752H4.53779L6.69096 1.89291ZM5.8099 13.3088H11.5391C12.096 13.3088 12.545 12.9148 12.6239 12.4149L13.2956 8.15685C13.3923 7.54366 12.905 6.94931 12.2108 6.94931H10.0257C9.27213 6.94931 8.62781 6.34857 8.62781 5.56682V3.41701C8.62781 3.08179 8.37943 2.77685 8.02175 2.70646L5.90482 7.35153C5.87706 7.41244 5.84528 7.47054 5.8099 7.52562V13.3088ZM4.26157 8.1659V13.3088H3.16449C2.88462 13.3088 2.69116 13.0925 2.69116 12.8664V8.6083C2.69116 8.38219 2.88462 8.1659 3.16449 8.1659H4.26157Z'
      />
    </svg>
  );
}

export function TutorRating({ ctrl }: TutorRatingProps) {
  const [isClosing, setIsClosing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const animateContainer = useCallback(async (isOpening: boolean) => {
    if (!containerRef.current) return;

    const keyframes = isOpening
      ? [
          { opacity: 0, transform: 'translateY(-20px)' },
          { opacity: 1, transform: 'translateY(0)' },
        ]
      : [
          { opacity: 1, transform: 'translateY(0)' },
          { opacity: 0, transform: 'translateY(-20px)' },
        ];

    const options = {
      duration: ANIMATION_DURATION,
      easing: isOpening ? 'ease-out' : 'ease-in',
      fill: 'forwards' as FillMode,
    };

    const animation = containerRef.current.animate(keyframes, options);
    return animation.finished;
  }, []);

  const handleDismissRating = useLiveCallback(async () => {
    if (isClosing) return;
    setIsClosing(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    await animateContainer(false);
    ctrl.closeRating();
  });

  useEffect(() => {
    animateContainer(true);

    // Set a timeout to dismiss after total seconds
    timerRef.current = setTimeout(() => {
      handleDismissRating();
    }, TUTOR_RATING_TIME_SECONDS * 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [animateContainer, handleDismissRating]);

  const handleSubmitRating = useLiveCallback(
    async (rating: 'thumbsUp' | 'thumbsDown') => {
      if (isClosing) return;
      setIsClosing(true);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      await ctrl.submitRating(rating);
      await animateContainer(false);
      ctrl.closeRating();
    }
  );

  return (
    <div ref={containerRef} className='absolute top-0 left-0 right-0 z-50'>
      <div className='flex-none p-3 pb-0 max-w-240 mx-auto'>
        <div className='w-full h-[62px] p-px rounded-full bg-gradient-to-b from-[#4CE3B3] to-black'>
          <div className='relative w-full h-full flex items-center justify-between px-4 py-3 bg-main-layer text-white rounded-full'>
            <CountdownCircle totalSeconds={TUTOR_RATING_TIME_SECONDS} />

            <div className='flex-1 flex items-center justify-between gap-3 pl-3'>
              <h2 className='text-xs xl:text-sm font-bold'>
                How was your tutor session?
              </h2>
              <div className='flex items-center gap-3.5 lg:gap-5 pr-2'>
                <button
                  type='button'
                  onClick={() => handleSubmitRating('thumbsUp')}
                  className='flex justify-center items-center hover:scale-110 transition-transform'
                  disabled={isClosing}
                >
                  <ThumbsUpIcon />
                </button>

                <button
                  type='button'
                  onClick={() => handleSubmitRating('thumbsDown')}
                  className='flex justify-center items-center hover:scale-110 transition-transform'
                  disabled={isClosing}
                >
                  <ThumbsUpIcon className='w-4.5 h-4.5 fill-current -rotate-180' />
                </button>

                <button
                  type='button'
                  onClick={handleDismissRating}
                  className='flex justify-center items-center hover:scale-110 transition-transform'
                  disabled={isClosing}
                  aria-label='Dismiss'
                >
                  <XBoldIcon className='w-3.5 h-3.5 fill-current' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
