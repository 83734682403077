import { useEffect, useRef } from 'react';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsFillInTheBlanksSegmentType,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  type FillInTheBlanksBlock,
  QuestionBlockAnswerGrade,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { apiService } from '../../../../services/api-service';
import { assertExhaustive } from '../../../../utils/common';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../../../utils/valtio';
import {
  createCommonPromptTemplateGrader,
  GraderPipeline,
  SimpleGrader,
} from '../../apis/graders/AIGrader';
import { type Grader } from '../../apis/graders/types';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { CommonInput, type CommonInputVariant } from '../../design/Input';
import { SparkBlockBackground } from '../../design/SparkBackground';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';

function createFIBGrader(context: string, answer: string): Grader {
  const graders: Grader[] = [];
  graders.push(new SimpleGrader([answer]));
  graders.push(
    createCommonPromptTemplateGrader({
      context,
      correctAnswers: [answer],
    })
  );
  return new GraderPipeline(graders);
}

type Blank = {
  index: number;
  segmentId: string;
  answer: string;
  submission: string;
  result: 'correct' | 'incorrect' | null;
};

type GameResult = 'correct' | 'incorrect' | 'partiallyCorrect';

type GameState = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  blanks: Blank[];
  blankIndex: number;

  showResult: boolean;
  questionVisible: boolean;
  result: GameResult | null;
  correctCount: number;
};

export class FIBTypingControlAPI implements IBlockCtrl {
  private _state: ValtioSnapshottable<GameState>;

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  // private schema: BlockOutputsDesc;

  constructor(
    private block: FillInTheBlanksBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('fib-typing');

    const blanks: Blank[] = block.fields.segments
      .filter(
        (s) =>
          s.type ===
          EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
      )
      .map((s, index) => ({
        index,
        segmentId: s.id,
        answer: s.text,
        submission: '',
        result: null,
      }));

    const blankIndex = 0;

    this._state = markSnapshottable(
      proxy<GameState>({
        status: 'init',
        blanks,
        blankIndex,
        questionVisible: false,
        result: null,
        showResult: false,
        correctCount: 0,
      })
    );

    // this.schema = getOutputSchema(block);
  }

  get state() {
    return this._state;
  }

  async preload() {
    return;
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private updateTutorForBlockStatus(status: GameState['status']) {
    switch (status) {
      case 'play':
      case 'complete':
        this.deps.tutorControl.setAvailabilityState('available');
        break;
      case 'inform':
        this.deps.tutorControl.setAvailabilityState('unavailable');
        break;
      default:
        this.deps.tutorControl.setAvailabilityState('unavailable');
    }
  }

  async present() {
    this._state.status = 'present';
    this.updateTutorForBlockStatus('present');
    this._state.questionVisible = true;

    this._state.status = 'play';
    this.updateTutorForBlockStatus('play');
  }

  updateSubmission(blankIndex: number, value: string) {
    this.state.blanks[blankIndex].submission = value;
  }

  nextBlank() {
    this.state.blankIndex =
      (this.state.blankIndex + 1) % this.state.blanks.length;
    console.log('nextBlank', this.state.blankIndex);
  }

  setBlankIndex(index: number) {
    this.state.blankIndex = index;
  }

  async submit() {
    this.state.status = 'grade';
    this.updateTutorForBlockStatus('grade');

    const question = this.block.fields.segments
      .map((s) =>
        s.type ===
        EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
          ? '___'
          : s.text
      )
      .join(' ');

    await Promise.all(
      this.state.blanks.map(async (blank) => {
        const grader = createFIBGrader(question, blank.answer);
        const result = await grader.grade(blank.submission);
        blank.result =
          result.grade === QuestionBlockAnswerGrade.CORRECT
            ? 'correct'
            : 'incorrect';
      })
    );

    this.state.correctCount = this.state.blanks.filter(
      (b) => b.result === 'correct'
    ).length;
    this.state.result =
      this.state.blanks.length === this.state.correctCount
        ? 'correct'
        : this.state.blanks.some((b) => b.result === 'correct')
        ? 'partiallyCorrect'
        : 'incorrect';

    const ttsPromise = this.makeTTSRenderRequest(
      await this.getEvaluationScript(
        question,
        this.state.blanks,
        this.state.result
      ),
      true
    );

    this.state.status = 'inform';
    this.updateTutorForBlockStatus('inform');
    this.state.showResult = true;
    switch (this.state.result) {
      case 'correct':
        this.deps.sfxControl.play('rapidCorrect');
        this.deps.shakeControl.rootPop();
        break;
      case 'partiallyCorrect':
        this.deps.sfxControl.play('rapidDuplicate');
        break;
      case 'incorrect':
        this.deps.sfxControl.play('rapidWrong');
        this.deps.shakeControl.rootIncorrect();
        break;
    }

    const ttsReq = await ttsPromise;
    await this.playTTS(ttsReq);

    this._state.status = 'complete';
    this.updateTutorForBlockStatus('complete');
  }

  async end() {
    this._state.status = 'complete';
    this.updateTutorForBlockStatus('complete');
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    return;
  }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = this.deps.createLVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>,
    preferFlashRender = false
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      preferFlashRender,
    };
  }

  private async getEvaluationScript(
    question: string,
    blanks: Blank[],
    result: GameResult
  ): Promise<Nullable<string>> {
    if (!this.block.fields.personalityId) return null;
    try {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: 'fill-in-the-blanks/evaluation-script',
        variables: {
          question,
          blanks: blanks.map((b) => ({
            expected: b.answer,
            submitted: b.submission,
            result: b.result,
          })),
          result,
        },
      });
      return resp.data.content;
    } catch (e) {
      this.logger.error('failed to generate evaluation script', e);
      return null;
    }
  }
}

function BlankInput(props: {
  blank: Blank;
  active: boolean;
  submittable: boolean;
  disabled: boolean;
  showResult: boolean;
  isLastBlank: boolean;
  ctrl: FIBTypingControlAPI;
}) {
  const {
    blank,
    active,
    submittable,
    disabled,
    showResult,
    isLastBlank,
    ctrl,
  } = props;

  const ref = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    console.log('enter');

    if (isLastBlank && submittable) {
      ctrl.submit();
      return;
    }

    ctrl.nextBlank();
  };

  let variant: CommonInputVariant = 'brand';
  if (showResult) {
    variant = blank.result === 'correct' ? 'correct' : 'incorrect';
  }

  useEffect(() => {
    if (active) {
      console.log('focus', !!ref.current);
      ref.current?.focus();
    }
  }, [active]);

  return (
    <CommonInput
      ref={ref}
      type='text'
      placeholder=''
      value={blank.submission}
      onChange={(e) => ctrl.updateSubmission(blank.index, e.target.value)}
      disabled={disabled}
      onKeyDown={(e) => handleKeyDown(e)}
      onFocus={() => ctrl.setBlankIndex(blank.index)}
      variant={variant}
      styles={{
        spacing: 'px-0 py-0',
      }}
      className={`${
        showResult
          ? match(blank.result)
              .with('correct', () => 'text-green-001')
              .with('incorrect', () => 'text-red-001')
              .otherwise(() => '')
          : ''
      } font-normal`}
      style={{
        width: `${Math.max(8, blank.submission.length)}ch`,
      }}
    />
  );
}

export function FIBPlaygroundTyping(props: {
  block: FillInTheBlanksBlock;
  ctrl: FIBTypingControlAPI;
}) {
  const { ctrl } = props;

  const { status, questionVisible, blanks, blankIndex, result, showResult } =
    useSnapshot(props.ctrl.state);

  const submittable =
    status === 'play' && blanks.every((b) => b.submission.length > 0);

  useEffect(() => {
    if (status === 'init') {
      ctrl.present();
    }
  }, [status, ctrl]);

  return (
    <>
      <SparkBlockBackground block={props.block} />
      <BlockContainer className='flex flex-col'>
        <main className='w-full flex-1 px-10 py-5 flex flex-col gap-5 sm:gap-10'>
          <div className='text-center text-sms italic text-icon-gray'>
            Fill in the blanks
          </div>

          <div
            className={`
            text-white text-center break-words
            text-base sm:text-xl lg:text-2xl leading-relaxed
            transition-opacity duration-500 ${
              questionVisible ? 'opacity-100' : 'opacity-0'
            }
        `}
          >
            {props.block.fields.segments.map((segment) => {
              switch (segment.type) {
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText:
                  return <span key={segment.id}>{segment.text}</span>;
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank:
                  const blank = blanks.find(
                    (b) => b.segmentId === segment.id
                  ) as Blank;

                  return (
                    <BlankInput
                      key={blank.segmentId}
                      disabled={status !== 'play'}
                      active={status === 'play' && blank.index === blankIndex}
                      blank={blank}
                      submittable={submittable}
                      showResult={showResult}
                      isLastBlank={blank.index === blanks.length - 1}
                      ctrl={ctrl}
                    />
                  );
                default:
                  assertExhaustive(segment.type);
                  return null;
              }
            })}
          </div>

          <div
            className={`transition-opacity duration-500 ${
              showResult && result !== 'correct' ? 'opacity-100' : 'opacity-0'
            } flex flex-col gap-5 sm:gap-10`}
          >
            <div className='text-center text-icon-gray text-sms italic'>
              Correct answer
            </div>
            <div className='text-center text-white text-base sm:text-xl lg:text-2xl leading-relaxed'>
              {props.block.fields.segments.map((s) => {
                switch (s.type) {
                  case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText:
                    return <span key={s.id}>{s.text}</span>;
                  case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank:
                    const blank = blanks.find(
                      (b) => b.segmentId === s.id
                    ) as Blank;
                    return (
                      <span
                        key={s.id}
                        className={`${
                          blank.result === 'correct'
                            ? 'text-green-001'
                            : 'text-red-006'
                        }`}
                      >
                        {blank.answer}
                      </span>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        </main>
        <footer
          className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative`}
        >
          <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
            {match(result)
              .with('correct', () => (
                <span className='text-green-001'>
                  <strong>Correct!</strong> Nice job!
                </span>
              ))
              .with('incorrect', () => (
                <span className='text-red-006'>
                  <strong>Incorrect!</strong> See correct answer above.
                </span>
              ))
              .with('partiallyCorrect', () => (
                <span className='text-red-006'>
                  <strong>Partially Correct!</strong> See correct answer above.
                </span>
              ))
              .otherwise(() => null)}
          </div>
          {result ? (
            <CommonButton
              variant={
                result === 'correct'
                  ? 'correct'
                  : result === 'incorrect' || result === 'partiallyCorrect'
                  ? 'incorrect'
                  : 'brand'
              }
              onClick={() => ctrl.end()}
              disabled={status !== 'complete'}
            >
              Continue
            </CommonButton>
          ) : (
            <CommonButton
              variant='brand'
              onClick={() => ctrl.submit()}
              disabled={!submittable}
            >
              Submit
            </CommonButton>
          )}
          {result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </BlockContainer>
    </>
  );
}
