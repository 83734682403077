import { useLayoutEffect, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';

import {
  EnumsDialogueGenerationType,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsDialogue,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';
import { type Logger } from '@lp-lib/logger-base';
import { assertExhaustive } from '@lp-lib/media/src/asserts';

import { preloadMedia, useMedia } from '../../../hooks/useMedia';
import { fromMediaDTO } from '../../../utils/api-dto';
import { sleep, xDomainifyUrl } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { UnplayableAudioImpl } from '../../../utils/unplayable';
import { markSnapshottable } from '../../../utils/valtio';
import { type BlockDependencies } from '../../GameV2/types';
import { usePersonality } from '../usePersonalities';
import { type DialogueMarker } from './types';
import { DialogueUtils } from './utils';

function newAvatarVideoElement(entry: ResolvedDialogueEntry) {
  const avatar = entry.offlineRender?.avatar;
  const avatarVideoUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media));
  if (!avatarVideoUrl) return null;

  const avatarPosterUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media), {
    videoThumbnail: 'first',
  });

  const video = document.createElement('video');
  video.preload = 'auto';
  video.muted = true;
  video.playsInline = true;
  video.className = 'w-full h-full object-cover';
  video.src = avatarVideoUrl;
  if (avatarPosterUrl) {
    video.poster = avatarPosterUrl;
  }
  return video;
}

export type ResolvedDialogueEntry = ModelsDialogueEntry & {
  resolvedScript: string;
  resolvedMarkers: DialogueMarker[];
};

export type DialoguePlayerState = {
  index: number;
  entry: Nullable<ResolvedDialogueEntry>;
  mediaIds: string[];
  currentMediaId: string | null;
};

export class DialoguePlayerControl {
  private entries: ModelsDialogueEntry[];

  private resolvedEntries: Map<
    ModelsDialogueEntry['id'],
    ResolvedDialogueEntry
  >;
  private preloaded: Set<ModelsDialogueEntry['id']>;
  private _state: DialoguePlayerState = markSnapshottable(
    proxy<DialoguePlayerState>({
      index: -1,
      entry: null,
      mediaIds: [],
      currentMediaId: null,
    })
  );

  constructor(
    dialogue: Nullable<ModelsDialogue>,
    private deps: BlockDependencies,
    private videoCache: Map<string, HTMLVideoElement>,
    private logger: Logger,
    private preloadCount = 3
  ) {
    this.entries = dialogue?.entries ?? [];
    this.resolvedEntries = new Map();
    this.preloaded = new Set();
  }

  get state(): DialoguePlayerState {
    return this._state;
  }

  getVideo(id: ModelsDialogueEntry['id'] | null | undefined) {
    if (!id) return null;
    return this.videoCache.get(id);
  }

  numEntries() {
    return this.entries.length;
  }

  /**
   * Attempts to preload the first `preloadCount` entries. Note that some entries may not be preloadable in case they
   * depend on variables that are not yet resolvable. These entries are skipped, and at most`preloadCount` entries are
   * preloaded.
   */
  async preload(): Promise<void> {
    await this.resolve();
    if (this.resolvedEntries.size === 0) return;

    const entries = Array.from(
      this.getPreloadableEntries({ limit: this.preloadCount })
    );
    const preloads = entries.map(async (entry) => {
      await this.preloadResolvedDialogueEntry(entry);
      this.preloaded.add(entry.id);
    });
    await Promise.all(preloads);

    // if there are any preloadable entries, set the first one as the current entry.
    // by this way, the avatar will be shown before playing the first entry.
    if (entries.length > 0) {
      this.state.index = 0;
      this.state.entry = entries[0];
    }
    if (this.state.mediaIds.length > 0) {
      await preloadMediaMarker(this.state.mediaIds[0]);
    }
  }

  play(): { started: Promise<void>; ended: Promise<void> } {
    const started = Promise.withResolvers<void>();
    const ended = Promise.withResolvers<void>();
    const result = {
      started: started.promise,
      ended: ended.promise,
    };

    (async () => {
      const handledTutorQuestions = new Set<string>();

      if (this.entries.length === 0) {
        started.resolve();
        ended.resolve();
        return;
      }

      // we're going to play, so we must have a resolved script for every entry. keep in mind that not all entries are
      // guaranteed to have been preloaded at this point.
      await this.resolve(true);

      let hasResolvedStarted = false;
      for (let i = 0; i < this.entries.length; i++) {
        const entry = this.entries[i];
        const resolvedEntry = this.resolvedEntries.get(entry.id) ?? {
          ...entry,
          resolvedScript: entry.script.trim(),
          resolvedMarkers: DialogueUtils.ParseMarkers(entry.script.trim()),
        };

        // skip anything with no script.
        if (resolvedEntry.resolvedScript.length === 0) continue;

        // we're going to play this entry, let's preload the next one.
        this.preloadNextEntry(i);

        try {
          const player = this.deps.createLVOLocalPlayer({
            script: resolvedEntry.resolvedScript,
            personalityId: entry.personalityId,
            cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
            renderedMedia: fromMediaDTO(
              resolvedEntry.offlineRender?.voiceOver?.media
            ),
          });

          const unsub = player.onMarkerReached((name) => {
            const marker = resolvedEntry.resolvedMarkers.find(
              (m) => m.name === name
            );
            if (marker?.type === 'image') {
              this.state.currentMediaId = marker.name;
            } else if (marker?.type === 'tutor-question') {
              const lvoCtrl = this.deps.getLVOCtrl();
              if (
                !lvoCtrl ||
                !marker ||
                handledTutorQuestions.has(marker?.name)
              ) {
                return;
              }

              handledTutorQuestions.add(marker?.name);
              lvoCtrl.pause();
              this.deps.tutorControl.open({
                question: marker.question,
                finishCriteria: marker.finishCriteria,
              });
              this.deps.tutorControl.waitForTutorClosed().finally(() => {
                lvoCtrl.resume();
              });
            }
          });

          const info = await player.playFromPool();
          if (info) {
            await info.trackStarted;
          }

          const video = this.getVideo(entry.id);
          if (video) {
            video.currentTime = 0;
            video.autoplay = true;
            video.play();
          }

          this.state.index = i;
          this.state.entry = resolvedEntry;

          if (!hasResolvedStarted) {
            // it's possible that the first entry failed to play for some reason...
            started.resolve();
            hasResolvedStarted = true;
          }
          if (info) {
            await info.trackEnded;
          }
          unsub();
        } catch (e) {
          this.logger.error('failed to play dialogue entry', { e });
        }
        // little bit of jitter to make it feel more natural
        await sleep(Math.random() * (125 - 25) + 25);
      }
      if (!hasResolvedStarted) {
        // if we haven't resolved started, then we never started. mark it resolved so others continue.
        started.resolve();
      }
      ended.resolve();
    })();

    return result;
  }

  // resolve can be called multiple times. any unresolved entries will be resolved.
  private async resolve(must = false): Promise<void> {
    const resolutions = this.entries.map(async (entry) => {
      if (this.resolvedEntries.has(entry.id)) return;
      if (
        entry.generationType ===
        EnumsDialogueGenerationType.DialogueGenerationTypeClient
      ) {
        const result = await this.deps.commonVariableRegistry.render(
          entry.script
        );
        if (must || result.resolved) {
          this.resolvedEntries.set(entry.id, {
            ...entry,
            resolvedScript: result.script.trim(),
            resolvedMarkers: DialogueUtils.ParseMarkers(result.script.trim()),
          });
        }
      } else {
        this.resolvedEntries.set(entry.id, {
          ...entry,
          resolvedScript: entry.script.trim(),
          resolvedMarkers: DialogueUtils.ParseMarkers(entry.script.trim()),
        });
      }
    });
    await Promise.all(resolutions);
  }

  private async preloadResolvedDialogueEntry(entry: ResolvedDialogueEntry) {
    if (entry.resolvedScript.length === 0 || this.preloaded.has(entry.id))
      return;

    const mediaIds = entry.resolvedMarkers
      .filter((m) => m.type === 'image')
      .map((m) => m.name);
    for (const mediaId of mediaIds) {
      if (this.state.mediaIds.includes(mediaId)) continue;
      this.state.mediaIds.push(mediaId);
    }

    const tasks: Promise<void>[] = [];

    switch (entry.generationType) {
      case EnumsDialogueGenerationType.DialogueGenerationTypeClient:
        tasks.push(
          this.deps.lvoLocalCacheWarm({
            script: entry.resolvedScript,
            personalityId: entry.personalityId,
            cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
          })
        );
        break;
      case EnumsDialogueGenerationType.DialogueGenerationTypeOffline:
        const video = newAvatarVideoElement(entry);
        if (video) {
          this.videoCache.set(entry.id, video);
          tasks.push(
            new Promise<void>(async (resolve) => {
              try {
                await video.play();
                video.pause();
                video.currentTime = 0;
              } catch (e) {
                this.logger.error('Failed to preload avatar video', { e });
              } finally {
                resolve();
              }
            })
          );
        }

        const voiceOver = entry.offlineRender?.voiceOver;
        const voiceOverUrl = voiceOver?.media?.url;
        if (voiceOverUrl) {
          const audio = new UnplayableAudioImpl(xDomainifyUrl(voiceOverUrl));
          tasks.push(audio.intoPlayable().then(() => void 0));
        }
        break;
      default:
        assertExhaustive(entry.generationType);
        break;
    }

    await Promise.all(tasks);
  }

  private preloadNextEntry(after: number) {
    const nextEntryNeedingPreload = Array.from(
      this.getPreloadableEntries({ after, limit: 1 })
    ).at(0);
    if (nextEntryNeedingPreload) {
      this.preloadResolvedDialogueEntry(nextEntryNeedingPreload);
    }
  }

  private *getPreloadableEntries(opts: {
    after?: number;
    limit?: number;
  }): Generator<ResolvedDialogueEntry> {
    if (opts.limit && opts.limit < 1) return;

    let count = 0;
    const startIndex =
      opts.after !== undefined ? Math.max(opts.after, -1) + 1 : 0;

    for (let i = startIndex; i < this.entries.length; i++) {
      const entry = this.entries[i];
      if (this.preloaded.has(entry.id)) continue;

      const maybe = this.resolvedEntries.get(entry.id);
      if (maybe) {
        yield maybe;
        count++;
      }
      if (opts.limit && count >= opts.limit) break;
    }
  }

  /**
   * Cleans up resources used by the DialoguePlayerControl.
   * This method should be called when the component is no longer needed.
   */
  destroy() {
    // Clear the state
    this._state.index = -1;
    this._state.entry = null;
    this._state.currentMediaId = null;

    // Clear the preloaded set
    this.preloaded.clear();

    // Clear the resolved entries map
    this.resolvedEntries.clear();

    // Note: We don't clear the videoCache here as it's managed by the parent component
  }
}

function AvatarPersonality(props: {
  personalityId: string;
  showPlaceholder?: boolean;
}) {
  const personality = usePersonality(props.personalityId);
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );

  if (!mediaUrl) return null;
  return (
    <div className='relative w-full h-full'>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      {props.showPlaceholder && (
        <div className='absolute inset-0 rounded-full flex justify-center items-center'>
          <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
        </div>
      )}
    </div>
  );
}

function AvatarOffline(props: {
  playingEntry: ResolvedDialogueEntry;
  ctrl: DialoguePlayerControl;
}) {
  const video = props.ctrl.getVideo(props.playingEntry.id);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const current = ref.current;

    if (!video) return;

    ref.current?.appendChild(video);
    return () => {
      current.removeChild(video);
    };
  }, [video]);

  if (!video)
    return (
      <AvatarPersonality
        personalityId={props.playingEntry.personalityId}
        showPlaceholder={true}
      />
    );

  return <div className='w-full h-full' ref={ref}></div>;
}

type DialogueAvatarClientDisplay = 'personality' | 'script';

function AvatarClient(props: {
  playingEntry: ResolvedDialogueEntry;
  ctrl: DialoguePlayerControl;
  className?: string;
  display?: DialogueAvatarClientDisplay;
}) {
  const { display = 'personality' } = props;

  switch (display) {
    case 'personality':
      return (
        <AvatarPersonality personalityId={props.playingEntry.personalityId} />
      );
    case 'script':
      return (
        <div className='w-full h-full p-10 sm:p-13 lg:p-15 flex justify-center items-center'>
          <p className='text-base font-bold text-white text-center'>
            {props.playingEntry.resolvedScript}
          </p>
        </div>
      );
    default:
      assertExhaustive(display);
      return null;
  }
}

export function DialoguePlayer(props: {
  ctrl: DialoguePlayerControl;
  clientDisplay?: DialogueAvatarClientDisplay;
}) {
  const { entry } = useSnapshot(props.ctrl.state) as DialoguePlayerState;

  if (!entry) return null;
  switch (entry.generationType) {
    case EnumsDialogueGenerationType.DialogueGenerationTypeOffline:
      return <AvatarOffline playingEntry={entry} ctrl={props.ctrl} />;
    case EnumsDialogueGenerationType.DialogueGenerationTypeClient:
      return (
        <AvatarClient
          playingEntry={entry}
          ctrl={props.ctrl}
          display={props.clientDisplay}
        />
      );
    default:
      assertExhaustive(entry.generationType);
      return null;
  }
}

async function preloadMediaMarker(mediaId: string) {
  const media = await preloadMedia(mediaId);
  const mediaUrl = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
    priority: ImagePickPriorityHighToLow,
  });
  if (!mediaUrl) return;

  await new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = mediaUrl;
  });
}

function MediaSlide(props: { mediaId: string; visible: boolean }) {
  const { media } = useMedia(props.mediaId);
  const mediaUrl = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
    priority: ImagePickPriorityHighToLow,
  });
  if (!mediaUrl) return null;

  return (
    <div
      className={`absolute inset-0 w-full h-full ${
        props.visible ? 'opacity-100' : 'opacity-0'
      } transition-opacity duration-500`}
    >
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
    </div>
  );
}

type DialogueSlideShowProps = {
  ctrl: DialoguePlayerControl;
};

export function DialogueSlideShow(props: DialogueSlideShowProps) {
  const { ctrl } = props;
  const { mediaIds, currentMediaId } = useSnapshot(
    ctrl.state
  ) as DialoguePlayerState;

  return (
    <div className={`relative w-full h-full`}>
      {mediaIds.map((mediaId) => (
        <MediaSlide
          key={mediaId}
          mediaId={mediaId}
          visible={mediaId === currentMediaId}
        />
      ))}
    </div>
  );
}
