import { preload } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../services/api-service';

export function preloadMedia(mediaId: string) {
  return preload([mediaId, '/api/media'], async ([id]) => {
    const response = await apiService.media.getMedia(id);
    return response.data.media;
  });
}

export function useMedia(mediaId?: string | null) {
  const { data, error, isLoading } = useSWRImmutable(
    mediaId ? [mediaId, '/api/media'] : null,
    async ([id]) => {
      const response = await apiService.media.getMedia(id);
      return response.data.media;
    }
  );

  return {
    media: data,
    error,
    isLoading,
  };
}
