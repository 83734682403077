import {
  type CSSProperties,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

export const ScrollableContent = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, forwardedRef) => {
  const [hasScroll, setHasScroll] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const setRefs = (element: HTMLIFrameElement | null) => {
    containerRef.current = element;
    if (typeof forwardedRef === 'function') {
      forwardedRef(element);
    } else if (forwardedRef) {
      forwardedRef.current = element;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const checkScroll = () => {
      const { scrollHeight, clientHeight, scrollTop } = container;
      const hasScrollContent = scrollHeight > clientHeight;
      const isScrolledToBottom = scrollHeight <= clientHeight + scrollTop + 1;
      setHasScroll(hasScrollContent && !isScrolledToBottom);
    };

    // check initially
    checkScroll();

    const resizeObserver = new ResizeObserver(checkScroll);
    resizeObserver.observe(container);
    container.addEventListener('scroll', checkScroll);
    return () => {
      resizeObserver.disconnect();
      container.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <div className='relative w-full h-full flex flex-col'>
      <div
        ref={setRefs}
        className='w-full min-h-0 flex-1 overflow-y-auto scrollbar'
      >
        {props.children}
      </div>
      {hasScroll && (
        <div className='absolute bottom-0 left-0 right-0 flex justify-center'>
          <div
            className='px-2.5 py-1.5 bg-tertiary rounded-full text-black text-xs font-medium shadow-lg animate-fade-in-v2'
            style={
              {
                '--tw-fade-in-v2-duration': '400ms',
              } as CSSProperties
            }
          >
            Scroll for more content
          </div>
        </div>
      )}
    </div>
  );
});
