import { colorForScore } from './utils';

const PointsRing = ({
  className,
  score,
  textClassName,
}: {
  className: string;
  score: number;
  textClassName?: string;
}): JSX.Element => {
  const radius = 30;
  const stroke = 6;

  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = -(circumference - score * circumference);
  const strokeColor = colorForScore(score);

  return (
    <div className={className}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className='spinner-circle'
          stroke={'#303436'}
          fill='black'
          strokeDasharray={circumference + ' ' + circumference}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className='spinner-circle'
          stroke={strokeColor}
          fill='black'
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <text
          className={`text-white text-xl font-cairo font-extrabold ${textClassName}`}
          fill='#ffffff'
          fontSize='20px'
          dominantBaseline='central'
          textAnchor='middle'
          x='50%'
          y='50%'
        >
          {Math.round(score * 100)}
        </text>
      </svg>
    </div>
  );
};

export function PointsBadge(props: {
  currPoints: number;
  totalPoints: number;
  className?: string;
  showPoints?: boolean;
}) {
  const { currPoints, totalPoints } = props;
  const score = totalPoints !== 0 ? currPoints / totalPoints : 0;
  return (
    <div
      className={`flex items-center justify-center gap-4 ${
        props.className ?? ''
      }`}
    >
      <PointsRing className='relative' score={score} />
      <div>
        <div className='text-xl font-bold text-white'>
          Your Score: {Math.round(score * 100)}%
        </div>
        {props.showPoints && (
          <div className='text-sm text-icon-gray'>
            {currPoints}/{totalPoints} points earned
          </div>
        )}
      </div>
    </div>
  );
}
