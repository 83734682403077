import { useSnapshot } from 'valtio';

import { getStaticAssetPath } from '../../../../utils/assets';
import { type TutorControl } from './TutorControl';

interface TutorButtonProps {
  tutorControl: TutorControl;
}

const raisedHandSrc = getStaticAssetPath('images/raise-hand-icon-v2.png');

export function TutorButton({ tutorControl }: TutorButtonProps) {
  const state = useSnapshot(tutorControl.state);

  // Don't render if the tutor is not enabled
  if (!tutorControl.isEnabled) {
    return null;
  }

  const isRaised = state.handState === 'raised';

  return (
    <div className='flex flex-col items-center flex-none'>
      <button
        type='button'
        onClick={() => tutorControl.handleButtonClick()}
        aria-label={isRaised ? 'Hand raised' : 'Raise hand for AI Tutor'}
        className='group relative'
      >
        <div
          className={`w-10 h-10 rounded-full flex items-center justify-center backdrop-blur-sm transition-colors duration-500 ${
            isRaised ? 'bg-emerald-500/30' : 'bg-white/10'
          }`}
        >
          <span
            className={`transition-all duration-500 ease-in-out ${
              isRaised
                ? 'transform -translate-y-4 scale-125 rotate-[-8deg]'
                : 'transform translate-y-0 scale-100 hover:scale-105 hover:-translate-y-1'
            }`}
          >
            <img src={raisedHandSrc} alt='Raised hand' className='w-6 h-6' />
          </span>
        </div>
      </button>
    </div>
  );
}
