import { decoder as downloader } from '../../../services/audio/downloader';
import { playLocalSoundEffect } from '../../../services/audio/play-sound-effect';
import {
  getSoundEffectMap,
  type SoundEffectKeys,
  type SoundEffectMap,
} from '../../SFX/consts';

export class SFXControl {
  private readonly soundEffects: SoundEffectMap;
  private buffers: Map<SoundEffectKeys, AudioBuffer>;

  constructor(soundEffects: SoundEffectMap = getSoundEffectMap()) {
    this.soundEffects = soundEffects;
    this.buffers = new Map();
  }

  destroy(): void {
    this.buffers.clear();
  }

  async preload(key: SoundEffectKeys) {
    let buffer = this.buffers.get(key);
    if (buffer) return buffer;
    buffer = await downloader.get(this.soundEffects[key].url);
    if (!buffer) this.buffers.set(key, buffer);
    return buffer;
  }

  play(key: SoundEffectKeys): {
    started: Promise<void>;
    ended: Promise<void>;
  } {
    const sfx = this.soundEffects[key];
    const started = Promise.withResolvers<void>();
    const ended = Promise.withResolvers<void>();

    (async () => {
      let buffer;
      try {
        buffer = await this.preload(key);
      } catch (e) {
        started.reject(e);
        ended.reject();
        return;
      }

      started.resolve();
      try {
        playLocalSoundEffect(buffer, sfx.volume, sfx.delayMs, sfx.loop, () =>
          ended.resolve()
        );
      } catch (e) {
        ended.reject(e);
      }
    })();

    return {
      started: started.promise,
      ended: ended.promise,
    };
  }
}
