import { useRef } from 'react';

export function useShakeElements() {
  const rootEl = useRef<HTMLDivElement | null>(null);
  return {
    rootEl,
  };
}

export class ShakeControl {
  constructor(private els: ReturnType<typeof useShakeElements>) {}

  async rootIncorrect() {
    return this.incorrect(this.els.rootEl.current);
  }

  async rootPop() {
    return this.pop(this.els.rootEl.current);
  }

  async pop(el: HTMLDivElement | null) {
    if (!el || !el.parentElement) return;
    const anim = el.animate(
      [
        { transform: 'scale(1)' },
        { transform: 'scale(1.0125)', offset: 0.1 },
        { transform: 'scale(1)' },
      ],
      { duration: 300, easing: 'ease-in-out' }
    );
    anim.play();
    return anim.finished;
  }

  async incorrect(el: HTMLDivElement | null) {
    if (!el || !el.parentElement) return;
    const anim = el.animate(
      [
        { transform: 'translate(0, 0)' },
        { transform: 'translate(-2px, 0px)' },
        { transform: 'translate(4px, 0px)' },
        { transform: 'translate(-2px, 0px)' },
        { transform: 'translate(0, 0)' },
      ],
      { easing: 'steps(5)', duration: 300 }
    );
    anim.play();
    return anim.finished;
  }
}
