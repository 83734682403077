import {
  audioSessionGetMic,
  audioSessionResetQuality,
} from '../../../services/audio/audio-session';
import { sleep } from '../../../utils/common';
import { type SimplifedAgentInfo } from '../../../utils/user-agent';

export class MicUsageControl {
  private micStream: MediaStream | null = null;
  private releasing: Promise<void> | null = null;

  constructor(private agentInfo: SimplifedAgentInfo) {}

  private async internalRelease(wait = true) {
    if (!this.micStream) {
      return;
    }

    this.micStream.getTracks().forEach((track) => track.stop());
    this.micStream = null;
    audioSessionResetQuality();

    if (wait) {
      // Firefox seems to keep the bluetooth device in HDP/HSP (bidirectional
      // profile) for at least 10 seconds once all streams are closed. After the
      // 10s have passed, it then takes an additional 1.5s on Mac to crossfade
      // back to R2DP profile (high quality).
      const duration = this.agentInfo.browser.isFirefox ? 12000 : 3500;
      await sleep(duration);
    }
  }

  /**
   * Generally, this should only be called by the Playground. However, an
   * allowed exception is if a block _knows_ that it wants to close the mic and
   * has a lengthy portion following where it wants to use high-quality audio.
   * An example could be the roleplay evaluation step, where it's nearly
   * guaranteed that the user will sit on the screen for a while, or is about to
   * hear a lengthy VO.
   *
   * This method will only fully release the microphone if the block has already
   * released any mic streams it created!
   *
   * It is safe to call this more than once, as it internally dedupes a single
   * "release" promise that will resolve when the mic has finished its shutdown
   * attempt.
   */
  async releaseMic(wait = true) {
    if (this.releasing) {
      return this.releasing;
    }

    // Allow deduping of requests to release the mic.
    this.releasing = this.internalRelease(wait);
    await this.releasing;
    this.releasing = null;
  }

  /**
   * On macos/iOS, closing the microphone while using Bluetooth headphones
   * causes the audio profile to change back to high quality. This results in a
   * fade-out where the playing audio is squelched. One workaround is to wait
   * ~3.5s for the fade out to occur. But that causes the user to wait, so
   * instead we rely on the block to tell us that it is using the mic. In
   * reaction, we hold onto our own copy of the stream. If the next block is
   * going to use the mic, we keep it open, and we keep this stream alive. If
   * the next block will _not_ be using the mic, then we close the stream,
   * releasing the mic and restoring audio quality.
   *
   * Only safe to call once permission has been granted.
   *
   * NOTE: if you need a mic stream yourself, it's best to call
   * audioSessionGetMic directly for your own instance. This way, a block can
   * still manage its own streams in an obvious manner.
   *
   * @see {audioSessionGetMic}
   */
  async retainMic() {
    if (!this.micStream) {
      this.micStream = await audioSessionGetMic();
    }

    return this.micStream;
  }

  destroy() {
    this.releaseMic(false);
  }
}
