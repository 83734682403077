import { z } from 'zod';

export const trainingDialogueSchema = z
  .object({
    entries: z
      .array(
        z
          .object({
            script: z
              .string()
              .describe('The voice-over script of the dialogue entry'),
            speakerId: z
              .string()
              .describe('The speaker ID of the dialogue entry'),
          })
          .strict()
          .describe('TrainingDialogueEntry is a single entry in a dialogue')
      )
      .describe('The conversation entries of the dialogue'),
  })
  .strict()
  .describe(
    'TrainingDialogue is a dialogue that is used in training materials'
  );
