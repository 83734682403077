export function BlockContainer(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`relative w-full max-w-240 mx-auto h-full min-h-0 ${props.className}`}
    >
      {props.children}
    </div>
  );
}
