import { type ReactNode, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { audioSessionGetMicAccess } from '../../../../services/audio/audio-session';
import { CommonButton } from '../../design/Button';

export function MicRequired(props: { children: ReactNode }) {
  const [micReady, setMicReady] = useState<boolean | null>(null);

  useEffectOnce(() => {
    navigator.permissions
      .query({
        name: 'microphone' as PermissionName,
      })
      .then((permissionStatus) => {
        setMicReady(permissionStatus.state === 'granted');
      });
  });

  const {
    call: approve,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    await audioSessionGetMicAccess();
    setMicReady(true);
  });

  if (micReady === null) return null;
  if (!micReady) {
    return (
      <div className='h-full flex flex-col bg-black text-white items-center gap-4'>
        <div className='flex-1 flex flex-col justify-center items-center px-5'>
          <h1 className='text-xl lg:text-2xl font-bold mb-4 text-center'>
            This experience requires mic approval to proceed
          </h1>

          <img
            src={getStaticAssetPath('images/roleplay/mic-required.png')}
            className='w-75 h-75 lg:w-100 lg:h-100 object-contain'
            alt=''
          />
        </div>

        <div className='h-10 flex items-end text-red-001 text-center'>
          {error?.message}
        </div>

        <CommonButton
          type='button'
          variant='correct'
          onClick={() => approve()}
          disabled={isRunning}
          className='mb-4 w-11/12 lg:w-1/5'
        >
          {isRunning ? 'Approving...' : 'Approve'}
        </CommonButton>
      </div>
    );
  }

  return <>{props.children}</>;
}
