import { type BPUnitlessProperties } from '../../../breakpoints';
import { useUnitlessMatch } from '../../../hooks/useUnitlessMatch';

interface ScoreRingProps {
  score: number | null;
  total: number | null;
  size?: number | BPUnitlessProperties;
  strokeWidth?: number | BPUnitlessProperties;
  achievedColor?: string;
  unachievedColor?: string;
}

interface CircleProps {
  size: number;
  strokeWidth: number;
  normalizedRadius: number;
  circumference: number;
  strokeDashoffset: number;
  fontSize: number;
  achievedColor: string;
  unachievedColor: string;
}

function ScoreRingSVG({
  circleProps,
  text,
}: {
  circleProps: CircleProps;
  text: string;
}) {
  return (
    <svg height={circleProps.size} width={circleProps.size}>
      <circle
        stroke={circleProps.unachievedColor}
        fill='transparent'
        strokeWidth={circleProps.strokeWidth}
        r={circleProps.normalizedRadius}
        cx='50%'
        cy='50%'
      />
      <circle
        stroke={circleProps.achievedColor}
        fill='transparent'
        strokeWidth={circleProps.strokeWidth}
        strokeLinecap='round'
        strokeDasharray={`${circleProps.circumference} ${circleProps.circumference}`}
        style={{
          strokeDashoffset: circleProps.strokeDashoffset,
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
        r={circleProps.normalizedRadius}
        cx='50%'
        cy='50%'
      />
      <text
        className='font-bold'
        fill='white'
        fontSize={circleProps.fontSize}
        textAnchor='middle'
        dominantBaseline='central'
        x='50%'
        y='50%'
      >
        {text}
      </text>
    </svg>
  );
}

const DEFAULT_SIZES: BPUnitlessProperties = {
  '': 60,
  xl: 60,
  'lp-sm': 75,
  '2xl': 75,
  '3xl': 80,
  'lp-md': 85,
  'lp-lg': 90,
};

const DEFAULT_STROKE_WIDTHS: BPUnitlessProperties = {
  '': 6,
  xl: 6,
  'lp-sm': 7,
  '2xl': 7,
  '3xl': 8,
  'lp-md': 9,
  'lp-lg': 10,
};

export function ScoreRing(props: ScoreRingProps) {
  const {
    score,
    total,
    size = DEFAULT_SIZES,
    strokeWidth = DEFAULT_STROKE_WIDTHS,
    achievedColor = '#39D966',
    unachievedColor = '#303436',
  } = props;

  const currentSize = useUnitlessMatch(
    typeof size === 'number' ? { '': size } : size
  );
  const currentStrokeWidth = useUnitlessMatch(
    typeof strokeWidth === 'number' ? { '': strokeWidth } : strokeWidth
  );

  const progress = !!total && score !== null ? score / total : 0;
  const text = score?.toString() ?? '--';

  const getCircleProps = (
    size: number,
    strokeWidth: number,
    achievedColor: string,
    unachievedColor: string
  ): CircleProps => {
    const radius = size / 2;
    const normalizedRadius = radius - strokeWidth;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - progress * circumference;
    const fontSize = Math.round((size * 0.75) / Math.max(text.length, 2));

    return {
      size,
      strokeWidth,
      normalizedRadius,
      circumference,
      strokeDashoffset,
      fontSize,
      achievedColor,
      unachievedColor,
    };
  };

  const circleProps = getCircleProps(
    currentSize,
    currentStrokeWidth,
    achievedColor,
    unachievedColor
  );

  return <ScoreRingSVG text={text} circleProps={circleProps} />;
}
