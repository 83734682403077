import { useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { z } from 'zod';

import {
  type DtoTTSRenderRequest,
  EnumsMediaScene,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsScenarioRubric,
  type ModelsScenarioRubricItem,
} from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';
import { type ScenarioBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { apiService } from '../../../../services/api-service';
import { audioSessionExec } from '../../../../services/audio/audio-session';
import { assertExhaustive } from '../../../../utils/common';
import { markSnapshottable } from '../../../../utils/valtio';
import { WavRecorder } from '../../../../vendor/wavtools/lib/wav_recorder';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { Loading } from '../../../Loading';
import {
  DialoguePlayer,
  DialoguePlayerControl,
} from '../../../VoiceOver/Dialogue/DialoguePlayer';
import { getStingerPrompt } from '../../apis/StingerControl';
import { BlockContainer } from '../../design/BlockContainer';
import { CommonButton } from '../../design/Button';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { MicRequired } from '../Roleplay/MicRequired';
import { getOutputSchema, type ScenarioBlockOutputSchema } from './outputs';
import { RealtimeControl } from './RealtimeControl';
import { RubricResult } from './RubricResult';
import { ScenarioBlockUtils } from './utils';

const preferEvalInput = getFeatureQueryParamArray('scenario-prefer-eval-input');
const useRealtimeApi = getFeatureQueryParam('scenario-realtime-api');

const GameResultAIReturnSchema = z.object({
  transcript: z.string().default(''),
  fillerWords: z.array(z.string()).default([]),
  thingsSaidCorrectly: z
    .array(
      z.object({
        id: z.string(),
        result: z.enum(['correct', 'incorrect']),
        assessment: z.string().default(''),
      })
    )
    .default([]),
  thingsAvoidedSaying: z
    .array(
      z.object({
        id: z.string(),
        result: z.enum(['correct', 'incorrect']),
        assessment: z.string().default(''),
      })
    )
    .default([]),
  feedback: z.string().default(''),
});

export type ModelsEvaluatedRubricItem = ModelsScenarioRubricItem & {
  result: 'correct' | 'incorrect';
  assessment: string;
};

export type GameResult = {
  transcript: string;

  wpm: number;
  fwpm: number;
  fillerWordCounts: Record<string, number>;
  fillerWordCount: number;

  thingsSaidCorrectly: ModelsEvaluatedRubricItem[];
  thingsAvoidedSaying: ModelsEvaluatedRubricItem[];
  feedback: string;

  earnedPoints: number;
  correctCount: number;
  incorrectCount: number;
  finalResult: 'succeeded' | 'failed';
};

type Recording = {
  startedAt: Date;
  endedAt?: Date | null;
  durationSec?: number | null;
  base64Audio?: string | null;
  mediaId?: string | null;
};

type GameState = {
  status:
    | 'init'
    | 'prep'
    | 'question'
    | 'wait-respond'
    | 'respond'
    | 'respond-rubric-skipped'
    | 'grade'
    | 'grade-error'
    | 'inform'
    | 'complete';
  result: Nullable<GameResult>;
  recording: Nullable<Recording>;
  feedbackTTSStarted: boolean;
  error: Nullable<{
    message: string;
  }>;
};

function initGameState(): GameState {
  return {
    status: 'init',
    recording: null,
    error: null,
    result: null,
    feedbackTTSStarted: false,
  };
}

export class ScenarioBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(proxy<GameState>(initGameState()));
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    feedback: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    feedback: null,
  };
  private _dialoguePlayer: DialoguePlayerControl;
  public videoCache: Map<string, HTMLVideoElement>;
  private preloadCount = 3;
  private schema: ScenarioBlockOutputSchema;
  private wavRecorder: WavRecorder;
  private realtimeControl: RealtimeControl;

  constructor(private block: ScenarioBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('scenario-block');
    this.videoCache = new Map();
    this._dialoguePlayer = new DialoguePlayerControl(
      block.fields.question,
      deps,
      this.videoCache,
      this.logger,
      this.preloadCount
    );
    this.wavRecorder = new WavRecorder({
      // OpenAI requires 24000, but Firefox doesn't support resampling.
      // We use the system sample rate here instead, and let the wavRecorder
      // resample it to 24000.
      sampleRate: this.deps.agentInfo.browser.isFirefox ? undefined : 24000,
    });
    this.schema = getOutputSchema(block);
    this.realtimeControl = new RealtimeControl();
    this.realtimeControl.onToolCall('evaluate_response', async (args) => {
      await this.onResponseEvaluated(args);
      return { ok: true };
    });
  }

  get state() {
    return this._state;
  }

  get dialoguePlayer() {
    return this._dialoguePlayer;
  }

  get question() {
    return this.block.fields.question?.entries?.at(0)?.script || '';
  }

  get personalityId() {
    return this.block.fields.personalityId;
  }

  get normalizedRubric(): ModelsScenarioRubric {
    return ScenarioBlockUtils.NormalizeRubric(this.block.fields.rubric);
  }

  async willNeedMicAccess() {
    return true;
  }

  async preload() {
    this.resolvedTTS.stinger = await this.stingerTTS();

    const preloads: Promise<void>[] = [];
    preloads.push(this._dialoguePlayer.preload());
    await Promise.all(preloads);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    this.state.status = 'prep';
  }

  get avTooltipShown() {
    return Boolean(this.deps.roleplayAVTooltip.get('shown'));
  }

  markAVTooltipShown() {
    this.deps.roleplayAVTooltip.set('shown', true);
  }

  async playQuestion() {
    this.state.status = 'question';
    const info = this._dialoguePlayer.play();
    await info.started;
    await info.ended;
    this.state.status = 'wait-respond';
  }

  async record() {
    if (useRealtimeApi) {
      const resp = await apiService.block.getScenarioToken(this.block.id);
      await this.realtimeControl.connect(resp.data.token);
      this.realtimeControl.switchToPushToTalk();
      await this.deps.micUsageControl.retainMic();

      this.state.recording = {
        startedAt: new Date(),
      };
      this.state.status = 'respond';
      return;
    }

    await audioSessionExec(() => this.wavRecorder.begin());
    await this.deps.micUsageControl.retainMic();
    await this.wavRecorder.record();

    this.state.recording = {
      startedAt: new Date(),
    };
    this.state.status = 'respond';
  }

  private async uploadResponseAudio(blob: Blob) {
    if (!this.state.recording) {
      return;
    }

    try {
      const uploadResp = await apiService.media.upload(blob, {
        contentType: 'audio/wav',
        scene: EnumsMediaScene.MediaSceneScenarioResponse,
      });

      this.state.recording.mediaId = uploadResp.data.media.id;
    } catch (error) {
      this.logger.error('Failed to upload audio', error);
    }
  }

  async stopRecord() {
    if (!this.state.recording) {
      return;
    }
    this.state.recording.endedAt = new Date();

    if (useRealtimeApi) {
      this.state.recording.durationSec =
        (this.state.recording.endedAt.getTime() -
          this.state.recording.startedAt.getTime()) /
        1000;

      // we have feedback tts, releasing the mic as soon as possible to make the
      // audio profile return to high quality on mac/iOS without cutting off
      // content.
      if (!this.block.fields.skipRubric) {
        this.deps.micUsageControl.releaseMic();
      }

      this.realtimeControl.createResponse();

      if (this.block.fields.skipRubric) {
        this.state.status = 'respond-rubric-skipped';
      } else {
        this.state.status = 'grade';
      }
      return;
    }

    const result = await this.wavRecorder.end();

    const { promise, resolve, reject } = Promise.withResolvers();
    const reader = new FileReader();
    reader.readAsDataURL(result.blob);
    reader.onloadend = resolve;
    reader.onerror = reject;

    await promise;

    const base64data = reader.result as string;
    this.state.recording.base64Audio = base64data.split(',')[1];
    this.state.recording.durationSec = result.duration;

    this.uploadResponseAudio(result.blob);

    if (this.block.fields.skipRubric) {
      this.state.status = 'respond-rubric-skipped';
      await this.end();
      return;
    }

    await this.grade();
  }

  onResponseEvaluated(args: unknown) {
    this.logger.info('onResponseEvaluated', {
      args,
    });

    try {
      const aiReturn = GameResultAIReturnSchema.parse(args);
      this.state.result = this.processAIResponse(aiReturn);
    } catch (error) {
      this.logger.error('Failed to parse AI response', error, {
        args,
      });
      throw error;
    }

    this.realtimeControl.disconnect();

    if (this.block.fields.skipRubric) {
      this.commitOutput();
      this.end();
    } else {
      this.inform();
    }
  }

  private async callAudioPreview() {
    if (!this.state.recording) {
      throw new Error('No recording data available');
    }

    const resp = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'scenario/evaluate-response',
      variables: {},
      metadata: {
        blockId: this.block.id,
        packId: this.deps.getPack().id,
      },
      additionalRawMessages: [
        {
          content: [
            {
              type: 'text',
              text: `
<question>
${this.question}
</question>

<goldenAnswer>
${this.block.fields.goldenAnswer}
</goldenAnswer>

<rubric>
${JSON.stringify(this.normalizedRubric)}
</rubric>

The user's audio response is in the attachment.
`,
            },
            {
              type: 'input_audio',
              input_audio: {
                data: this.state.recording.base64Audio,
                format: 'wav',
              },
            },
          ],
        },
      ],
    });

    const args = resp.data.toolCalls.at(0)?.args;
    if (!args) {
      throw new Error('No tool calls returned');
    }

    const aiReturn = GameResultAIReturnSchema.parse(args);
    return aiReturn;
  }

  private processAIResponse(
    aiReturn: z.infer<typeof GameResultAIReturnSchema>
  ): GameResult {
    if (!this.state.recording) {
      throw new Error('No recording data available');
    }

    const thingsSaidCorrectly: ModelsEvaluatedRubricItem[] =
      this.normalizedRubric.thingsSaidCorrectly
        .map((item) => {
          const evaluated = aiReturn.thingsSaidCorrectly.find(
            (r) => r.id === item.id
          );
          if (!evaluated?.result) {
            console.error('No result for', item.id);
            return null;
          }
          return {
            ...item,
            result: evaluated.result,
            assessment: evaluated.assessment || '',
          } as ModelsEvaluatedRubricItem;
        })
        .filter((item): item is ModelsEvaluatedRubricItem => item !== null);

    const thingsAvoidedSaying: ModelsEvaluatedRubricItem[] =
      this.normalizedRubric.thingsAvoidedSaying
        .map((item) => {
          const evaluated = aiReturn.thingsAvoidedSaying.find(
            (r) => r.id === item.id
          );
          if (!evaluated?.result) {
            console.error('No result for', item.id);
            return null;
          }
          return {
            ...item,
            result: evaluated.result,
            assessment: evaluated.assessment || '',
          } as ModelsEvaluatedRubricItem;
        })
        .filter((item): item is ModelsEvaluatedRubricItem => item !== null);

    const correctCount =
      thingsSaidCorrectly.filter((item) => item.result === 'correct').length +
      thingsAvoidedSaying.filter((item) => item.result === 'correct').length;
    const totalCount = thingsSaidCorrectly.length + thingsAvoidedSaying.length;
    const incorrectCount = totalCount - correctCount;

    const earnedPoints =
      totalCount > 0
        ? Math.round((correctCount / totalCount) * this.block.fields.points)
        : 0;
    const finalResult = correctCount === totalCount ? 'succeeded' : 'failed';

    const durationMinutes = this.state.recording?.durationSec
      ? this.state.recording.durationSec / 60
      : 0;
    const transcript = aiReturn.transcript.toLowerCase();
    const words = transcript.trim().split(/\s+/);
    const wordCount = words.length;
    const wpm = wordCount > 0 ? Math.round(wordCount / durationMinutes) : 0;

    const fillerWordCounts = ScenarioBlockUtils.CountFillerWords(
      transcript,
      new Set(aiReturn.fillerWords)
    );
    const fillerWordCount = Object.values(fillerWordCounts).reduce(
      (acc, count) => acc + count,
      0
    );
    const fwpm = ScenarioBlockUtils.CalculateFillerWordsPerMinute(
      fillerWordCounts,
      durationMinutes
    );

    return {
      transcript,
      wpm,
      fwpm,
      fillerWordCounts,
      fillerWordCount,
      thingsSaidCorrectly,
      thingsAvoidedSaying,
      feedback: aiReturn.feedback,
      earnedPoints,
      correctCount,
      incorrectCount,
      finalResult,
    };
  }

  private async evaluate(): Promise<GameResult> {
    const evaluationMethods = [
      this.callAudioPreview,
      this.callWhisperAndEvaluate,
    ];
    if (preferEvalInput === 'transcript') {
      evaluationMethods.reverse();
    }

    for (const method of evaluationMethods) {
      try {
        const aiReturn = await method.call(this);
        return this.processAIResponse(aiReturn);
      } catch (error) {
        this.logger.warn('Evaluation method failed, trying next method', {
          error,
        });
      }
    }

    throw new Error('No evaluation methods succeeded');
  }

  private async callWhisperAndEvaluate() {
    if (!this.state.recording?.base64Audio) {
      throw new Error('No recording data available');
    }

    const formData = new FormData();
    const audioBlob = new Blob(
      [Buffer.from(this.state.recording.base64Audio, 'base64')],
      { type: 'audio/wav' }
    );
    formData.append('audio', audioBlob, 'audio.wav');

    const resp = await apiService.openai.transcribe(formData);
    const transcript = resp.data.text;
    console.log('transcript', transcript);

    const evalResp = await apiService.promptTemplate.runTemplate({
      promptTemplateMappingKey: 'scenario/evaluate-transcribed-response',
      variables: {},
      metadata: {
        blockId: this.block.id,
        packId: this.deps.getPack().id,
      },
      additionalRawMessages: [
        {
          content: [
            {
              type: 'text',
              text: `
<question>
${this.question}
</question>

<goldenAnswer>
${this.block.fields.goldenAnswer}
</goldenAnswer>

<rubric>
${JSON.stringify(this.normalizedRubric)}
</rubric>

<userResponse>
${transcript}
</userResponse>
`,
            },
          ],
        },
      ],
    });

    const args = evalResp.data.toolCalls.at(0)?.args;
    if (!args) {
      throw new Error('No tool calls returned');
    }

    const aiReturn = GameResultAIReturnSchema.parse(args);
    aiReturn.transcript = transcript;

    return aiReturn;
  }

  async grade() {
    this.state.status = 'grade';

    try {
      [this.state.result] = await Promise.all([
        this.evaluate(),
        // We will likely perform some VO, so manually shutdown the mic while
        // waiting for the evaluation since it takes a while. This should allow
        // the VO to be a consistent volume and quality.
        this.deps.micUsageControl.releaseMic(),
      ]);
    } catch (error) {
      this.state.status = 'grade-error';
      this.state.error = {
        message:
          'Something went wrong when evaluating your response.\nPlease try again.',
      };
      return;
    }

    await this.inform();
  }

  async retryGrade() {
    await this.grade();
  }

  async inform() {
    if (this.state.recording?.endedAt) {
      const now = new Date();
      const evaluationDuration =
        now.getTime() - this.state.recording.endedAt.getTime();
      console.log(
        `Evaluation ${this.state.recording.durationSec}s recording took ${evaluationDuration}ms`
      );
    }

    this.state.status = 'inform';

    this.commitOutput();

    this.resolvedTTS.feedback = await this.makeTTSRenderRequest(
      this.state.result?.feedback || ''
    );
    // make sure the mic is released before playing TTS
    await this.deps.micUsageControl.releaseMic();
    const info = await this.playTTS(this.resolvedTTS.feedback);
    await info?.trackStarted;
    this.state.feedbackTTSStarted = true;
    await info?.trackEnded;

    this.state.status = 'complete';
  }

  private commitOutput() {
    if (!this.state.result) return;
    const result = this.state.result;

    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      this.block.fields.points
    );
    this.delegate?.blockDidOutput(this.schema.points, result.earnedPoints);
    this.delegate?.blockDidOutput(this.schema.result, result.finalResult);
    this.delegate?.blockDidOutput(this.schema.question, this.question);
    this.delegate?.blockDidOutput(
      this.schema.correctCount,
      result.correctCount
    );
    this.delegate?.blockDidOutput(
      this.schema.incorrectCount,
      result.incorrectCount
    );
    this.delegate?.blockDidOutput(this.schema.transcript, result.transcript);
    this.delegate?.blockDidOutput(
      this.schema.thingsSaidCorrectly,
      this.state.result.thingsSaidCorrectly
        .map(
          (item) =>
            `${item.title}: ${item.result === 'correct' ? 'passed' : 'failed'}`
        )
        .join('\n')
    );
    this.delegate?.blockDidOutput(
      this.schema.thingsAvoidedSaying,
      this.state.result.thingsAvoidedSaying
        .map(
          (item) =>
            `${item.title}: ${item.result === 'correct' ? 'passed' : 'failed'}`
        )
        .join('\n')
    );
    if (this.state.recording?.mediaId) {
      this.delegate?.blockDidOutput(
        this.schema.responseMediaId,
        this.state.recording.mediaId
      );
    }
  }

  replay() {
    this.state.status = 'init';
    this.state.result = null;
    this.state.error = null;
    this.state.recording = null;
    this.state.feedbackTTSStarted = false;
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  async destroy() {
    if (useRealtimeApi) {
      await this.realtimeControl.disconnect();
      return;
    }

    if (this.wavRecorder.recording) {
      await this.wavRecorder.end();
    }
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(this.question, 'Scenario', 'A scenario simulation')
    );
  }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = this.deps.createLVOLocalPlayer(req);
      return await player.playFromPool();
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    if (!this.personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId: this.personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

function Playing(props: {
  block: ScenarioBlock;
  ctrl: ScenarioBlockControlAPI;
}) {
  const { status } = useSnapshot(props.ctrl.state);
  const {
    call: startRecording,
    state: {
      state: { isRunning: isStarting },
    },
  } = useLiveAsyncCall(async () => {
    await props.ctrl.record();
  });
  const {
    call: stopRecording,
    state: {
      state: { isRunning: isStopping },
    },
  } = useLiveAsyncCall(async () => {
    await props.ctrl.stopRecord();
  });

  return (
    <>
      <div className='absolute inset-0'>
        <DialoguePlayer
          ctrl={props.ctrl.dialoguePlayer}
          clientDisplay='script'
        />
      </div>

      <BlockContainer className='flex flex-col justify-end items-center'>
        {status === 'respond' && !isStopping && (
          <div className='relative w-full h-0'>
            <div className='absolute bottom-3 w-full px-5 flex justify-center'>
              <div className='flex text-xl font-bold text-white'>
                Listening...
              </div>
            </div>
          </div>
        )}
        <footer
          className={`w-full flex items-center justify-center gap-2 p-3 pb-5`}
        >
          {status === 'wait-respond' && (
            <CommonButton
              variant='correct'
              onClick={() => startRecording()}
              className={`flex-none`}
              disabled={isStarting}
            >
              {isStarting ? (
                <Loading imgClassName='w-5 h-5' text='Getting ready...' />
              ) : (
                'Respond'
              )}
            </CommonButton>
          )}
          {status === 'respond' && (
            <CommonButton
              variant='incorrect'
              onClick={() => stopRecording()}
              className={`flex-none`}
              disabled={isStopping}
            >
              {isStopping ? (
                <Loading imgClassName='w-5 h-5' text='Analyzing response...' />
              ) : (
                'Finish'
              )}
            </CommonButton>
          )}
          {status === 'respond-rubric-skipped' && (
            <CommonButton variant='incorrect' className='flex-none' disabled>
              <Loading
                imgClassName='w-4 h-4'
                text='Waiting for mic to shutdown...'
              />
            </CommonButton>
          )}
        </footer>
      </BlockContainer>
    </>
  );
}

function ErrorModal(props: { message: string; onRetry: () => void }) {
  return (
    <div className='fixed inset-0 flex items-center justify-center z-50'>
      <div className='absolute inset-0 bg-black opacity-50'></div>
      <div className='relative bg-black border border-secondary rounded-lg m-4 p-6 flex flex-col items-center gap-4'>
        <div className='text-xl font-bold text-white text-center whitespace-pre-wrap'>
          {props.message}
        </div>
        <CommonButton
          variant='correct'
          onClick={props.onRetry}
          className='flex-none'
        >
          Retry
        </CommonButton>
      </div>
    </div>
  );
}

function Result(props: {
  block: ScenarioBlock;
  ctrl: ScenarioBlockControlAPI;
}) {
  const { status, result, error, feedbackTTSStarted } = useSnapshot(
    props.ctrl.state
  ) as GameState;
  const [disabled, setDisabled] = useState(false);

  return (
    <BlockContainer className='flex flex-col items-center justify-center'>
      <div
        className='w-full flex-1 overflow-auto scrollbar flex flex-col items-center'
        style={{
          maskImage:
            'linear-gradient(180deg, #D9D9D9 95.71%, rgba(115, 115, 115, 0.00) 100%)',
        }}
      >
        <div className='w-full flex-1'>
          <RubricResult
            result={result}
            rubric={props.ctrl.normalizedRubric}
            block={props.block}
          />
        </div>
        <div className='h-5'></div>
      </div>
      {status === 'grade-error' && error && (
        <ErrorModal
          message={error.message}
          onRetry={() => props.ctrl.retryGrade()}
        />
      )}
      {result && (
        <footer className='w-full flex flex-col items-center'>
          {result.finalResult === 'failed' && (
            <div className='text-xs text-red-006'>
              Room for improvement! See above.
            </div>
          )}

          <div className='w-full flex justify-center items-center gap-2 p-3 pb-5'>
            <CommonButton
              variant='gray'
              onClick={() => {
                props.ctrl.replay();
              }}
              className='flex-none'
              disabled={status !== 'complete' || disabled}
              styles={{ size: 'h-full' }}
              style={{
                aspectRatio: '1/1',
              }}
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
            </CommonButton>
            <CommonButton
              variant={
                result?.finalResult === 'succeeded' ? 'correct' : 'incorrect'
              }
              onClick={() => {
                // Make sure the we only call `end()` once. Closing the mic can
                // take time between blocks.
                setDisabled(true);
                props.ctrl.end();
              }}
              disabled={status !== 'complete' || disabled}
              className='flex-none'
            >
              {!feedbackTTSStarted ? (
                <Loading
                  imgClassName='w-4 h-4'
                  text='Reviewing your results…'
                />
              ) : (
                'Got it!'
              )}
            </CommonButton>
          </div>
        </footer>
      )}
    </BlockContainer>
  );
}

function Prep(props: { block: ScenarioBlock; ctrl: ScenarioBlockControlAPI }) {
  const showTooltip = useInstance(() => !props.ctrl.avTooltipShown);

  useEffect(() => {
    if (showTooltip) return;

    props.ctrl.playQuestion();
  }, [showTooltip, props.ctrl]);

  if (!showTooltip) return null;
  return (
    <BlockContainer className='flex flex-col text-white'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10 flex flex-col justify-center items-center gap-4'>
        <div className='text-xl font-bold'>Ready to respond?</div>

        <img
          src={getStaticAssetPath('images/headphones-warning.png')}
          alt=''
          className='w-50 h-50'
        />

        <div className='text-center max-w-65'>
          <p className='text-base font-bold'>
            Use headphones and your mic to participate in this scenario.
          </p>
          <p className=' text-sms mt-2'>Make sure you're in a quiet space!</p>
        </div>
      </div>

      <footer
        className={`relative w-full flex items-center justify-center gap-2 p-3 pb-5`}
      >
        <CommonButton
          variant='correct'
          onClick={() => {
            props.ctrl.markAVTooltipShown();
            props.ctrl.playQuestion();
          }}
          disabled={false}
          className='flex-none'
        >
          Let's go!
        </CommonButton>
      </footer>
    </BlockContainer>
  );
}

function Internal(props: {
  block: ScenarioBlock;
  ctrl: ScenarioBlockControlAPI;
}) {
  const { status } = useSnapshot(props.ctrl.state);

  switch (status) {
    case 'init':
      return null;
    case 'prep':
      return <Prep block={props.block} ctrl={props.ctrl} />;
    case 'question':
    case 'wait-respond':
    case 'respond':
    case 'respond-rubric-skipped':
      return <Playing block={props.block} ctrl={props.ctrl} />;
    case 'grade':
    case 'grade-error':
    case 'inform':
    case 'complete':
      return <Result block={props.block} ctrl={props.ctrl} />;
    default:
      assertExhaustive(status);
      return null;
  }
}

export function ScenarioBlockPlayground(props: {
  block: ScenarioBlock;
  ctrl: ScenarioBlockControlAPI;
}) {
  const status = useSnapshot(props.ctrl.state).status;

  useEffect(() => {
    if (status !== 'init') return;
    props.ctrl.present();
  }, [status, props.ctrl]);

  if (status === 'init') return null;
  return (
    <MicRequired>
      <Internal block={props.block} ctrl={props.ctrl} />
    </MicRequired>
  );
}
