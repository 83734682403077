import './design/styles.css';

import { useNavigate } from '@remix-run/react';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy, ref } from 'valtio';

import {
  type DtoAssessmentResultRequest,
  type DtoBlock,
  type DtoBlockDestination,
  type DtoProgression,
  EnumsBlockGradeResult,
  type ModelsBlockOutput,
  type ModelsBlockOutputMap,
  type ModelsMinigameProgression,
} from '@lp-lib/api-service-client/public';
import {
  BlockTypeV2,
  blockTypeV2fromEnumsBlockType,
  type DrawToWinBlock,
  type FillInTheBlanksBlock,
  type HiddenPictureBlock,
  type JeopardyBlock,
  type MemoryMatchBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
  type ResultsBlock,
  type RoleplayBlock,
  type ScenarioBlock,
  type SlideBlock,
  type SparkifactBlock,
  type SwipeToWinBlock,
} from '@lp-lib/game';
import {
  getBlockOutputAsString,
  getBlockOutputKey,
  getBlockOutputsById,
} from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { type LearningAnalytics } from '../../analytics/learning';
import applaudImg from '../../assets/img/applaud.png';
import config from '../../config';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { findLanguageOptionOrDefault } from '../../i18n/language-options';
import { getLogger } from '../../logger/logger';
import { useLoggerConsoleCtrl } from '../../logger/logger-ctrl';
import { type User } from '../../types/user';
import {
  type AbortableRunner,
  YieldableAbortableRunner,
} from '../../utils/AbortSignalableRunner';
import { fromDTOBlock } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { assertExhaustive, sleep } from '../../utils/common';
import { StorageFactory } from '../../utils/storage';
import { getAgentInfo } from '../../utils/user-agent';
import { markSnapshottable, useSnapshot } from '../../utils/valtio';
import { DefaultClock } from '../Clock';
import { useConfettiAnimation } from '../ConfettiAnimation';
import { Loading } from '../Loading';
import { SubtitlesAreaV2 } from '../MediaControls/SubtitlesAreaV2';
import { ProvidersList } from '../ProvidersList';
import {
  useMyI18nSettings,
  useMyI18nSettingsGetter,
} from '../Settings/useMyI18nSettings';
import {
  // eslint-disable-next-line no-restricted-syntax
  lvoCacheWarmForLocales,
  // eslint-disable-next-line no-restricted-syntax
  LVOLocalCtrl,
  // eslint-disable-next-line no-restricted-syntax
  LVOLocalPlayer,
} from '../VoiceOver/LocalLocalizedVoiceOvers';
import {
  type ISubtitlesManager,
  LocalSubtitlesManager,
} from '../VoiceOver/LocalSubtitlesManager';
import { makeMediaElementTracker } from '../VoiceOver/makeLocalAudioOnlyVideoMixer';
import { VariableRegistry } from '../VoiceOver/VariableRegistry';
import {
  GlobalAudioDetectionProvider,
  useGlobalAudioDetectionAPI,
} from './apis/AudioDetection';
import { BlockLogicEvaluator } from './apis/BlockLogicEvaluator';
import { LMSNotifier } from './apis/LMSNotify';
import { MicUsageControl } from './apis/MicUsageControl';
import { MusicControl } from './apis/MusicControl';
import { PauseManagement } from './apis/PauseManagement';
import { newProgressionTracker } from './apis/progression';
import { SFXControl } from './apis/SFXControl';
import { ShakeControl, useShakeElements } from './apis/ShakeControl';
import {
  BlockStinger,
  MinigameStinger,
  StingerControl,
  useStingerElements,
} from './apis/StingerControl';
import { TutorButton } from './apis/tutor/TutorButton';
import { TutorControl } from './apis/tutor/TutorControl';
import { TutorOverlay } from './apis/tutor/TutorOverlay';
import {
  blockOutputsToGradeResult,
  blockTypePlayable,
} from './blocks/block-grade-result';
import {
  DrawToWinBlockControlAPI,
  DrawToWinBlockPlayground,
} from './blocks/DrawToWin';
import {
  FIBPlayground,
  newFIBControlAPI,
} from './blocks/FillInTheBlanks/FIBPlayground';
import {
  HiddenPictureBlockControlAPI,
  HiddenPictureBlockPlayground,
} from './blocks/HiddenPicture/HiddenPictureBlock';
import {
  JeopardyBlockControlAPI,
  JeopardyBlockPlayground,
} from './blocks/Jeopardy/JeopardyBlockPlayground';
import {
  MatchBlockControlAPI,
  MatchBlockPlayground,
} from './blocks/Match/MatchBlock';
import {
  MultipleChoiceBlockControlAPI,
  MultipleChoiceBlockPlayground,
} from './blocks/MultipleChoice/MultipleChoiceBlock';
import {
  QuestionBlockControlAPI,
  QuestionBlockPlayground,
} from './blocks/Question/QuestionBlock';
import {
  ResultsBlockControlAPI,
  ResultsBlockPlayground,
} from './blocks/Results/ResultsBlockPlayground';
import {
  RoleplayBlockControlAPI,
  RoleplayBlockPlayground,
} from './blocks/Roleplay/RoleplayBlock';
import {
  ScenarioBlockControlAPI,
  ScenarioBlockPlayground,
} from './blocks/Scenario/ScenarioBlockPlayground';
import {
  SlideBlockControlAPI,
  SlideBlockPlayground,
} from './blocks/SlideBlock';
import {
  SparkifactBlockControlAPI,
  SparkifactBlockPlayground,
} from './blocks/Sparkifact/SparkifactBlock';
import {
  SwipeToWinBlockControlAPI,
  SwipeToWinBlockPlayground,
} from './blocks/SwipeToWin/SwipeToWinBlock';
import { CommonButton } from './design/Button';
import { StatusBar } from './design/StatusBar';
import { type PlayCursor } from './PlayCursor';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from './types';

export type PlaygroundProps = {
  // the cursor that will be used to play the game.
  cursor: PlayCursor;
  // any block outputs that should be added to the initial variable registry.
  initialBlockOutputs?: Nullable<DtoProgression['blockOutputs']>;
  // called when the user clicks the X on the status bar.
  onClose: () => void;
  // called when the user clicks the continue button after finishing the game.
  onMinigameContinue: () => void;
  // when true, the playground will not track progression.
  preview?: boolean;
  // get the current authenticated user, if there is one.
  getUser: () => Nullable<User>;
  // function to get analytics instance for tracking
  getLearningAnalytics?: () => Nullable<LearningAnalytics>;
};

export function Playground(props: PlaygroundProps) {
  const stingerElements = useStingerElements();
  const shakeElements = useShakeElements();
  return (
    <ProviderInitialization>
      <AudioInitialization>
        <LoggerControl />
        <ControlInitialization
          {...props}
          stingerElements={stingerElements}
          shakeElements={shakeElements}
        />
      </AudioInitialization>
      <BlockStinger ref={stingerElements.blockStingerEl} />
      <MinigameStinger ref={stingerElements.minigameStingerEl} />
    </ProviderInitialization>
  );
}

const ROCKET_ANIMATION = getStaticAssetPath('videos/rocket-animation.mp4');
const FINISH_GAME_PACK_ANIMATION = getStaticAssetPath(
  'videos/training-finish-animation.mp4'
);

function ProviderInitialization(props: { children: ReactNode }) {
  //////////////
  // NOTE: please think twice about what you add here!! Try to keep this as
  // minimal as possible. Consider adding a block dependency instead.
  //////////////
  const providers = [];
  providers.push(<GlobalAudioDetectionProvider />);

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}

function LoggerControl() {
  useLoggerConsoleCtrl();
  return null;
}

function AudioInitialization(props: { children: ReactNode }) {
  const api = useGlobalAudioDetectionAPI();
  const audioReady = useSnapshot(api.state).audioReady;
  const [unlocking, setUnlocking] = useState(false);

  if (!audioReady) {
    return (
      <div className='h-full flex flex-col bg-black text-white items-center'>
        <div className='flex-1 flex flex-col justify-center items-center px-5'>
          <div className='flex flex-col items-center'>
            <h1 className='text-3xl lg:text-4xl font-bold mb-4'>
              Ready to go?
            </h1>

            <div className='w-80 h-80 mb-4 lg:w-120 lg:h-120'>
              <video
                src={ROCKET_ANIMATION}
                autoPlay
                loop
                muted
                playsInline
                className='w-full h-full object-contain'
              />
            </div>

            <div className='text-center lg:text-xl'>
              <p className='text-white'>Your experience has loaded.</p>
              <p className='text-white'>Click below to proceed</p>
            </div>
          </div>
        </div>

        <CommonButton
          type='button'
          variant='brand'
          onClickCapture={async () => {
            if (unlocking) return;
            setUnlocking(true);
            try {
              await api.tryUnlock();
            } finally {
              setUnlocking(false);
            }
          }}
          disabled={unlocking}
          className='mb-5 w-11/12 lg:w-1/5'
        >
          {unlocking ? 'Loading...' : 'Continue'}
        </CommonButton>
      </div>
    );
  }

  return <>{props.children}</>;
}

function ControlInitialization(
  props: PlaygroundProps & {
    stingerElements: ReturnType<typeof useStingerElements>;
    shakeElements: ReturnType<typeof useShakeElements>;
  }
) {
  const navigate = useNavigate();
  const getI18n = useMyI18nSettingsGetter();
  const audioAPI = useGlobalAudioDetectionAPI();
  const getAudioContext = useLiveCallback(() => {
    return audioAPI.getAudioContextForTutor();
  });

  const [control, setControl] = useState<PlaygroundControlAPI>(
    () =>
      new PlaygroundControlAPI(
        props.cursor,
        props.initialBlockOutputs,
        props.getUser,
        getI18n,
        props.stingerElements,
        props.shakeElements,
        navigate,
        getAudioContext,
        props.preview,
        config.learning.usePauseManagement,
        props.getLearningAnalytics
      )
  );

  useEffect(() => {
    if (control.shouldRebuild(props.cursor)) {
      control.destroy();
      setControl(
        new PlaygroundControlAPI(
          props.cursor,
          props.initialBlockOutputs,
          props.getUser,
          getI18n,
          props.stingerElements,
          props.shakeElements,
          navigate,
          getAudioContext,
          props.preview,
          config.learning.usePauseManagement,
          props.getLearningAnalytics
        )
      );
    }
  }, [
    control,
    props.getUser,
    getI18n,
    props.cursor,
    props.preview,
    props.initialBlockOutputs,
    props.stingerElements,
    navigate,
    props.shakeElements,
    props.getLearningAnalytics,
    getAudioContext,
  ]);

  useEffect(() => {
    return () => {
      control.destroy();
    };
  }, [control]);

  return <PlaygroundInternal {...props} ctrl={control} />;
}

function SubtitlesAreaWithLocale(props: { subman: ISubtitlesManager }) {
  const { i18nSettings } = useMyI18nSettings();
  const currentSubtitlesLocale = findLanguageOptionOrDefault(
    i18nSettings?.value?.subtitlesLocale
  );
  return (
    <SubtitlesAreaV2
      subman={props.subman}
      className=''
      currentLanguage={currentSubtitlesLocale}
    />
  );
}

function PlaygroundInternal(
  props: PlaygroundProps & {
    ctrl: PlaygroundControlAPI;
    shakeElements: ReturnType<typeof useShakeElements>;
  }
) {
  const snapshot = useSnapshot(props.ctrl.state);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [currBlock, setCurrBlock] = useState({
    block: snapshot.block,
    blockCtrl: snapshot.blockCtrl,
  });

  useEffect(() => {
    if (snapshot.status === 'initializing-block') {
      const ctrl = new BrowserTimeoutCtrl();
      ctrl.set(() => setShowLoader(true), 2000);
      return () => ctrl.clear();
    } else if (snapshot.status !== 'playing-block') {
      setShowLoader(false);
    }
  }, [snapshot.status]);

  useEffect(() => {
    async function animate() {
      const container = bodyRef.current;
      if (
        !container ||
        snapshot.status !== 'playing-block' ||
        snapshot.block?.id === currBlock.block?.id
      )
        return;

      const fadeOut = container.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 300,
        easing: 'ease-out',
        fill: 'forwards',
      });

      await fadeOut.finished;
      setShowLoader(false); // Clear loader after fade out

      setCurrBlock({
        block: snapshot.block,
        blockCtrl: snapshot.blockCtrl,
      });

      container.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 300,
        easing: 'ease-in',
        fill: 'forwards',
      });
    }

    animate();
  }, [
    snapshot.status,
    snapshot.block?.id,
    currBlock.block?.id,
    snapshot.block,
    snapshot.blockCtrl,
  ]);

  let body = null;
  let showProgress = true;

  if (snapshot.status === 'preparing') {
    body = <Loading text='' />;
    showProgress = false;
  } else if (snapshot.status === 'minigame-intro') {
    body = null;
    showProgress = false;
  } else if (snapshot.status === 'finished') {
    body = <Finished {...props} />;
  } else if (
    snapshot.status === 'playing-block' ||
    snapshot.status === 'initializing-block'
  ) {
    body = (
      <DisplayBlock
        block={currBlock.block as Nullable<DtoBlock>}
        blockCtrl={currBlock.blockCtrl}
        ctrl={props.ctrl}
      />
    );
  }

  return (
    <div
      className='relative w-full h-full min-h-0 flex flex-col'
      ref={props.shakeElements.rootEl}
    >
      <div
        className={`w-full max-w-240 mx-auto p-5 pb-0 ${
          showProgress ? 'opacity-100' : 'opacity-0'
        } transition-opacity z-5`}
      >
        <div className='flex h-10 items-center gap-2'>
          <div className='flex-1'>
            <StatusBar
              progressPct={snapshot.progressPct}
              onClose={props.onClose}
            />
          </div>
          <TutorButton tutorControl={props.ctrl.blockDeps.tutorControl} />
        </div>
      </div>
      <div ref={bodyRef} className='flex-1 min-h-0 overflow-hidden'>
        {body}
        {showLoader && (
          <div className='fixed inset-0 bg-lp-black-001 flex items-center justify-center animate-fade-in'>
            <Loading text='' />
          </div>
        )}
      </div>
      <TutorOverlay ctrl={props.ctrl.blockDeps.tutorControl} />
    </div>
  );
}

const logger = getLogger().scoped('playground');
function DisplayBlock(props: {
  ctrl: PlaygroundControlAPI;
  block: Nullable<DtoBlock>;
  blockCtrl: Nullable<IBlockCtrl>;
}) {
  const type = blockTypeV2fromEnumsBlockType(props.block?.type);
  useEffect(() => {
    return () => {
      props.blockCtrl
        ?.destroy()
        .catch((e) => logger.error('failed to destroy block', e));
    };
  }, [props.blockCtrl]);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as MultipleChoiceBlock}
          ctrl={props.blockCtrl as MultipleChoiceBlockControlAPI}
        />
      );
    case BlockTypeV2.QUESTION:
      return (
        <QuestionBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as QuestionBlock}
          ctrl={props.blockCtrl as QuestionBlockControlAPI}
        />
      );
    case BlockTypeV2.MATCH:
      return (
        <MatchBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as MemoryMatchBlock}
          ctrl={props.blockCtrl as MatchBlockControlAPI}
        />
      );
    case BlockTypeV2.SLIDE:
      return (
        <SlideBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SlideBlock}
          ctrl={props.blockCtrl as SlideBlockControlAPI}
          subtitlesArea={
            <SubtitlesAreaWithLocale subman={props.ctrl.blockDeps.subman} />
          }
        />
      );
    case BlockTypeV2.ROLEPLAY:
      return (
        <RoleplayBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as RoleplayBlock}
          ctrl={props.blockCtrl as RoleplayBlockControlAPI}
        />
      );
    case BlockTypeV2.DRAW_TO_WIN:
      return (
        <DrawToWinBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as DrawToWinBlock}
          ctrl={props.blockCtrl as DrawToWinBlockControlAPI}
        />
      );
    case BlockTypeV2.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as HiddenPictureBlock}
          ctrl={props.blockCtrl as HiddenPictureBlockControlAPI}
        />
      );
    case BlockTypeV2.JEOPARDY:
      return (
        <JeopardyBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as JeopardyBlock}
          ctrl={props.blockCtrl as JeopardyBlockControlAPI}
        />
      );
    case BlockTypeV2.SPARKIFACT:
      return (
        <SparkifactBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SparkifactBlock}
          ctrl={props.blockCtrl as SparkifactBlockControlAPI}
        />
      );
    case BlockTypeV2.FILL_IN_THE_BLANKS:
      return (
        <FIBPlayground
          block={props.block as unknown as FillInTheBlanksBlock}
          ctrl={props.blockCtrl as IBlockCtrl}
        />
      );
    case BlockTypeV2.SWIPE_TO_WIN:
      return (
        <SwipeToWinBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SwipeToWinBlock}
          ctrl={props.blockCtrl as SwipeToWinBlockControlAPI}
        />
      );
    case BlockTypeV2.SCENARIO:
      return (
        <ScenarioBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as ScenarioBlock}
          ctrl={props.blockCtrl as ScenarioBlockControlAPI}
        />
      );
    case BlockTypeV2.RESULTS:
      return (
        <ResultsBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as ResultsBlock}
          ctrl={props.blockCtrl as ResultsBlockControlAPI}
        />
      );
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(type);
      return null;
  }
}

function Finished(
  props: PlaygroundProps & {
    ctrl: PlaygroundControlAPI;
  }
) {
  const { cursor } = props;
  const nextMinigame = cursor.peekNextMinigame();
  const isLastSlideGroup = !nextMinigame;
  return isLastSlideGroup ? (
    <PackFinished {...props} />
  ) : (
    <MinigameFinished {...props} />
  );
}

function MinigameFinished(
  props: PlaygroundProps & {
    ctrl: PlaygroundControlAPI;
  }
) {
  const { onMinigameContinue, cursor } = props;

  const blockProgressPct = cursor.blockProgressPct();
  const nextMinigame = cursor.peekNextMinigame();
  const isNextGameAssessment = cursor.isAssessmentMinigame(nextMinigame?.id);

  return (
    <div className='h-full flex flex-col bg-black text-white items-center'>
      <div className='flex-1 flex flex-col justify-center items-center px-5 gap-15'>
        <div className='text-2xl lg:text-3xl font-bold text-center px-3'>
          {isNextGameAssessment ? (
            <>
              You’re <span className='text-tertiary'>nearly</span> done!
              <br />
              Get ready for the assessment.
            </>
          ) : (
            <>
              You’re{' '}
              <span className='text-tertiary'>
                {Math.floor(blockProgressPct * 100)}%
              </span>{' '}
              done with <br />
              the course
            </>
          )}
        </div>

        <img alt='applaud' src={applaudImg} className='w-42 h-42' />

        <div className='text-2xl lg:text-3xl font-bold text-center'>
          {isNextGameAssessment ? <>You got this!</> : <>Nice work!</>}
        </div>
      </div>

      <CommonButton
        variant='brand'
        onClick={onMinigameContinue}
        className='mb-5 w-11/12 lg:w-1/2'
      >
        Continue
      </CommonButton>
    </div>
  );
}

function PackFinished(
  props: PlaygroundProps & {
    ctrl: PlaygroundControlAPI;
  }
) {
  const { onMinigameContinue: onContinue, cursor, ctrl } = props;
  const { fire, canvasConfetti } = useConfettiAnimation();
  useEffectOnce(() => {
    async function exec() {
      await ctrl.blockDeps.sfxControl.play('spotlightConfetti').started;
      fire();
    }
    exec();
  });

  return (
    <div className='h-full flex flex-col bg-black text-white items-center'>
      {canvasConfetti}
      <div className='flex-1 flex flex-col justify-center items-center px-5'>
        <h1 className='text-xl lg:text-2xl font-bold mb-4 text-center'>
          Congratulations! You finished
          <br />
          {cursor.gamePack.name}!
        </h1>

        <div className='w-75 h-75 mb-4 lg:w-120 lg:h-120'>
          <video
            src={FINISH_GAME_PACK_ANIMATION}
            autoPlay
            loop
            muted
            playsInline
            className='w-full h-full object-contain'
          />
        </div>
      </div>

      <CommonButton
        variant='brand'
        onClick={onContinue}
        className='mb-5 w-11/12 lg:w-1/2'
      >
        Continue
      </CommonButton>
    </div>
  );
}

type PlaygroundStatus =
  | 'preparing'
  | 'minigame-intro'
  | 'initializing-block'
  | 'playing-block'
  | 'finished';

type PlaygroundControlState = {
  status: PlaygroundStatus;
  block: Nullable<DtoBlock>;
  blockCtrl: Nullable<IBlockCtrl>;
  progressPct: number;
  nextMinigameId: Nullable<string>;
};

type PlayBlockResult = {
  nextCursor: Nullable<PlayCursor>;
};

class PlaygroundControlAPI {
  private _state = markSnapshottable(
    proxy<PlaygroundControlState>(this.initialState())
  );

  readonly blockDeps: BlockDependencies;
  private runner: Nullable<AbortableRunner<void, undefined>>;
  private musicControl;
  private logger: Logger;
  private pauseManagement: PauseManagement | null;
  private lmsNotifier = new LMSNotifier();
  private tutorControl: TutorControl;
  private blockPreloadResult: ReturnType<PlaygroundControlAPI['preloadBlock']>;
  private lvoLocalCtrl: LVOLocalCtrl;

  constructor(
    private cursor: PlayCursor,
    initialBlockOutputs: Nullable<DtoProgression['blockOutputs']>,
    getUser: () => Nullable<User>,
    private getI18n: ReturnType<typeof useMyI18nSettingsGetter>,
    stingerElements: ReturnType<typeof useStingerElements>,
    shakeElements: ReturnType<typeof useShakeElements>,
    navigate: ReturnType<typeof useNavigate>,
    getAudioContextForTutor: () => Nullable<AudioContext>,
    preview = false,
    private readonly usePauseManagement = config.learning.usePauseManagement,
    getLearningAnalytics?: () => Nullable<LearningAnalytics>
  ) {
    this.logger = this.initLogger(getUser);
    const sfxControl = new SFXControl();
    this.musicControl = new MusicControl();
    const createLVOLocalPlayer: BlockDependencies['createLVOLocalPlayer'] = (
      req
    ) => {
      return new LVOLocalPlayer(
        req,
        () => this.lvoLocalCtrl,
        () => this.getLocale()
      );
    };
    const lvoLocalCacheWarm: BlockDependencies['lvoLocalCacheWarm'] = (
      entry
    ) => {
      return lvoCacheWarmForLocales(entry, [this.getLocale()]);
    };
    this.tutorControl = new TutorControl(
      this.logger.scoped('tutor-control'),
      sfxControl,
      getAudioContextForTutor,
      () => this.cursor.gamePack,
      getUser,
      getAgentInfo(),
      getLearningAnalytics?.()
    );
    this.tutorControl.setFeatureEnabled(!cursor.isAssessing());
    this.blockDeps = {
      sfxControl: sfxControl,
      subman: new LocalSubtitlesManager(),
      commonVariableRegistry: this.initVariableRegistry(
        getUser,
        initialBlockOutputs
      ),
      getLogger: (scope: string) => this.logger.scoped(scope),
      getUser,
      stingerControl: new StingerControl(
        stingerElements,
        sfxControl,
        createLVOLocalPlayer,
        this.logger.scoped('stinger')
      ),
      shakeControl: new ShakeControl(shakeElements),
      tutorControl: this.tutorControl,
      micUsageControl: new MicUsageControl(getAgentInfo()),
      storage: StorageFactory('local'),
      roleplayAVTooltip: StorageFactory('in-memory'),
      getBlocks: () => this.cursor.allBlocks(),
      getPack: () => this.cursor.gamePack,
      isPreview: () => preview,
      progressionTracker: newProgressionTracker(
        preview ? 'in-memory' : 'persist'
      ),
      agentInfo: getAgentInfo(),
      createLVOLocalPlayer,
      lvoLocalCacheWarm,
      getLVOCtrl: () => this.lvoLocalCtrl,
    };

    this.lvoLocalCtrl = this.makeLVOCtrl(this.getLocale());
    this.pauseManagement =
      preview || !this.usePauseManagement
        ? null
        : new PauseManagement(
            this.logger.scoped('pause-mgmt'),
            cursor,
            navigate
          );
    this.blockPreloadResult = null;
    this.run();
  }

  get state() {
    return this._state;
  }

  get gamePack() {
    return this.cursor.gamePack;
  }

  get minigame() {
    return this.cursor.currentMinigame();
  }

  getLocale() {
    return this.getI18n().i18nSettings?.value?.voiceOverLocale ?? 'en-US';
  }

  shouldRebuild(cursor: PlayCursor) {
    return (
      this.cursor.gamePack.id !== cursor.gamePack.id ||
      this.cursor.currentMinigame()?.id !== cursor.currentMinigame()?.id
    );
  }

  destroy() {
    this.lvoLocalCtrl?.destroy();
    this.runner?.destroy();
    this._state.blockCtrl?.destroy();
    if (this.blockPreloadResult) {
      this.blockPreloadResult.blockCtrl.destroy();
    }
    this.blockDeps.sfxControl.destroy();
    this.blockDeps.micUsageControl.destroy();
    this.musicControl.destroy();
    this.pauseManagement?.destroy();
    this.tutorControl.reset();
  }

  private rebuildLVOCtrl() {
    this.lvoLocalCtrl.destroy();
    this.lvoLocalCtrl = this.makeLVOCtrl(this.getLocale());
  }

  private makeLVOCtrl(locale: string) {
    return new LVOLocalCtrl(
      'not-a-venue', // TODO(falcon): revisit
      // this is not super important. in our block dependency we will create
      // the lvolocalplayer with a function to get the locale
      locale,
      this.blockDeps.subman,
      // We are using 'volume' impl (ignored on ios) because VOs do not need
      // volume control.
      makeMediaElementTracker('volume')
    );
  }

  async run() {
    // already running;
    if (this.runner) throw new Error('failed to start: already running');

    this.runner = YieldableAbortableRunner<
      PlayBlockResult,
      void,
      PlayBlockResult
    >(
      async function* (
        this: PlaygroundControlAPI
      ): AsyncGenerator<PlayBlockResult, void, PlayBlockResult> {
        // start the music
        this.musicControl.play();

        // kick off preloading the first block...
        let block = this.cursor.currentBlock();
        this.blockPreloadResult = this.preloadBlock(block);

        // before we enter the loop, let's run the minigame stinger.
        const currentMinigame = this.cursor.currentMinigame();
        if (currentMinigame) {
          // and before we run this, let's try to prep some music...
          // it's okay to do this early. when we call it again for the same block in the loop, the music will continue.
          this.switchMusicForBlock(block);

          this._state.status = 'minigame-intro';
          this.blockDeps.stingerControl.resetMinigameStingerEl();
          if (!this.blockDeps.stingerControl.isReady()) {
            // we should have the el refs, but if we're coming from the overworld,
            // they aren't ready yet.
            await sleep(5);
          }
          await this.blockDeps.stingerControl.playMinigameIntro(
            currentMinigame
          );
        }

        while (block) {
          if (!this.blockPreloadResult) {
            // this is some kind of block we don't support, just move along.
            this.logger.warn('unsupported block type, skipping', {
              blockId: block.id,
              blockType: block.type,
            });
            block = this.cursor.nextMinigameBlock();
            this.blockPreloadResult = this.preloadBlock(block);
            continue;
          }

          // start preloading the next block if it exists
          const nextBlockPreloadResult = this.preloadBlock(
            this.cursor.peekNextMinigameBlock()
          );

          this.switchMusicForBlock(block);

          // reset the stinger...
          this.blockDeps.stingerControl.resetBlockStingerEl();

          // TODO(falcon): errors...
          const result = yield this.playBlock(block, this.blockPreloadResult);
          if (!result.nextCursor) {
            break;
          }

          this.rebuildLVOCtrl();
          this.cursor = result.nextCursor;
          // Update tutor feature enabled state based on assessment mode
          this.blockDeps.tutorControl.setFeatureEnabled(
            !this.cursor.isAssessing()
          );
          block = this.cursor.currentBlock();
          if (nextBlockPreloadResult?.block?.id === block?.id) {
            this.blockPreloadResult = nextBlockPreloadResult;
          } else {
            this.blockPreloadResult = this.preloadBlock(block);
          }
        }

        // disable the tutor entirely
        this.blockDeps.tutorControl.setFeatureEnabled(false);

        // No more blocks to play, ensure mic is closed
        await this.blockDeps.micUsageControl.releaseMic();

        // No more blocks, kill the lvoCtrl
        this.lvoLocalCtrl.destroy();

        this._state.status = 'finished';
        this._state.progressPct = 100;
        this._state.nextMinigameId = this.cursor.peekNextMinigame()?.id;
        if (!this._state.nextMinigameId) {
          this.lmsNotifier.post('setCourseCompleted');
        }
      }.bind(this)
    );
    this.runner.start();
  }

  private async switchMusicForBlock(block: Nullable<DtoBlock>) {
    const bgMusicMedia = fromDTOBlock(block)?.fields.bgMusic?.asset.media;
    await this.musicControl.switchTrack(bgMusicMedia);
  }

  private async playBlock(
    block: DtoBlock,
    blockPreloadResult: NonNullable<ReturnType<typeof this.preloadBlock>>
  ) {
    const promise = Promise.withResolvers<PlayBlockResult>();
    // these are what we'll persist to the backend, outputs with block ids.
    const outputs: Record<string, ModelsBlockOutput> = {};
    // these are additional outputs we'll put in the vr.
    const refOutputs: Record<string, ModelsBlockOutput> = {};
    const evaluator = new BlockLogicEvaluator(
      this.gamePack.logicSettings?.blockLogic?.[block.id],
      this.logger.scoped('block-logic-evaluator')
    );

    // Handle mic access before playing the block. We assume the block will
    // always initialize its own mic and handle permissions. But we only want to
    // stop the mic if the _next_ block will _not_ use the mic. This prevents
    // iOS/macos bluetooth audio profile changes from interferring with content
    // (the audio fades out while the profile changes, squelching currently
    // playing audio).
    const willNeedMic =
      (await blockPreloadResult.blockCtrl.willNeedMicAccess?.()) ?? false;
    if (!willNeedMic) {
      await this.blockDeps.micUsageControl.releaseMic();
    }

    const protocol: PlaygroundPlaybackProtocol = {
      blockDidStart: async () => {
        this.logger.info('blockDidStart', {
          blockId: block.id,
          blockType: block.type,
        });
      },
      blockDidEnd: async () => {
        this.logger.info('blockDidEnd', {
          blockId: block.id,
          blockType: block.type,
          minigameId: block.gameId,
          outputs: outputs,
        });

        // compute blockProgressPct
        const currentBlockIds = new Set(
          this.cursor.allBlocks().map((b) => b.id)
        );
        const completedBlockIds = new Set([
          ...this.cursor.completedBlockIds(),
          block.id,
        ]);
        // our numerator should only include the completed blocks _currently_
        // in the gamepack, otherwise we could exceed 1.
        const numCompleted =
          currentBlockIds.intersection(completedBlockIds).size;
        let blockProgressPct = 0;
        if (currentBlockIds.size > 0) {
          blockProgressPct = numCompleted / currentBlockIds.size;
        }

        // set outputs on the vr.
        const entries = [
          ...Object.entries(outputs),
          ...Object.entries(refOutputs),
        ];
        for (const [key, value] of entries) {
          this.blockDeps.commonVariableRegistry.set(key, async () =>
            getBlockOutputAsString(value)
          );
        }

        // determine where we go next. this could be jump, the next block
        // in the minigame, the first game in the next minigame. we must
        // send this to the backend for record keeping.
        let nextDestination: Nullable<DtoBlockDestination>;
        let nextCursor: Nullable<PlayCursor> = this.cursor;

        // check for logic-based jumps first
        let jumpToBlockId: string | undefined = undefined;
        evaluator.eval(outputs, {
          onJump: (toBlockId: string) => {
            jumpToBlockId = toBlockId;
          },
        });

        if (jumpToBlockId) {
          nextCursor = this.cursor.withJump(jumpToBlockId);
        } else {
          // this will permit crossing the minigame boundary
          nextCursor = this.cursor.withNext();
        }

        const nextBlock = nextCursor.currentBlock();
        const nextMinigame = nextCursor.currentMinigame();
        if (nextBlock && nextMinigame) {
          nextDestination = {
            minigameId: nextMinigame.id,
            blockId: nextBlock.id,
            blockType: nextBlock.type,
          };
        }

        // it's the client's role to compute the assessment result because
        // the graded result can only be computed by the client.
        let assessmentResult = null;
        if (this.cursor.isAssessing() && this.cursor.willCompleteMinigame()) {
          const progression =
            await this.blockDeps.progressionTracker.getProgression(
              this.cursor.gamePack.id
            );

          assessmentResult = gradeAssessment(
            progression?.progress?.[block.gameId],
            this.cursor.currentMinigameBlocks() ?? [],
            Object.assign(progression?.blockOutputs ?? {}, outputs),
            this.blockDeps.isPreview()
          );
        }

        const progression =
          await this.blockDeps.progressionTracker.trackBlockPlayed(
            this.cursor.gamePack.id,
            {
              minigameId: block.gameId,
              blockId: block.id,
              blockType: block.type,
              willCompleteMinigame: this.cursor.willCompleteMinigame(),
              willCompleteGamePack: this.cursor.willCompleteGamePack(),
              blockProgressPct,
              outputs,
              nextDestination,
              assessmentResult,
            }
          );
        nextCursor = nextCursor?.withProgression(progression);

        // note: our current pattern is to return to the overworld at the end
        // of the minigame. so while we always need the next block for tracking
        // if we are not jumping and this is the end, we should end the experience.
        if (!jumpToBlockId && this.cursor.willCompleteMinigame()) {
          nextCursor = null;
        }

        promise.resolve({ nextCursor });
      },

      blockDidOutput: async (desc, value) => {
        this.logger.info('blockDidOutput', {
          blockId: block.id,
          blockType: block.type,
          desc,
          value,
        });
        const output: ModelsBlockOutput = {
          type: desc.schema.type,
          value,
        };
        outputs[getBlockOutputKey(block.id, desc.name)] = output;
        const refId = fromDTOBlock(block).fields.referenceId;
        if (refId) {
          refOutputs[getBlockOutputKey(refId, desc.name)] = output;
        }
      },
    };

    this._state.block = block;
    this._state.blockCtrl = ref(blockPreloadResult.blockCtrl);
    this._state.progressPct = this.cursor.progressPct();
    this._state.nextMinigameId = this.cursor.peekNextMinigame()?.id;

    this.logger.info('initializing block', {
      blockId: block.id,
      blockType: block.type,
    });

    this.tutorControl.reset();
    this.tutorControl.setCurrentBlock(block);

    this._state.status = 'initializing-block';
    await this._state.blockCtrl?.initialize(blockPreloadResult.preloaded);

    this.logger.info('playing block', {
      blockId: block.id,
      blockType: block.type,
    });
    this._state.blockCtrl?.setDelegate(protocol);
    this._state.status = 'playing-block';

    return promise.promise;
  }

  private preloadBlock(block: Nullable<DtoBlock>) {
    if (!block) return null;
    const blockCtrl = createBlockControl(block, this.blockDeps);
    if (!blockCtrl) return null;

    return {
      block,
      blockCtrl,
      preloaded: (async () => {
        try {
          await blockCtrl.preload();
        } catch (e) {
          this.logger.error('failed to preload block', e);
        }
      })(),
    };
  }

  private initialState(): PlaygroundControlState {
    return {
      status: 'preparing',
      block: null,
      blockCtrl: null,
      progressPct: 0,
      nextMinigameId: null,
    };
  }

  private initLogger(getUser: () => Nullable<User>): Logger {
    const logger = getLogger();
    logger.verbose(getFeatureQueryParam('verbose-local-logging'));
    const user = getUser();
    if (user) {
      logger.updateSharedMeta({
        uid: user.id,
      });
    }
    const clock = new DefaultClock();
    logger.setTimeMsGetter(() => clock.now());
    (async () => {
      try {
        await clock.sync();
      } catch (e) {
        logger.error('failed to sync clock', e);
      }
    })();
    logger.scoped('device').info('DeviceInfo', {
      userAgent: navigator.userAgent,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
    });
    return logger.scoped('playground');
  }

  private initVariableRegistry(
    getUser: () => Nullable<User>,
    initialBlockOutputs?: Nullable<DtoProgression['blockOutputs']>
  ): VariableRegistry {
    const vr = new VariableRegistry();
    vr.set('playerName', async () => {
      const user = getUser();
      if (!user) return 'Guest';
      return user.organizer?.firstName ?? user.username;
    })
      // TODO: this will be public facing since users will see the scripts.
      // Should it be called gamepack or something else, such as "course" or "lesson"?
      .set('gamePackName', async () => this.cursor.gamePack.name)
      .set('gamePackDescription', async () => this.cursor.gamePack.description)
      .set(
        'minigameName',
        async () => this.cursor.currentMinigame()?.name ?? ''
      )
      .set(
        'minigameDescription',
        async () => this.cursor.currentMinigame()?.description ?? ''
      );

    // add progression block outputs.
    for (const [key, value] of Object.entries(initialBlockOutputs ?? {})) {
      vr.set(key, async () => getBlockOutputAsString(value));
    }

    return vr;
  }
}

function createBlockControl(
  block: DtoBlock,
  blockDeps: BlockDependencies
): Nullable<IBlockCtrl> {
  const type = blockTypeV2fromEnumsBlockType(block.type);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE: {
      return new MultipleChoiceBlockControlAPI(
        block as unknown as MultipleChoiceBlock,
        blockDeps
      );
    }
    case BlockTypeV2.QUESTION:
      return new QuestionBlockControlAPI(
        block as unknown as QuestionBlock,
        blockDeps
      );
    case BlockTypeV2.MATCH:
      return new MatchBlockControlAPI(
        block as unknown as MemoryMatchBlock,
        blockDeps
      );
    case BlockTypeV2.SLIDE:
      return new SlideBlockControlAPI(
        block as unknown as SlideBlock,
        blockDeps
      );
    case BlockTypeV2.ROLEPLAY:
      return new RoleplayBlockControlAPI(
        block as unknown as RoleplayBlock,
        blockDeps
      );
    case BlockTypeV2.DRAW_TO_WIN:
      return new DrawToWinBlockControlAPI(
        block as unknown as DrawToWinBlock,
        blockDeps
      );
    case BlockTypeV2.HIDDEN_PICTURE:
      return new HiddenPictureBlockControlAPI(
        block as unknown as HiddenPictureBlock,
        blockDeps
      );
    case BlockTypeV2.JEOPARDY:
      return new JeopardyBlockControlAPI(
        block as unknown as JeopardyBlock,
        blockDeps
      );
    case BlockTypeV2.SPARKIFACT:
      return new SparkifactBlockControlAPI(
        block as unknown as SparkifactBlock,
        blockDeps
      );
    case BlockTypeV2.FILL_IN_THE_BLANKS:
      return newFIBControlAPI(
        block as unknown as FillInTheBlanksBlock,
        blockDeps
      );
    case BlockTypeV2.SWIPE_TO_WIN:
      return new SwipeToWinBlockControlAPI(
        block as unknown as SwipeToWinBlock,
        blockDeps
      );
    case BlockTypeV2.SCENARIO:
      return new ScenarioBlockControlAPI(
        block as unknown as ScenarioBlock,
        blockDeps
      );
    case BlockTypeV2.RESULTS:
      return new ResultsBlockControlAPI(
        block as unknown as ResultsBlock,
        blockDeps
      );
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(type);
      return null;
  }
}

function gradeAssessment(
  minigameProgression: Nullable<ModelsMinigameProgression>,
  minigameBlocks: DtoBlock[],
  outputs: Nullable<ModelsBlockOutputMap>,
  mock = false
): Nullable<DtoAssessmentResultRequest> {
  const blockResults: DtoAssessmentResultRequest['blockResults'] = [];

  let totalScore = 0;
  for (const block of minigameBlocks) {
    if (!blockTypePlayable(block.type)) continue;

    const blockOutputs = getBlockOutputsById(block.id, outputs ?? {});
    const result = blockOutputsToGradeResult(block, blockOutputs, mock);

    const blockAssessmentResult = {
      blockId: block.id,
      blockType: block.type,
      result: result.status,
      score:
        result.totalPoints > 0
          ? result.earnedPoints / result.totalPoints
          : result.status === EnumsBlockGradeResult.BlockGradeResultPassed
          ? 1
          : 0,
      outputs: blockOutputs,
    };
    blockResults.push(blockAssessmentResult);
    totalScore += blockAssessmentResult.score;
  }

  // edge case: there was nothing graded.
  if (blockResults.length === 0) return null;

  const now = new Date().toISOString();
  return {
    startedAt: minigameProgression?.unlockedAt ?? now,
    completedAt: now,
    score: totalScore / blockResults.length,
    blockResults,
  };
}
