import {
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
} from '@lp-lib/game';

import { apiService } from '../../../../services/api-service';
import { type Grader, type GradeResult } from './types';

/**
 * Configuration for the PromptTemplateGrader
 */
export type PromptTemplateGraderConfig = {
  /** The prompt template ID to use for grading */
  promptTemplateId?: string;
  /** The prompt template mapping key to use for grading */
  promptTemplateMappingKey?: string;
  /**
   * Function that constructs the variables object for the template.
   * Receives the submission string and should return an object with all necessary variables.
   */
  buildVariables?: (submission: string) => Record<string, string>;
};

/**
 * Grader that uses prompt templates to evaluate answers.
 * Expects the prompt template to return a numeric value that maps to QuestionBlockAnswerGrade.
 */
export class PromptTemplateGrader implements Grader {
  constructor(private config: PromptTemplateGraderConfig) {
    if (!config.promptTemplateId && !config.promptTemplateMappingKey) {
      throw new Error(
        'Either promptTemplateId or promptTemplateMappingKey must be provided'
      );
    }
  }

  async grade(submission: string): Promise<GradeResult> {
    const source = this.config.promptTemplateId
      ? `prompt-template-${this.config.promptTemplateId}`
      : `prompt-template-${this.config.promptTemplateMappingKey}`;
    const trimmedAnswer = submission.trim().toLowerCase();
    const t1 = Date.now();

    try {
      const variables = this.config.buildVariables?.(trimmedAnswer) ?? {
        submission: trimmedAnswer,
      };
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateId: this.config.promptTemplateId,
        promptTemplateMappingKey: this.config.promptTemplateMappingKey,
        variables,
      });

      // Parse the response content as a number and map it to the enum
      const gradeValue = parseInt(resp.data.content, 10);
      const grade =
        isNaN(gradeValue) ||
        !Object.values(QuestionBlockAnswerGrade).includes(gradeValue)
          ? QuestionBlockAnswerGrade.NONE
          : gradeValue;

      return {
        source,
        grade,
        timeMs: Date.now() - t1,
        error: null,
      };
    } catch (error) {
      return {
        source,
        grade: QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error,
      };
    }
  }
}

/**
 * Creates a PromptTemplateGrader that uses a shared template
 * suitable for most simple grading scenarios.
 */
export function createCommonPromptTemplateGrader(params: {
  context?: string;
  correctAnswers: string[];
}): Grader {
  return new PromptTemplateGrader({
    promptTemplateMappingKey: 'shared/grade-answer',
    buildVariables: (submission) => ({
      context: params.context ?? '<No context>',
      correctAnswers: JSON.stringify(params.correctAnswers),
      submission,
    }),
  });
}

/**
 * Simple grader that checks for exact matches (case-insensitive)
 */
export class SimpleGrader implements Grader {
  constructor(private correctAnswers: string[]) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    if (this.correctAnswers.some((correct) => correct === trimmedAnswer)) {
      return {
        source: 'simple',
        grade: QuestionBlockAnswerGrade.CORRECT,
        timeMs: 0,
      };
    }
    return {
      source: 'simple',
      grade: QuestionBlockAnswerGrade.NONE,
      timeMs: 0,
    };
  }
}

/**
 * Legacy AI-based grader that uses GPT models to evaluate answers
 */
export class LegacyAIGrader implements Grader {
  constructor(
    private model: QuestionBlockAnswerGradeModel,
    private temperature: number,
    private correctAnswers: string[]
  ) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const t1 = Date.now();
    const req = {
      model: this.model,
      temperature: this.temperature,
      correctAnswers: this.correctAnswers,
      userSubmission: trimmedAnswer,
    };
    try {
      const resp = await apiService.aiGrade.gradeSubmission(req);
      return {
        source: `legacy-${this.model}`,
        grade: resp.data.evaluation
          ? QuestionBlockAnswerGrade.CORRECT
          : QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error: null,
      };
    } catch (error) {
      return {
        source: `legacy-${this.model}`,
        grade: QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error,
      };
    }
  }
}

/**
 * Pipeline that tries multiple graders in sequence
 */
export class GraderPipeline implements Grader {
  constructor(private graders: Grader[]) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const results: GradeResult[] = [];
    for (const grader of this.graders) {
      const r = await grader.grade(trimmedAnswer);
      results.push(r);
      if (r.grade !== QuestionBlockAnswerGrade.NONE) {
        break;
      }
    }
    return (
      results[results.length - 1] ?? {
        source: 'simple',
        grade: QuestionBlockAnswerGrade.NONE,
      }
    );
  }
}
