import { audioSessionGetMic } from '../../../../services/audio/audio-session';
import { Emitter } from '../../../../utils/emitter';

type RealtimeConversationItem = {
  id: string;
  type: 'message' | 'function_call' | 'function_call_output';
  status: 'completed' | 'incomplete';
  role: 'user' | 'assistant' | 'system';
  name: string;
  arguments: string;
  call_id: string;
};

type RealtimeFunctionCallArgumentsDoneEvent = {
  event_id: string;
  type: 'response.function_call_arguments.done';
  name: string;
  arguments: string;
  call_id: string;
};

type RealtimeServerEvent =
  | {
      event_id: string;
      type: 'conversation.item.created' | 'response.done';
      item: RealtimeConversationItem;
    }
  | RealtimeFunctionCallArgumentsDoneEvent;

type RealtimeEvents = {
  'conversation.item.created': (item: RealtimeConversationItem) => void;
  'response.done': (item: RealtimeConversationItem) => void;
};

export class RealtimeControl {
  private peerConnection: RTCPeerConnection | null = null;
  private dataChannel: RTCDataChannel | null = null;
  private mediaStream: MediaStream | null = null;

  protected emitter = new Emitter<RealtimeEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  private tools: Record<
    string,
    { handler: (args: unknown) => Promise<unknown> }
  > = {};

  async connect(token: string) {
    const { promise, resolve } = Promise.withResolvers<void>();

    this.peerConnection = new RTCPeerConnection();
    this.mediaStream = await audioSessionGetMic();
    const audioTrack = this.mediaStream.getTracks()[0];
    this.peerConnection.addTrack(audioTrack);

    this.dataChannel = this.peerConnection.createDataChannel('oai-events');
    this.dataChannel.addEventListener('message', (event) => {
      this.handleMessage(event);
    });
    this.dataChannel.addEventListener('open', () => {
      resolve();
    });

    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(offer);

    const baseUrl = 'https://api.openai.com/v1/realtime';
    const sdpResponse = await fetch(`${baseUrl}`, {
      method: 'POST',
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/sdp',
      },
    });
    const answer = {
      type: 'answer' as RTCSdpType,
      sdp: await sdpResponse.text(),
    };
    await this.peerConnection.setRemoteDescription(answer);

    await promise;
  }

  async disconnect() {
    if (this.dataChannel) {
      this.dataChannel.close();
      this.dataChannel = null;
    }

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }

    if (this.mediaStream) {
      this.mediaStream.getAudioTracks().forEach((track) => track.stop());
      this.mediaStream = null;
    }
  }

  onToolCall(name: string, handler: (args: unknown) => Promise<unknown>) {
    this.tools[name] = { handler };
  }

  private handleMessage(event: MessageEvent) {
    const message = JSON.parse(event.data) as RealtimeServerEvent;
    switch (message.type) {
      case 'conversation.item.created':
        this.emitter.emit('conversation.item.created', message.item);
        break;
      case 'response.done':
        this.emitter.emit('response.done', message.item);
        break;
      case 'response.function_call_arguments.done':
        this.handleFunctionCall(message);
        break;
      default:
        break;
    }
  }

  private async handleFunctionCall(
    event: RealtimeFunctionCallArgumentsDoneEvent
  ) {
    const jsonArguments = JSON.parse(event.arguments);
    const tool = this.tools[event.name];
    if (!tool) {
      throw new Error(`Tool "${event.name}" has not been added`);
    }

    const result = await tool.handler(jsonArguments);
    this.sendClientEvent({
      type: 'conversation.item.create',
      item: {
        type: 'function_call_output',
        call_id: event.call_id,
        output: JSON.stringify(result),
      },
    });
  }

  switchToPushToTalk() {
    this.sendClientEvent({
      type: 'session.update',
      session: {
        turn_detection: null,
      },
    });
  }

  createResponse() {
    this.sendClientEvent({
      type: 'input_audio_buffer.commit',
    });
    this.sendClientEvent({
      type: 'response.create',
    });
  }

  sendText(text: string) {
    this.sendClientEvent({
      type: 'conversation.item.create',
      item: {
        type: 'message',
        role: 'user',
        content: [
          {
            type: 'input_text',
            text,
          },
        ],
      },
    });
    this.sendClientEvent({
      type: 'response.create',
    });
  }

  sendClientEvent(message: object) {
    if (!this.dataChannel) return;

    this.dataChannel.send(JSON.stringify(message));
  }
}
