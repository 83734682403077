import {
  EnumsMediaType,
  EnumsSparkBackgroundOption,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';

import { fromMediaDTO } from '../../../utils/api-dto';
import { getStaticAssetPath } from '../../../utils/assets';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';

const blockVideoPaths: Partial<Record<BlockType, string>> = {
  [BlockType.FILL_IN_THE_BLANKS]: 'videos/fill-in-the-blanks-background-v2.mp4',
  [BlockType.MEMORY_MATCH]: 'videos/match-background-v2.mp4',
  [BlockType.MULTIPLE_CHOICE]: 'videos/multiple-choice-background-v3.mp4',
  [BlockType.QUESTION]: 'videos/question-background-v2.mp4',
  [BlockType.SWIPE_TO_WIN]: 'videos/swipe-to-win-background.mp4',
};

export function SparkBlockBackground<
  T extends Block & {
    fields: {
      sparkBackgroundOption?: EnumsSparkBackgroundOption | null;
      sparkBackgroundMedia?: ModelsMediaAsset | null;
    };
  }
>(props: { block: T; position?: string; noVideo?: boolean }) {
  const { block, position = 'fixed' } = props;
  const { color: brandColor } = useOrgBrandColor();

  switch (block.fields.sparkBackgroundOption) {
    case EnumsSparkBackgroundOption.SparkBackgroundOptionNone:
      return null;
    case EnumsSparkBackgroundOption.SparkBackgroundOptionBrandColor:
      if (!brandColor) return null;
      return (
        <div
          className={`${position} inset-0 opacity-20`}
          style={{ backgroundColor: brandColor }}
        />
      );
    case EnumsSparkBackgroundOption.SparkBackgroundOptionBlockVideo: {
      const path = blockVideoPaths[block.type];
      if (!path) return null;
      const src = getStaticAssetPath(path);
      return (
        <div className={`${position} inset-0`}>
          <video
            src={src}
            autoPlay
            loop
            muted
            className='object-cover w-full h-full select-none'
            playsInline
          />
        </div>
      );
    }
    case EnumsSparkBackgroundOption.SparkBackgroundOptionMedia:
    case EnumsSparkBackgroundOption.SparkBackgroundOptionMediaWithOverlay: {
      const mediaUrl = MediaUtils.PickMediaUrl(
        fromMediaDTO(block.fields.sparkBackgroundMedia?.media),
        {
          priority: ImagePickPriorityHighToLow,
          videoThumbnail: props.noVideo ? 'first' : undefined,
        }
      );
      const withOverlay =
        block.fields.sparkBackgroundOption ===
        EnumsSparkBackgroundOption.SparkBackgroundOptionMediaWithOverlay;

      if (!mediaUrl) return null;

      return (
        <div className={`${position} inset-0`}>
          {!props.noVideo &&
          block.fields.sparkBackgroundMedia?.media?.type ===
            EnumsMediaType.MediaTypeVideo ? (
            <video
              autoPlay
              muted
              loop
              src={mediaUrl}
              className='w-full h-full object-cover select-none'
              playsInline
            />
          ) : (
            <img
              src={mediaUrl}
              alt=''
              className='w-full h-full object-cover select-none'
            />
          )}
          {withOverlay && <div className='absolute inset-0 bg-black/60' />}
        </div>
      );
    }
  }
}
