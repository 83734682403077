import { useState } from 'react';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { type JeopardyBlock } from '@lp-lib/game';

import { useInstance } from '../../../../hooks/useInstance';
import { sleep } from '../../../../utils/common';
import { type JeopardyBlockControlAPI } from './JeopardyBlockPlayground';
import { type JeopardyPlayer } from './types';
import { JeopardyUtils } from './utils';

function JeopardyPlayerCell(props: { player: JeopardyPlayer }) {
  const { player } = props;

  return (
    <div
      className={`
        w-45 h-11.5 bg-layer-001 rounded-xl p-0.5
        border ${player.order === 0 ? 'border-[#FF3975]' : 'border-[#0029FF]'}
        flex items-center gap-1.5
      `}
      style={{
        boxShadow: player.order === 0 ? '0px 4px 0px 0px #FF3975' : undefined,
      }}
    >
      <div className='w-8.5 h-8.5 flex-none bg-[#D9D9D9] rounded-xl'>
        <video
          src={player.icon}
          className='w-full h-full object-cover rounded-xl'
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
      <div className='flex-1 overflow-hidden flex flex-col gap-0.5'>
        <p className='text-3xs truncate text-white'>
          {player.username}
          {player.isMe ? ' (You)' : ''}
        </p>
        <p className='text-tertiary text-sms font-bold'>
          {player.score < 0 ? '-' : ''}${Math.abs(player.score)}
        </p>
      </div>
    </div>
  );
}

export function JeopardyPlayerTracker(props: {
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
  animatePopIn?: boolean;
}) {
  const players = useSnapshot(props.ctrl.state).players;

  const [animateIndex, setAnimateIndex] = useState(0);

  useEffect(() => {
    if (!props.animatePopIn) return;
    const run = async () => {
      for (let i = 0; i < players.length; i++) {
        setAnimateIndex(i);
        await sleep(200);
      }
    };
    run();
  }, [props.animatePopIn, players.length]);

  return (
    <div className='grid grid-cols-2 gap-3'>
      {players.map((player, index) => (
        <div
          key={player.uid}
          className={`
            ${player.order === 0 ? 'col-span-2' : ''}
            w-full flex justify-center items-center
            ${
              props.animatePopIn
                ? index <= animateIndex
                  ? 'opacity-100 animate-[pop-in_300ms_ease_1]'
                  : 'opacity-0'
                : 'opacity-100'
            }
          `}
        >
          <JeopardyPlayerCell
            key={player.uid}
            player={player as JeopardyPlayer}
          />
        </div>
      ))}
    </div>
  );
}

export function JeopardyEditorPlayerTracker() {
  const players = useInstance(() => JeopardyUtils.MockPlayers());

  return (
    <div className='grid grid-cols-2 gap-3'>
      {players.map((player) => (
        <div
          key={player.uid}
          className={`
          ${player.order === 0 ? 'col-span-2' : ''}
            w-full flex justify-center items-center
          `}
        >
          <JeopardyPlayerCell key={player.uid} player={player} />
        </div>
      ))}
    </div>
  );
}
