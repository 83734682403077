import { type ReactNode, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import { useSnapshot } from 'valtio';

import { EnumsJeopardyClueType } from '@lp-lib/api-service-client/public';

import { getStaticAssetPath } from '../../../../utils/assets';
import { CommonButton } from '../../design/Button';
import { MultipleChoiceBlockPlayground } from '../MultipleChoice/MultipleChoiceBlock';
import { QuestionBlockPlayground } from '../Question/QuestionBlock';
import { type JeopardyBlockControlAPI } from './JeopardyBlockPlayground';
import { useJeopardyBoardCellClueRef } from './JeopardyBoard';
import { type JeopardyStagePlayClue } from './types';

function JeopardyAnimateClueScaleUp(props: {
  stage: JeopardyStagePlayClue;
  ctrl: JeopardyBlockControlAPI;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const clueRef = useJeopardyBoardCellClueRef(props.stage.clue.id);

  useLayoutEffect(() => {
    if (!ref.current || !clueRef) {
      return;
    }

    const clueRect = clueRef.getBoundingClientRect();
    const displayRect = ref.current.getBoundingClientRect();

    const tx = clueRect.left - displayRect.left;
    const ty = clueRect.top - displayRect.top;
    const scaleX = clueRect.width / displayRect.width;
    const scaleY = clueRect.height / displayRect.height;

    ref.current.animate(
      [
        {
          transformOrigin: 'top left',
          transform: `translate(${tx}px, ${ty}px) scale(${scaleX}, ${scaleY})`,
          opacity: 0.8,
          backgroundColor: '#0B1887',
        },
        {
          transformOrigin: 'top left',
          transform: `translate(0px, 0px) scale(1, 1)`,
          opacity: 1,
          backgroundColor: props.stage.isWagerTurn
            ? 'rgba(0, 0, 0, 0.80)'
            : '#0029FF',
        },
      ],
      {
        duration: 450,
        fill: 'forwards',
      }
    );
  }, [clueRef, props.stage.isWagerTurn]);

  return <div ref={ref} className={`fixed inset-0`}></div>;
}

export function JeopardyCluePlayground(props: {
  stage: JeopardyStagePlayClue;
  ctrl: JeopardyBlockControlAPI;
}) {
  const { stage, ctrl } = props;

  const [body, setBody] = useState<ReactNode>(null);

  useLayoutEffect(() => {
    const run = async () => {
      switch (stage.clue.type) {
        case EnumsJeopardyClueType.JeopardyClueTypeMultipleChoice:
          const multipleChoice = await ctrl.mockMultipleChoiceBlock(stage.clue);
          if (!multipleChoice) return;
          const { multipleChoiceBlock, multipleChoiceBlockCtrl } =
            multipleChoice;

          setBody(
            <MultipleChoiceBlockPlayground
              block={multipleChoiceBlock}
              ctrl={multipleChoiceBlockCtrl}
            />
          );
          break;
        case EnumsJeopardyClueType.JeopardyClueTypeQuestion:
          const questionResult = await ctrl.mockQuestionBlock(stage.clue);
          if (!questionResult) return;
          const { questionBlock, questionBlockCtrl } = questionResult;

          setBody(
            <QuestionBlockPlayground
              block={questionBlock}
              ctrl={questionBlockCtrl}
            />
          );
          break;
      }
    };
    run();
  }, [stage.clue, ctrl]);

  return (
    <>
      {stage.animateClueCellScaleUp && (
        <JeopardyAnimateClueScaleUp stage={stage} ctrl={ctrl} />
      )}

      {stage.animateDailyDouble && <JeopardyDailyDouble />}

      {stage.showCluePlayground &&
        (body ? (
          <div className={`absolute inset-0 bg-black`}>
            <audio
              src={getStaticAssetPath('audios/v2/jeopardy/bg-music.mp3')}
              autoPlay
              playsInline
              loop
            />
            {body}
          </div>
        ) : (
          <div className='fixed inset-0 bg-[#0029FF]'></div>
        ))}
    </>
  );
}

function JeopardyDailyDouble() {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.animate(
      [{ transform: 'translateX(100%)' }, { transform: 'translateX(0)' }],
      {
        duration: 300,
      }
    );
  }, []);

  return (
    <div className='fixed inset-0 bg-lp-black-004 flex justify-center items-center'>
      <div
        className='w-full font-Montserrat text-5xl italic font-black text-center'
        ref={ref}
        style={{
          filter: 'drop-shadow(3px 3px 0px #956C00)',
          background: 'linear-gradient(180deg, #F3FB07 0%, #FF0048 100%)',
          color: 'transparent',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
        }}
      >
        DAILY <br /> DOUBLE
      </div>
    </div>
  );
}

export function JeopardyWagerPoints(props: { ctrl: JeopardyBlockControlAPI }) {
  const { ctrl } = props;
  const { category, points } = useSnapshot(ctrl.jeopardyControl.state);

  const players = ctrl.state.players;
  const me = players.find((p) => p.isMe);
  const myScore = me?.score ?? 0;

  const lastPlayTime = useRef(0);
  const [wager, setWager] = useState(points);

  const handleWager = () => {
    ctrl.jeopardyControl.state.points = wager;
    ctrl.endWager();
  };

  const handleMove = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setWager(Number(e.target.value));

    if (Date.now() - lastPlayTime.current >= 150) {
      lastPlayTime.current = Date.now();
      ctrl.playSoundEffect('dail');
    }
  };

  return (
    <div
      className='
        relative w-full h-full
        p-6 flex flex-col items-center justify-center gap-15
      '
      style={{
        background:
          'linear-gradient(180deg, #001EB8 0%, #FF0935 100%), rgba(0, 0, 0, 0.60)',
      }}
    >
      <div className='flex flex-col items-center gap-2'>
        <div className='text-white font-bold'>Category:</div>
        <div className='text-icon-gray text-3.5xl font-bold'>{category}</div>
      </div>

      <div className='w-full flex flex-col items-center gap-7.5'>
        <div className='flex flex-col items-center gap-2'>
          <div className='text-white text-sms font-bold'>Wager: {` `}</div>
          <div className='text-tertiary text-3.5xl font-bold'>${wager}</div>
        </div>
      </div>

      <div className='w-full flex flex-col items-center gap-7.5'>
        <div className='flex flex-col items-center gap-2'>
          <div className='text-white text-sms font-bold'>
            Potential Final Score: {` `}
          </div>
          <div className='text-tertiary text-3.5xl font-bold'>
            ${myScore + wager}
          </div>
        </div>
      </div>

      <input
        type='range'
        min={1}
        max={points * 2}
        value={wager}
        className='jeopardy-range'
        onChange={handleMove}
      />

      <div className='absolute -bottom-2'>
        <CommonButton variant={'brand'} className='w-35' onClick={handleWager}>
          Wager!
        </CommonButton>
      </div>
    </div>
  );
}
