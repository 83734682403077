import {
  type ModelsScenarioRubric,
  type ModelsScenarioRubricItem,
} from '@lp-lib/api-service-client/public';

export class ScenarioBlockUtils {
  static NormalizeRubric(
    rubric: Nullable<ModelsScenarioRubric>
  ): ModelsScenarioRubric {
    if (!rubric) {
      return {
        thingsSaidCorrectly: [],
        thingsAvoidedSaying: [],
      };
    }

    return {
      thingsSaidCorrectly: rubric.thingsSaidCorrectly.filter(
        this.IsRubricItemValid
      ),
      thingsAvoidedSaying: rubric.thingsAvoidedSaying.filter(
        this.IsRubricItemValid
      ),
    };
  }

  static IsRubricItemValid(item: ModelsScenarioRubricItem) {
    return !!item.title || !!item.description;
  }

  static FormatFillerWordsDisplay(
    fillerWordCounts: Record<string, number>
  ): string {
    const sortedFillerWords = Object.entries(fillerWordCounts)
      .sort(([, a], [, b]) => b - a)
      .map(([word, count]) => {
        const displayWord = word.includes(' ') ? `"${word}"` : word;
        return count === 1 ? displayWord : `${displayWord} (x${count})`;
      });

    if (sortedFillerWords.length === 0) {
      return '';
    }

    if (sortedFillerWords.length === 1) {
      return sortedFillerWords[0];
    }

    if (sortedFillerWords.length <= 4) {
      return `${sortedFillerWords.slice(0, -1).join(', ')} and ${
        sortedFillerWords[sortedFillerWords.length - 1]
      }`;
    }

    return `${sortedFillerWords.slice(0, 3).join(', ')} and ${
      sortedFillerWords.length - 3
    } others`;
  }

  static CountFillerWords(
    transcript: string,
    fillerWords: Set<string>
  ): Record<string, number> {
    const fillerWordCounts: Record<string, number> = {};
    for (const word of fillerWords) {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      const matches = transcript.match(regex);
      if (matches) {
        fillerWordCounts[word] = matches.length;
      }
    }
    return fillerWordCounts;
  }

  static CalculateFillerWordsPerMinute(
    fillerWordCounts: Record<string, number>,
    durationMinutes: number
  ): number {
    const totalFillerWords = Object.values(fillerWordCounts).reduce(
      (a, b) => a + b,
      0
    );
    return Math.round(totalFillerWords / durationMinutes);
  }
}
