import { useEffect, useRef } from 'react';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsJeopardyTurnResult,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import type { MultipleChoiceBlock, MultipleChoiceOption } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { apiService } from '../../../../services/api-service';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import { JeopardyHeader, JeopardyTools } from '../../apis/JeopardyControl';
import { getStingerPrompt } from '../../apis/StingerControl';
import { Timer, TimerBackground } from '../../apis/Timer';
import { BlockContainer } from '../../design/BlockContainer';
import {
  CommonButton,
  type CommonButtonVariant,
  GamePlayButton,
} from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { SparkBlockBackground } from '../../design/SparkBackground';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import {
  getOutputSchema,
  type MultipleChoiceBlockOutputSchema,
} from './outputs';

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  questionVisibility: 'hidden' | 'entering' | 'entered';
  questionHasEntered: ReturnType<typeof Promise.withResolvers<void>>;
  choicesVisibility: 'visible' | 'hidden';
  choicesEnabled: boolean;
  selection?: MultipleChoiceOption | null;
  result?: 'correct' | 'incorrect' | 'skipped' | null;
  continueEnabled: boolean;
  strikedChoices: number[];
};

export class MultipleChoiceBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      questionVisibility: 'hidden',
      questionHasEntered: Promise.withResolvers(),
      choicesVisibility: 'hidden',
      choicesEnabled: false,
      continueEnabled: false,
      strikedChoices: [],
    })
  );

  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
    skipped: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    correct: null,
    incorrect: null,
    skipped: null,
  };

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private schema: MultipleChoiceBlockOutputSchema;
  private readonly willGrade: boolean;
  private _timer: Nullable<Timer>;
  private _aborter = new AbortController();

  constructor(
    private block: MultipleChoiceBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('multiple-choice-block');
    this.schema = getOutputSchema(block);
    this.willGrade = block.fields.points !== 0;
    // only Jeopardy has a timer
    if (this.deps.jeopardyControl) {
      this._timer = new Timer(block.fields.questionTimeSec * 1000);
    }
  }

  get state() {
    return this._state;
  }

  get timer() {
    return this._timer;
  }

  get jeopardyControl() {
    return this.deps.jeopardyControl;
  }

  private async sleep(ms: number) {
    // TODO: make this a utility that will be assigned to the iblockctrl at
    // construction time, such as this.sleep = createIBlockCtrlSleep(aborter)
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(resolve, ms);

      this._aborter.signal?.addEventListener(
        'abort',
        () => {
          clearTimeout(timeoutId);
          reject(new Error('Sleep aborted'));
        },
        { once: true }
      );
    });
  }

  async preload() {
    if (this.deps.stingerControl.shouldPreloadTTS(this.block)) {
      this.resolvedTTS.stinger = await this.preloadTTS(
        getStingerPrompt(
          this.block.fields.question,
          'Tap the Fact',
          'A multiple choice question.'
        )
      );
    }

    this.resolvedTTS.question = await this.preloadTTS(
      this.block.fields.question
    );

    // preload TTS for grading, but do not wait it
    this.preloadGradingTTS();

    await Promise.all([
      this.deps.sfxControl.preload('pop'),
      this.deps.sfxControl.preload('baDink'),
      this.deps.sfxControl.preload('instructionHoverReadyButton'),
      this.deps.sfxControl.preload('rapidCorrect'),
      this.deps.sfxControl.preload('rapidWrong'),
    ]);

    this.deps.sfxControl.preload('timePaused');
    this.deps.sfxControl.preload('jeopardyTimesUp');
  }

  private async preloadGradingTTS() {
    if (!this.willGrade) return;

    const correctChoice = this.block.fields.answerChoices.find(
      (c) => c.correct
    );
    const choices = this.block.fields.answerChoices.map((c) => c.text);

    this.resolvedTTS.correct = await this.preloadTTS(
      await this.getEvaluationScript(
        this.block.fields.question,
        choices,
        correctChoice?.text ?? '<No Answer>',
        correctChoice?.text ?? '<No Submission>',
        'correct'
      )
    );

    if (this.deps.jeopardyControl) {
      this.resolvedTTS.skipped = await this.preloadTTS(
        await this.getEvaluationScript(
          this.block.fields.question,
          choices,
          correctChoice?.text ?? '<No Answer>',
          '<No submission>',
          'skipped'
        )
      );
    }
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  private updateTutorForBlockStatus(status: State['status']) {
    if (this.deps.jeopardyControl) {
      this.deps.tutorControl.setAvailabilityState('unavailable');
      return;
    }

    switch (status) {
      case 'play':
      case 'complete':
        this.deps.tutorControl.setAvailabilityState('available');
        break;
      case 'grade':
      case 'inform':
        this.deps.tutorControl.setAvailabilityState('unavailable');
        break;
      default:
        this.deps.tutorControl.setAvailabilityState('unavailable');
    }
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = this.deps.createLVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      this._state.questionVisibility = 'entering';
      this.deps.sfxControl.play('pop');
      await Promise.all([info?.trackEnded, this.sleep(2000)]);
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this.updateTutorForBlockStatus('present');
      this._state.questionVisibility = 'entering';
      await this.sleep(3000);
    }
    this._state.questionVisibility = 'entered';
    await this._state.questionHasEntered.promise;
    this.deps.sfxControl.play('baDink');
    this._state.status = 'play';
    this._state.choicesVisibility = 'visible';
    this._state.choicesEnabled = true;

    this.updateTutorForBlockStatus('play');

    this._timer?.on('timeout', () => this.timeout());
    this._timer?.start();
  }

  async selectChoice(choice: MultipleChoiceOption) {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    this._state.selection = choice;
    this._state.continueEnabled = true;
  }

  async strikeChoice() {
    this.deps.sfxControl.play('quicklyErase');

    const candidates = [];

    for (let i = 0; i < this.block.fields.answerChoices.length; i++) {
      if (this.block.fields.answerChoices[i].correct) continue;
      if (this._state.strikedChoices.includes(i)) continue;
      candidates.push(i);
    }

    if (candidates.length === 0) return;

    const index = candidates[Math.floor(Math.random() * candidates.length)];
    // TODO: play strike sound
    this._state.strikedChoices.push(index);
  }

  async pauseTime() {
    this.deps.sfxControl.play('timePaused');
    this._timer?.pause(10000);
  }

  async continue() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    if (this._state.status === 'complete') {
      await this.end();
    } else if (this._state.selection) {
      await this.submitChoice();
    }
  }

  async submitChoice() {
    this._timer?.stop();

    this._state.choicesEnabled = false;
    const choice = this._state.selection;
    this.delegate?.blockDidOutput(this.schema.choice, choice?.text ?? '');
    this.delegate?.blockDidOutput(
      this.schema.question,
      this.block.fields.question
    );
    this.delegate?.blockDidOutput(
      this.schema.answer,
      this.block.fields.answerChoices.find((c) => c.correct)?.text ?? ''
    );
    if (!this.willGrade) {
      this._state.status = 'complete';
      this.updateTutorForBlockStatus('complete');
      return this.end();
    }

    this._state.continueEnabled = false;
    this._state.status = 'grade';
    this.updateTutorForBlockStatus('grade');
    // need a little delay to make the transition smoother
    await this.sleep(250);

    const isCorrect = choice?.correct ?? false;
    this._state.result = isCorrect ? 'correct' : 'incorrect';
    this.delegate?.blockDidOutput(this.schema.grade, this._state.result);
    this.delegate?.blockDidOutput(
      this.schema.points,
      isCorrect ? this.block.fields.points : 0
    );
    this.delegate?.blockDidOutput(
      this.schema.totalPoints,
      isCorrect ? this.block.fields.points : 0
    );

    this._state.status = 'inform';
    this.updateTutorForBlockStatus('inform');

    if (isCorrect) {
      this.deps.sfxControl.play('rapidCorrect');
      this.deps.shakeControl.rootPop();
      await this.playTTS(this.resolvedTTS.correct);
    } else {
      // in the incorrect case, we need to generate the script first
      // and be sure that it's ready before playing the other effects.
      // in case of failure, we'll just skip the TTS
      let incorrectReq: Nullable<DtoTTSRenderRequest> = null;
      try {
        const correctChoice = this.block.fields.answerChoices.find(
          (c) => c.correct
        );
        const choices = this.block.fields.answerChoices.map((c) => c.text);
        const script = await this.getEvaluationScript(
          this.block.fields.question,
          choices,
          correctChoice?.text ?? '<No Answer>',
          choice?.text ?? '<No Submission>',
          'incorrect'
        );
        incorrectReq = await this.makeTTSRenderRequest(script);
      } catch (e) {
        this.logger.error('failed to generate incorrect answer tts request', e);
      }

      this.deps.sfxControl.play('rapidWrong');
      this.deps.shakeControl.rootIncorrect();
      if (incorrectReq) {
        incorrectReq.preferFlashRender = true;
        await this.playTTS(incorrectReq);
      }
    }

    this._state.status = 'complete';
    this._state.continueEnabled = true;
    this.updateTutorForBlockStatus('complete');
  }

  async skip() {
    this._timer?.stop();
    this._state.choicesEnabled = false;

    await this.sleep(250);

    this._state.result = 'skipped';
    this.delegate?.blockDidOutput(this.schema.grade, this._state.result);

    this._state.status = 'inform';
    this.updateTutorForBlockStatus('inform');
    await this.playTTS(this.resolvedTTS.skipped);
    this._state.status = 'complete';
    this._state.continueEnabled = true;
    this.updateTutorForBlockStatus('complete');
  }

  async timeout() {
    this.deps.sfxControl.play('jeopardyTimesUp');
    await this.skip();
  }

  async end() {
    this._state.continueEnabled = false;
    await this.delegate?.blockDidEnd();
    this.jeopardyControl?.end(
      this._state.result === 'correct'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultCorrect
        : this._state.result === 'incorrect'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect
        : EnumsJeopardyTurnResult.JeopardyTurnResultSkipped
    );
  }

  async destroy() {
    this._timer?.stop();
    this._aborter.abort();
  }

  private async preloadTTS(script: Nullable<string>) {
    const req = await this.makeTTSRenderRequest(script);
    if (!req) return null;
    await this.deps.lvoLocalCacheWarm(req);
    return req;
  }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = this.deps.createLVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: Nullable<string>
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!script) return null;

    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }

  private async getEvaluationScript(
    question: string,
    choices: string[],
    correctAnswer: string,
    submission: string,
    evaluation: string
  ): Promise<Nullable<string>> {
    if (!this.block.fields.personalityId) return null;

    try {
      const resp = await apiService.promptTemplate.runTemplate({
        promptTemplateMappingKey: 'mc/evaluation-script',
        variables: {
          question,
          choices,
          correctAnswer,
          submission,
          evaluation,
        },
      });
      return resp.data.content;
    } catch (e) {
      this.logger.error('failed to generate evaluation script', e);
      return null;
    }
  }
}

export function MultipleChoiceBlockPlayground(props: {
  block: MultipleChoiceBlock;
  ctrl: MultipleChoiceBlockControlAPI;
}) {
  const { question, answerChoices } = props.block.fields;
  const {
    status,
    questionVisibility,
    choicesVisibility,
    choicesEnabled,
    selection,
    result,
    continueEnabled,
    strikedChoices,
  } = useSnapshot(props.ctrl.state);

  useEffect(() => {
    if (status !== 'init') return;
    props.ctrl.present();
  }, [status, props.ctrl]);

  const questionRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!questionRef.current) return;

    if (questionVisibility === 'entering') {
      // transition to middle of screen vertically

      // NOTE: typically it should be fine to use window.innerHeight. However,
      // the TrainingEditor does two things:
      //
      // 1. previews using an iframe
      // 2. portals the content into the iframe
      //
      // Due to the use of the portal, `window` is actually not the iframe's
      // window! It is the portal owner's window! Therefore `window.innerHeight`
      // _in this code right here_ will not represent the visible
      // `window.innerHeight` of the iframe! Therefore, we have to get a
      // reference to the window of the iframe using an element from said
      // iframe.
      //
      // One other note is that measuring
      // questionRef.current.ownerDocument.body.getBoundingClientRect().height
      // also fails because the first child within _shell is defined as
      // `position: static` and therefore the body element has no height!
      const innerHeight =
        questionRef.current.ownerDocument.defaultView?.innerHeight ??
        window.innerHeight;
      const box = questionRef.current.getBoundingClientRect();
      const startY = innerHeight - box.y;
      const targetY = innerHeight / 2 - box.height / 2 - box.y;

      const anim = questionRef.current.animate(
        [
          { transform: `translateY(${startY}px)`, opacity: 0 },
          { transform: `translateY(${targetY}px)`, opacity: 1 },
        ],
        {
          duration: 300,
          easing: 'ease-in-out',
          fill: 'both',
        }
      );

      anim.play();
      anim.finished.then(() => anim.commitStyles());
      return () => {
        anim.cancel();
      };
    }

    if (questionVisibility === 'entered') {
      // transition to final (original) destination

      const anim = questionRef.current.animate(
        [{ transform: `translateY(0px)` }],
        {
          duration: 200,
          easing: 'ease-in-out',
          fill: 'both',
        }
      );

      anim.finished.then(() => {
        props.ctrl.state.questionHasEntered.resolve();
        anim.commitStyles();
      });

      anim.play();
      return () => {
        anim.cancel();
      };
    }
  }, [props.ctrl.state.questionHasEntered, questionVisibility]);

  return (
    <>
      <SparkBlockBackground block={props.block} />
      {props.ctrl.timer && <TimerBackground timer={props.ctrl.timer} />}
      <BlockContainer className='flex flex-col'>
        {props.ctrl.jeopardyControl && (
          <JeopardyHeader jeopardyControl={props.ctrl.jeopardyControl} />
        )}

        <main className='w-full flex-1 min-h-0 px-10 flex flex-col items-center justify-center gap-5 sm:gap-10'>
          <div
            ref={questionRef}
            className={`
            text-white text-center break-words
            text-xl md:text-2xl lg:text-3xl
            ${questionVisibility === 'hidden' ? 'opacity-0' : ''}
         `}
          >
            <span
              style={{
                // These are key to the tiktok/instagram style text breaking
                // where each line has its own tight rounded background
                boxDecorationBreak: 'clone',
                WebkitBoxDecorationBreak: 'clone',
              }}
              className='
                px-1.5 py-0.5
                leading-relaxed
                rounded-md
                bg-black bg-opacity-80
              '
            >
              {question}
            </span>
          </div>

          <div
            className={`w-full max-w-150 flex flex-col items-center gap-2 transition-opacity duration-200 ${
              choicesVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {answerChoices.map((choice, idx) => {
              const isStriked = strikedChoices.includes(idx);

              const mySelection = selection?.text === choice.text;
              let variant: CommonButtonVariant = 'gray';
              if (result) {
                if (choice.correct) {
                  variant = 'correct';
                } else if (mySelection) {
                  variant = 'incorrect';
                }
              } else if (mySelection) {
                variant = 'brand';
              }
              const hasSelection = !!selection;

              return (
                <div
                  key={`choice-${idx}`}
                  className={`${
                    isStriked ? 'opacity-0 h-0' : 'opacity-100'
                  } w-full transition-all duration-500`}
                >
                  <GamePlayButton
                    variant={variant}
                    styles={{
                      text: 'text-sm font-bold text-white',
                      size: 'w-full min-h-11.5',
                      spacing: 'px-4 py-2',
                      bordered: result === 'incorrect' && choice.correct,
                    }}
                    className={hasSelection ? 'disabled:opacity-100' : ''}
                    onClick={() => props.ctrl.selectChoice(choice)}
                    disabled={!choicesEnabled}
                  >
                    {choice.text}
                  </GamePlayButton>
                </div>
              );
            })}

            {props.ctrl.jeopardyControl && (
              <button
                type='button'
                className={`btn text-sms font-bold text-white`}
                disabled={!choicesEnabled}
                onClick={() => props.ctrl.skip()}
              >{`Skip >>`}</button>
            )}
          </div>
        </main>

        <footer
          className={`
          relative w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500
          ${choicesVisibility === 'visible' ? 'opacity-100' : 'opacity-0'}
        `}
        >
          {props.ctrl.jeopardyControl && (
            <JeopardyTools
              jeopardyControl={props.ctrl.jeopardyControl}
              onStrike={() => props.ctrl.strikeChoice()}
              onExtraTime={() => props.ctrl.pauseTime()}
              visible={status === 'play'}
            />
          )}

          <div
            className={`
            w-full h-5 flex items-center justify-center text-xs text-center transition-opacity
            ${
              result === 'correct'
                ? 'text-green-001 opacity-100'
                : result === 'incorrect'
                ? 'text-red-006 opacity-100'
                : result === 'skipped'
                ? 'text-yellow-001 opacity-100'
                : 'opacity-0'
            }
          `}
          >
            {result === 'correct' ? (
              <span>
                <strong>Correct!</strong> Nice job!
              </span>
            ) : result === 'incorrect' ? (
              <span>
                <strong>Incorrect!</strong> See correct answer above.
              </span>
            ) : result === 'skipped' ? (
              <span>
                <strong>Skipped!</strong> See correct answer above.
              </span>
            ) : null}
          </div>
          <CommonButton
            variant={
              result === 'correct'
                ? 'correct'
                : result === 'incorrect'
                ? 'incorrect'
                : 'brand'
            }
            onClick={() => props.ctrl.continue()}
            disabled={!continueEnabled}
          >
            Continue
          </CommonButton>
          {result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </BlockContainer>
    </>
  );
}
