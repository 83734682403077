export type DialogueMarkerImage = {
  type: 'image';
  name: string;
  query: string;
};

export type DialogueMarkerTrigger = {
  type: 'trigger';
  name: string;
};

export type DialogueMarkerTutorQuestion = {
  type: 'tutor-question';
  name: string;
  question: string;
  finishCriteria: string;
};

export type DialogueMarker =
  | DialogueMarkerTrigger
  | DialogueMarkerImage
  | DialogueMarkerTutorQuestion;

export type DialogueMarkerType = DialogueMarker['type'];

export const DIALOGUE_MARKER_TYPES = [
  'trigger',
  'image',
  'tutor-question',
] as const;

export type DialogueScriptSegment =
  | {
      type: 'text';
      text: string;
    }
  | DialogueMarker;

export type DialogueScriptSegmentType = DialogueScriptSegment['type'];
