import { z } from 'zod';

export const trainingSummarizeBlockPerformanceResultSchema = z
  .object({
    summaries: z
      .array(
        z
          .object({
            blockId: z.string().describe('The blockId from the given inputs'),
            summary: z
              .string()
              .describe('The summary of the block performance'),
          })
          .strict()
          .describe('BlockPerformanceSummary summarized the block performance')
      )
      .describe('a list of block performance summaries'),
  })
  .strict();
