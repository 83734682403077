import type {
  DtoBlock,
  DtoGame,
  DtoGamePack,
  DtoProgression,
  DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

export class PlayCursor {
  constructor(
    readonly gamePack: DtoGamePack,
    private minigames: DtoGame[],
    private minigameBlocks: Map<DtoGame['id'], DtoBlock[]>,
    private blocks: DtoBlock[],
    private progression: Nullable<DtoProgression>,
    private _currentMinigameIndex: number,
    private _currentBlockIndex: number
  ) {}

  static FromGamePackResponse(
    bundle: DtoSingleGamePackResponse,
    gameIdToPlay?: string
  ) {
    const { gamePack, games = [], blocks = [], progression } = bundle;

    // collect all the minigame blocks into a map...
    const minigameBlocks = blocks.reduce((map, block) => {
      if (!map.has(block.gameId)) {
        map.set(block.gameId, []);
      }
      map.get(block.gameId)?.push(block);
      return map;
    }, new Map<DtoGame['id'], DtoBlock[]>());

    const cursor = new PlayCursor(
      gamePack,
      games,
      minigameBlocks,
      blocks,
      progression,
      0,
      0
    );

    if (gameIdToPlay) return cursor.withContinueAtMinigame(gameIdToPlay);

    return cursor.withContinueAtGamePack();
  }

  withContinueAtMinigame(minigameId: string) {
    const cursor = this.withMinigame(minigameId);

    // find the most recently started block in this minigame.
    const minigameBlocks = cursor.minigameBlocks.get(minigameId) ?? [];
    if (minigameBlocks.length === 0) return cursor;

    let mostRecentlyStartedBlock;
    let startedAt;

    for (const block of minigameBlocks) {
      const progress = cursor.getBlockProgress(block.id);
      if (!progress) continue;

      if (!startedAt || progress.startedAt >= startedAt) {
        startedAt = progress.startedAt;
        mostRecentlyStartedBlock = block;
      }
    }

    if (!mostRecentlyStartedBlock || !startedAt) return cursor;

    // if the most recently started block was started after the minigame was completed, start from the beginning.
    const minigameProgress = cursor.getMinigameProgress(minigameId);
    if (!minigameProgress) return cursor;

    if (
      !minigameProgress.completedAt ||
      minigameProgress.completedAt < startedAt
    ) {
      // start at this block
      cursor._currentBlockIndex = minigameBlocks.findIndex(
        (block) => block.id === mostRecentlyStartedBlock.id
      );
    }

    return cursor;
  }

  withContinueAtGamePack() {
    const cursor = this.clone();
    cursor._currentBlockIndex = 0;
    cursor._currentMinigameIndex = 0;

    // find the most recently started block in the entire game pack.
    let mostRecentlyStartedBlock;
    let startedAt;

    for (const block of cursor.blocks) {
      const progress = cursor.getBlockProgress(block.id);
      if (!progress) continue;

      // we're only interested in the most recently started block, even if there
      // are incomplete blocks before it (in terms of started at).

      if (!startedAt || progress.startedAt >= startedAt) {
        startedAt = progress.startedAt;
        mostRecentlyStartedBlock = block;
      }
    }

    if (!mostRecentlyStartedBlock || !startedAt) return cursor;

    // if the most recently started block was started after the gamepack was completed, start from the beginning.
    const progression = cursor.progression;
    if (!progression) return cursor;

    if (!progression.completedAt || progression.completedAt < startedAt) {
      // start at this block
      return cursor.withJump(mostRecentlyStartedBlock.id);
    }

    return cursor;
  }

  getBlockProgress(blockId: string) {
    return this.progression?.blockProgress?.[blockId];
  }

  getMinigameProgress(minigameId: string) {
    return this.progression?.progress?.[minigameId];
  }

  get currentMinigameIndex() {
    return this._currentMinigameIndex;
  }

  get currentBlockIndex() {
    return this._currentBlockIndex;
  }

  withProgression(progression: DtoProgression) {
    return new PlayCursor(
      this.gamePack,
      this.minigames,
      this.minigameBlocks,
      this.blocks,
      progression,
      this._currentMinigameIndex,
      this._currentBlockIndex
    );
  }

  currentMinigame(): Nullable<DtoGame> {
    return this.minigames.at(this._currentMinigameIndex) ?? null;
  }

  currentMinigameBlocks(): Nullable<DtoBlock[]> {
    const minigameId = this.currentMinigame()?.id;
    if (!minigameId) return null;

    return this.minigameBlocks.get(minigameId) ?? null;
  }

  peekNextMinigame(): Nullable<DtoGame> {
    const idx = this._currentMinigameIndex + 1;
    return this.minigames.at(idx) ?? null;
  }

  nextMinigame(): Nullable<DtoGame> {
    this._currentMinigameIndex += 1;
    this._currentBlockIndex = 0;
    return this.currentMinigame();
  }

  withNextMinigame(): PlayCursor {
    const cursor = this.clone();
    cursor.nextMinigame();
    return cursor;
  }

  withMinigame(minigameId: string): PlayCursor {
    const cursor = this.clone();
    cursor._currentMinigameIndex = this.minigames.findIndex(
      (game) => game.id === minigameId
    );
    cursor._currentBlockIndex = 0;
    return cursor;
  }

  withRestartMinigame(): PlayCursor {
    const cursor = this.clone();
    cursor._currentBlockIndex = 0;
    return cursor;
  }

  withJump(blockId: string): PlayCursor {
    const cursor = this.clone();
    for (
      let minigameIndex = 0;
      minigameIndex < this.minigames.length;
      minigameIndex++
    ) {
      const minigame = this.minigames[minigameIndex];
      const blockIndex = (this.minigameBlocks.get(minigame.id) ?? []).findIndex(
        (block) => block.id === blockId
      );
      if (blockIndex !== -1) {
        cursor._currentMinigameIndex = minigameIndex;
        cursor._currentBlockIndex = blockIndex;
        return cursor;
      }
    }
    return cursor;
  }

  currentBlock(): Nullable<DtoBlock> {
    return this.currentMinigameBlocks()?.at(this._currentBlockIndex) ?? null;
  }

  peekNextMinigameBlock(): Nullable<DtoBlock> {
    const idx = this._currentBlockIndex + 1;
    return this.currentMinigameBlocks()?.at(idx) ?? null;
  }

  nextMinigameBlock(): Nullable<DtoBlock> {
    this._currentBlockIndex += 1;
    return this.currentBlock();
  }

  withNextMinigameBlock(): PlayCursor {
    const cursor = this.clone();
    cursor.nextMinigameBlock();
    return cursor;
  }

  next(): Nullable<DtoBlock> {
    const nextMiniGameBlock = this.nextMinigameBlock();
    if (nextMiniGameBlock) return nextMiniGameBlock;

    const nextMiniGame = this.nextMinigame();
    if (nextMiniGame) return this.currentBlock();
    return null;
  }

  withNext(): PlayCursor {
    const cursor = this.clone();
    cursor.next();
    return cursor;
  }

  willCompleteMinigame(): boolean {
    // note: in the future this could be a property on the block.
    return !this.peekNextMinigameBlock();
  }

  willCompleteGamePack(): boolean {
    return this.willCompleteMinigame() && !this.peekNextMinigame();
  }

  progressPct(): number {
    const numMinigameBlocks = this.currentMinigameBlocks()?.length ?? 0;
    if (numMinigameBlocks === 0) return 100;

    const numBlocksRemaining = numMinigameBlocks - this._currentBlockIndex;
    return (1 - numBlocksRemaining / numMinigameBlocks) * 100;
  }

  allBlocks(): Readonly<DtoBlock[]> {
    return this.blocks;
  }

  isAssessmentMinigame(minigameId: Nullable<string>): boolean {
    return Boolean(
      minigameId && this.gamePack.assessmentSettings?.gameId === minigameId
    );
  }

  isAssessing(): boolean {
    return this.isAssessmentMinigame(this.currentMinigame()?.id);
  }

  completedBlockIds(): string[] {
    if (!this.progression) return [];
    return Object.entries(this.progression.blockProgress ?? {})
      .filter(([, progress]) => !!progress.completedAt)
      .map(([blockId]) => blockId);
  }

  blockProgressPct(): number {
    const completedBlockIds = new Set();
    for (
      let i = 0;
      i < this.minigames.length && i < this.currentMinigameIndex;
      i++
    ) {
      const minigameBlocks =
        this.minigameBlocks.get(this.minigames[i].id) ?? [];
      for (const block of minigameBlocks) {
        completedBlockIds.add(block.id);
      }
    }

    const currentMinigameBlocks = this.currentMinigameBlocks() ?? [];
    for (const block of currentMinigameBlocks) {
      completedBlockIds.add(block.id);
    }

    const currentBlockIds = new Set(this.blocks.map((b) => b.id));

    // our numerator should only include the completed blocks _currently_
    // in the gamepack, otherwise we could exceed 1.
    const numCompleted = currentBlockIds.intersection(completedBlockIds).size;
    let blockProgressPct = 0;
    if (currentBlockIds.size > 0) {
      blockProgressPct = numCompleted / currentBlockIds.size;
    }
    return blockProgressPct;
  }

  private clone() {
    return new PlayCursor(
      this.gamePack,
      this.minigames,
      this.minigameBlocks,
      this.blocks,
      this.progression,
      this._currentMinigameIndex,
      this._currentBlockIndex
    );
  }
}
